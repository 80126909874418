import Box from "@mui/material/Box";

import close from "../assets/close.svg";

function ListRoles({ roles, deleteRol, isSmall, user, t }) {
  return (
    <Box style={{ width: "100%" }}>
      <Box
        style={{
          margin: "auto",
          background: "#FFFFFF",
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          borderRadius: 5,
          minHeight: 200,
          maxHeight: 600,
          overflow: "scroll",
          width: "55%",
          paddingTop: 25.5,
          paddingBottom: 16,
          paddingLeft: 24,
          paddingRight: 24,
        }}
        className="listRoles-step"
      >
        <Box>
          <Box
            style={{
              fontSize: 14,
              fontWeight: 600,
              fontFamily: "Rubik",
              marginBottom: 10.39,
            }}
          >
            {t("roles.superadmin")}
          </Box>
          <Box
            style={{
              display: "flex",
              marginBottom: 42.31,
              alignItems: "center",
            }}
          >
            <Box
              style={{
                width: "30%",
                fontSize: 12,
                fontFamily: "Rubik",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                height: 26,
                // display: "flex",
                alignItems: "center",
                paddingTop: 3,
              }}
            >
              {`${user?.firstname} ${user?.lastname}`}
            </Box>
            <Box
              style={{
                width: "70%",
                borderRadius: 20,
                color: "#17191E",
                paddingLeft: 8,
                paddingRight: 8,
                fontSize: 12,
                fontFamily: "Rubik",
                whiteSpace: "nowrap",
                overflow: "hidden",
                height: 26,
                textOverflow: "ellipsis",
                // display: "flex",
                alignItems: "center",
                paddingTop: 3,
                alignItems: "center",
              }}
            >
              {user?.email}
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            textAlign: "center",
            fontSize: 20,
            fontFamily: "Rubik",
            color: "#17191E",
            fontWeight: "bold",
            marginBottom: 14,
          }}
        >
          {t("roles.assign")}
          {/* Asignaste roles a */}
        </Box>
        <Box>
          <Box
            style={{
              fontSize: 14,
              fontWeight: 600,
              fontFamily: "Rubik",
              marginBottom: 8,
            }}
          >
            {t("roles.admin.string")}
            {/* Administrador */}
          </Box>
          {roles
            .filter((role) => role.type === "admin")
            .map((item, index) => (
              <Box
                style={{
                  display: "flex",
                  marginBottom: 8,
                  alignItems: "center",
                }}
                key={`admin-${index}`}
              >
                <Box
                  style={{
                    width: "47.5%",
                    fontSize: 12,
                    fontFamily: "Rubik",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    height: 26,
                    // display: "flex",
                    alignItems: "center",
                    paddingTop: 3,
                  }}
                >
                  {`${item.name} ${item.lastName}`}
                </Box>
                <Box
                  style={{
                    width: "47.5%",
                    background: "#F6F6FC",
                    borderRadius: 20,
                    color: "#17191E",
                    paddingLeft: 8,
                    paddingRight: 8,
                    fontSize: 12,
                    fontFamily: "Rubik",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    height: 26,
                    textOverflow: "ellipsis",
                    // display: "flex",
                    alignItems: "center",
                    paddingTop: 3,
                    alignItems: "center",
                  }}
                >
                  {item.email}
                </Box>
                <Box
                  style={{ width: "5%", paddingLeft: 8 }}
                  onClick={() => deleteRol(item.id)}
                  className="role-close-vl"
                  id={`detele-role-admin-${index}`}
                >
                  <img src={close} alt="" />
                </Box>
              </Box>
            ))}
        </Box>
        {!isSmall && (
          <Box>
            <Box
              style={{
                fontSize: 14,
                fontWeight: 600,
                fontFamily: "Rubik",
                marginBottom: 8,
                marginTop: 50,
              }}
            >
              {t("roles.supervisor.string")}
              {/* Supervisor */}
            </Box>
            {roles
              .filter((role) => role.type === "supervisor")
              .map((item, index) => (
                <Box
                  style={{
                    display: "flex",
                    marginBottom: 8,

                    alignItems: "center",
                  }}
                  key={`supervisor-${index}`}
                >
                  <Box
                    style={{
                      width: "47.5%",
                      fontSize: 12,
                      fontFamily: "Rubik",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      height: 26,
                      textOverflow: "ellipsis",
                      // display: "flex",
                      alignItems: "center",
                      paddingTop: 3,
                    }}
                  >
                    {`${item.name} ${item.lastName}`}
                  </Box>
                  <Box
                    style={{
                      width: "47.5%",
                      background: "#F6F6FC",
                      borderRadius: 20,
                      color: "#17191E",
                      paddingLeft: 8,
                      paddingRight: 8,
                      fontSize: 12,
                      fontFamily: "Rubik",
                      height: 26,
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      // display: "flex",
                      alignItems: "center",
                      paddingTop: 3,
                    }}
                  >
                    {item.email}
                  </Box>
                  <Box
                    style={{
                      width: "5%",

                      paddingLeft: 8,
                    }}
                    className="role-close-vl"
                    id={`detele-role-supervisor-${index}`}
                    onClick={() => deleteRol(item.id)}
                  >
                    <img src={close} alt="" />
                  </Box>
                </Box>
              ))}
          </Box>
        )}

        <Box>
          <Box
            style={{
              fontSize: 14,
              fontWeight: 600,
              fontFamily: "Rubik",
              marginBottom: 8,
              marginTop: 50,
            }}
          >
            {t("roles.agent.string")}
          </Box>
          {roles
            .filter((role) => role.type === "agent")
            .map((item, index) => (
              <Box
                style={{
                  display: "flex",
                  marginBottom: 8,
                  alignItems: "center",
                  height: 26,
                }}
                key={`agent-${index}`}
              >
                <Box
                  style={{
                    width: "47.5%",
                    fontSize: 12,
                    fontFamily: "Rubik",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    height: 26,
                    // display: "flex",
                    alignItems: "center",
                    paddingTop: 3,
                  }}
                >
                  {`${item.name} ${item.lastName}`}
                </Box>
                <Box
                  style={{
                    width: "47.5%",
                    background: "#F6F6FC",
                    borderRadius: 20,
                    color: "#17191E",
                    paddingLeft: 8,
                    paddingRight: 8,
                    fontSize: 12,
                    fontFamily: "Rubik",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    height: 26,
                    // display: "flex",
                    alignItems: "center",
                    paddingTop: 3,
                  }}
                >
                  {item.email}
                </Box>
                <Box
                  style={{ width: "5%", paddingLeft: 8 }}
                  onClick={() => deleteRol(item.id)}
                  id={`detele-role-agent-${index}`}
                  className="role-close-vl"
                >
                  <img src={close} alt="" />
                </Box>
              </Box>
            ))}
        </Box>
      </Box>
    </Box>
  );
}

export default ListRoles;
