import { useState, useEffect } from "react";
import { Box, Dialog, Backdrop } from "@mui/material";
import { styled } from "@mui/material/styles";

import mobileBackground from "../../assets/mobile_background.png";
import expandMore from "../../assets/expandMore.png";
import ContactCenterForm from "../../components/contactCenterForm";
import { consts } from "../../consts";
import VideolinkHook from "../../components/videolinkHook";
import AttentionsForm from "../../components/attentionsForm";

function MobileView({
  t,
  lang,
  setLang,
  banner,
  principalColor,
  buttonColor,
  hookTitle,
  setHookTitle,
  hookSubtitle,
  setHookSubtitle,
  buttonText,
  setButtonText,
  attentions,
  isOpenButtonColorPicker,
  setIsOpenButtonColorPicker,
  setButtonColorAttentions,
  buttonColorAttentions,
  onDeleteAttention,
  onAddAttention,
  onChangeColor,
  selectedAttention,
  setSelectedAttention,
  onHandleChangeTitle,
  onHandleChangeDescription,
  updateAttentions,
  onBack,
  isLoading,
  iconColor,
  onChangeIcon,
  showTour,
  isCreation,
  setIsCreation,
  isOpen,
}) {
  const [isOpenDialog] = useState(true);
  const [isShowHook, setIsShowHook] = useState(false);
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <Box style={{ height: "100vh", overflow: isOpen && "hidden" }}>
      {!isShowHook ? (
        <>
          <Box
            style={{
              height: isOpenDialog
                ? height >= 1180
                  ? "15vh"
                  : "25vh"
                : "45vh",
              width: "100%",
              background:
                "linear-gradient(153.91deg, #E53463 0.87%, #4B27EF 83.55%)",
              backgroundSize: "cover",
              display: "flex",
              padding: 12,
            }}
          ></Box>

          <Box
            style={{
              // display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              // margin: "auto",
              marginTop:
                height < 600
                  ? -130
                  : height > 844 && height < 1366
                  ? -145
                  : -175,
              paddingLeft: 8,
              paddingRight: 8,
            }}
          >
            <AttentionsForm
              t={t}
              lang={lang}
              hookTitle={hookTitle}
              setHookTitle={setHookTitle}
              hookSubtitle={hookSubtitle}
              setHookSubtitle={setHookSubtitle}
              buttonText={buttonText}
              setButtonText={setButtonText}
              attentions={attentions}
              isOpenButtonColorPicker={isOpenButtonColorPicker}
              setIsOpenButtonColorPicker={setIsOpenButtonColorPicker}
              setButtonColorAttentions={setButtonColorAttentions}
              buttonColorAttentions={buttonColorAttentions}
              onAddAttention={onAddAttention}
              onChangeColor={onChangeColor}
              selectedAttention={selectedAttention}
              setSelectedAttention={setSelectedAttention}
              onHandleChangeTitle={onHandleChangeTitle}
              onHandleChangeDescription={onHandleChangeDescription}
              updateAttentions={updateAttentions}
              onBack={onBack}
              isMobile={true}
              setIsShowHook={setIsShowHook}
              isLoading={isLoading}
              iconColor={iconColor}
              onChangeIcon={onChangeIcon}
              onDeleteAttention={onDeleteAttention}
              isCreation={isCreation}
              setIsCreation={setIsCreation}
              showTour={showTour}
              height={height}
              width={width}
            />
          </Box>
        </>
      ) : (
        <Box
          style={{
            height: "100vh",
            width: "100%",
            background:
              "linear-gradient(153.91deg, #E53463 0.87%, #4B27EF 83.55%)",
            backgroundSize: "cover",
          }}
        >
          <VideolinkHook
            t={t}
            lang={lang}
            setLang={setLang}
            banner={banner}
            principalColor={principalColor}
            buttonColor={buttonColor}
            page={consts.mode_attentions}
            hookTitle={hookTitle}
            hookSubtitle={hookSubtitle}
            attentions={attentions}
            buttonText={buttonText}
            buttonColorAttentions={buttonColorAttentions}
            onDeleteAttention={onDeleteAttention}
            isMobile={true}
            setIsShowHook={setIsShowHook}
            height={height}
          />
        </Box>
      )}
    </Box>
  );
}

export default MobileView;
