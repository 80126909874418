import { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Backdrop from "@mui/material/Backdrop";

import mobileBackground from "../../assets/mobile_background.png";
import mobileImageRegistry from "../../assets/mobile_image_registry.png";
import mobileLogo from "../../assets/logo_videolink_mobile.svg";
import RegistryForm from "../../components/registryForm";
import ChangePasswordForm from "../../components/changePasswordForm";

function MobileView({
  t,
  lang,
  setLang,
  isMobile,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  isChecked,
  setIsChecked,
  onConfirmAccount,
}) {
  const [isOpenDialog] = useState(true);

  return (
    <Box style={{ height: "100vh" }}>
      <Box
        style={{
          height: isOpenDialog ? "25vh" : "45vh",
          width: "100%",
          background:
            "linear-gradient(153.91deg, #E53463 0.87%, #4B27EF 83.55%)",
          backgroundSize: "cover",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          padding: 24,
        }}
      >
        {!isOpenDialog && (
          <img
            alt=""
            style={{ width: "225px", marginBottom: 24 }}
            src={mobileImageRegistry}
          />
        )}

        <img src={mobileLogo} alt="" />
      </Box>
      <Dialog
        fullScreen
        open={true}
        // TransitionComponent={Transition}
        PaperProps={{
          style: {
            borderTopLeftRadius: 10,
            height: isOpenDialog ? "98%" : "60%",
            position: "fixed",
            bottom: 0,
            width: "95%",
            borderTopRightRadius: 10,
          },
        }}
        BackdropComponent={styled(Backdrop, {
          name: "MuiModal",
          slot: "Backdrop",
          overridesResolver: (props, styles) => {
            return styles.backdrop;
          },
        })({
          zIndex: -1,
          position: "relative",
        })}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            // justifyContent: "center",
            marginLeft: "auto",
            marginRight: "auto",
            padding: 16,
            paddingTop: 24,
          }}
        >
          <ChangePasswordForm
            t={t}
            isMobile={isMobile}
            isConfirmAccount={true}
            password={password}
            setPassword={setPassword}
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            onConfirmAccount={onConfirmAccount}
          />
        </Box>
      </Dialog>
    </Box>
  );
}

export default MobileView;
