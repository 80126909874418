import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";
import useMediaQuery from "@mui/material/useMediaQuery";

import i18n from "../i18n";
import MobileView from "../views/changePassword/mobile";
import DesktopView from "../views/changePassword/desktop";
import service from "../service";
import { consts } from "../consts";
import { environment } from "../config";

function ChangePasword({ isMobile }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const params = useParams();
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation();
  const [lang, setLang] = useState("es");

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  useEffect(() => {
    async function validate() {
      const result = await service.validateTokenStatus(params.token);
      if (result.token.status === "used") {
        navigate(`/confirmation/${result.token.token}`);
      }
    }
    validate();
  }, [params]);

  return !mobileBreakpoint ? (
    <MobileView
      t={t}
      lang={lang}
      setLang={setLang}
      isMobile={!mobileBreakpoint}
      password={password}
      setPassword={setPassword}
      confirmPassword={confirmPassword}
      setConfirmPassword={setConfirmPassword}
      isChecked={isChecked}
      setIsChecked={setIsChecked}
      onConfirmAccount={onConfirmAccount}
    />
  ) : (
    <DesktopView
      t={t}
      lang={lang}
      setLang={setLang}
      isMobile={!mobileBreakpoint}
      password={password}
      setPassword={setPassword}
      confirmPassword={confirmPassword}
      setConfirmPassword={setConfirmPassword}
      isChecked={isChecked}
      setIsChecked={setIsChecked}
      onConfirmAccount={onConfirmAccount}
    />
  );

  async function onConfirmAccount(password) {
    try {
      const data = await service.validateAccount({
        token: params.token,
        password: password,
      });
      console.log(data.type);
      switch (data.type) {
        case "admin":
          window.location.href = environment.adminURL;
          break;
        case "agent":
          window.location.href = environment.agentURL;
          break;
        case "supervisor":
          window.location.href = environment.adminURL;
          break;
      }
    } catch (err) {
      console.log("[Err validate token] =>", err);
    }
  }
}

export default ChangePasword;
