export const isProd = process.env.REACT_APP_MODE === "PROD";
export const REACT_URL = window.location.host;
export const TOKEN_KEY = `user_token`;

const ENVIRONMENTS = {
  PROD: {
    storage: "https://cdn.tk.videolink.app",
    server: "https://api.tk.videolink.app",
    agentURL: "https://agent.tk.videolink.app",
    adminURL: "https://admin.tk.videolink.app",
    defaultBanner: "https://cdn.videolink.app/c/63a08f2a190bc20039b960bc",
  },
  DEV: {
    storage: "https://cdn.tk.videolink.app",
    server: "https://api.tk.videolink.app",
    agentURL: "https://agent.tk.videolink.app",
    adminURL: "https://admin.tk.videolink.app",
    defaultBanner: "https://cdn.videolink.app/c/63a08f2a190bc20039b960bc",
  },
  QA: {
    storage: "https://cdn.tk.videolink.app",
    server: "https://api.tk.videolink.app",
    agentURL: "https://agent.tk.videolink.app",
    adminURL: "https://admin.tk.videolink.app",
    defaultBanner: "https://cdn.videolink.app/c/63a08f2a190bc20039b960bc",
  },
  LOCAL: {
    storage: "http://localhost:3003",
    server: "http://localhost:3001",
    agentURL: "http://localhost:4300",
    adminURL: "http://localhost:4200",
    defaultBanner: "https://cdn.videolink.app/c/63a08f2a190bc20039b960bc",
  },
};

export const environment = ENVIRONMENTS[process.env.REACT_APP_MODE]
  ? ENVIRONMENTS[process.env.REACT_APP_MODE]
  : ENVIRONMENTS["LOCAL"];

console.log("MODE", process.env.REACT_APP_MODE);

function getServerHost() {
  const apiUrl = environment.server;

  if (apiUrl) {
    return apiUrl;
  } else {
    return isProd
      ? `http://${window.location.host}`
      : `http://${window.location.hostname}:3003`;
  }
}

//URL
export const SERVER_HOST = getServerHost();

export const API_BASE_URL = `${SERVER_HOST}`;
