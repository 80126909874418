import { useState, useEffect } from "react";
import { Box, Dialog, Backdrop } from "@mui/material";
import { styled } from "@mui/material/styles";

import mobileBackground from "../../assets/mobile_background.png";
import RolesForm from "../../components/rolesForm";
import DialogConfirmationRoles from "../../components/dialogConfirmationRoles";

function MobileView({
  t,
  setLang,
  lang,
  onBack,
  adminName,
  setAdminName,
  supervisorName,
  setSupervisorName,
  agentName,
  setAgentName,
  adminEmail,
  setAdminEmail,
  supervisorEmail,
  setSupervisorEmail,
  agentEmail,
  setAgentEmail,
  roles,
  addRol,
  deleteRol,
  onCreateUsers,
  isSmall,
  setIsSmall,
  agentLastName,
  setAgentLastName,
  supervisorLastName,
  setSupervisorLastName,
  adminLastName,
  setAdminLastName,
  isLoading,
  open,
  user,
  setOpen,
  showTour,
  isOpen,
}) {
  const [isOpenDialog] = useState(true);
  const [isShowHook, setIsShowHook] = useState(false);
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <Box style={{ height: "100vh", overflow: isOpen && "hidden" }}>
      {!isShowHook ? (
        <>
          <Box
            style={{
              height: isOpenDialog
                ? height >= 1180
                  ? "15vh"
                  : "25vh"
                : "45vh",
              width: "100%",
              // backgroundImage: `url(${mobileBackground})`,
              background:
                "linear-gradient(153.91deg, #E53463 0.87%, #4B27EF 83.55%)",
              backgroundSize: "cover",
              display: "flex",
              padding: 12,
            }}
          ></Box>

          <Box
            style={{
              // display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              // margin: "auto",
              marginTop:
                height < 600
                  ? -130
                  : height > 844 && height < 1366
                  ? -145
                  : -175,
              paddingLeft: 8,
              paddingRight: 8,
            }}
          >
            <RolesForm
              t={t}
              lang={lang}
              setLang={setLang}
              onBack={onBack}
              adminName={adminName}
              setAdminName={setAdminName}
              supervisorName={supervisorName}
              setSupervisorName={setSupervisorName}
              agentName={agentName}
              setAgentName={setAgentName}
              adminEmail={adminEmail}
              setAdminEmail={setAdminEmail}
              supervisorEmail={supervisorEmail}
              setSupervisorEmail={setSupervisorEmail}
              agentEmail={agentEmail}
              setAgentEmail={setAgentEmail}
              roles={roles}
              addRol={addRol}
              onCreateUsers={onCreateUsers}
              isMobile={true}
              roles={roles}
              deleteRol={deleteRol}
              isSmall={isSmall}
              setIsSmall={setIsSmall}
              agentLastName={agentLastName}
              setAgentLastName={setAgentLastName}
              supervisorLastName={supervisorLastName}
              setSupervisorLastName={setSupervisorLastName}
              adminLastName={adminLastName}
              setAdminLastName={setAdminLastName}
              isLoading={isLoading}
              open={open}
              setOpen={setOpen}
              user={user}
              showTour={showTour}
              height={height}
              width={width}
            />
          </Box>
        </>
      ) : (
        <Box
          style={{
            height: "100vh",
            width: "100%",
            background:
              "linear-gradient(153.91deg, #E53463 0.87%, #4B27EF 83.55%)",
            backgroundSize: "cover",
            // display: "flex",
            // padding: 12,
          }}
        ></Box>
      )}
      <DialogConfirmationRoles
        open={open}
        setOpen={setOpen}
        onCreateUsers={onCreateUsers}
        isLoading={isLoading}
        t={t}
      />
    </Box>
  );
}

export default MobileView;
