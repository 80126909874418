import { useState } from "react";
import Box from "@mui/material/Box";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { Button } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

import HeaderConfig from "./hedaerConfig";
import StepperVideolink from "./stepper";
import Snackbar from "@mui/material/Snackbar";
import { environment } from "../config";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#17191E !important",
    width: "auto !important",
    color: "#FFFFFF !important",
    fontSize: 14,
    borderRadius: 5,
    boxShadow: "none",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#17191E !important",
  },
}));

function ListLinks({
  t,
  setLang,
  lang,
  onBack,
  isMobile,
  onFinish,
  contactCenterURL,
  height,
  width,
}) {
  const activeStep = 3;
  const [open, setOpen] = useState(false);
  return (
    <Box
      style={{
        maxWidth: !isMobile ? 750 : height >= 1180 ? 895 : 750,
        margin: "auto",
      }}
    >
      <HeaderConfig t={t} isMobile={isMobile} isFinish={true} height={height} />
      <StepperVideolink activeStep={activeStep} />
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 48,
          padding: isMobile && 8,
          paddingLeft: !isMobile ? 42 : 24,
        }}
      >
        <Box
          style={{
            fontFamily: "Rubik",
            fontSize: !isMobile ? 14 : height >= 1024 || width >=1023 ? 24 : 14,
            fontWeight: 600,
          }}
        >
          {t("links.string")}
          {/* Enlaces */}
        </Box>
        <Box style={{ paddingTop: 8, paddingLeft: 4 }}>
          <LightTooltip title={t("tooltip.links")} arrow placement="right">
            <InfoIcon color="primary" style={{ width: 16 }} />
          </LightTooltip>
        </Box>
      </Box>
      <Box
        style={{
          backgroundColor: "#FBFBFF",
          // borderRadius: 10,
          width: "100%",
          padding: 24,
          paddingRight: !isMobile ? 42 : 24,
          paddingLeft: !isMobile ? 42 : 24,
          border: "1px solid #AFB8CD",
        }}
      >
        <Box
          style={{
            // display: !isMobile && "flex",
            textAlign: "left !important",

            paddingLeft: !isMobile && 8,
          }}
        >
          <Box
            style={{
              fontFamily: "Rubik",
              fontSize: !isMobile ? 14 : height >= 1024 || width >=1023 ? 24 : 14,
              fontWeight: 600,
              color: "#17191E",
              display: "flex",
            }}
          >
            {t("links.admin.url")}
            {/* Link de administrador */}
          </Box>
        </Box>
        <Box
          style={{
            display: !isMobile && "flex",
            marginTop: isMobile && 8,
            alignItems: "center",
          }}
        >
          <Box
            style={{
              backgroundColor: "#E6E7F3",
              marginRight: 4,
              display: "flex",
              paddingLeft: !isMobile && 16,
              paddingRight: !isMobile && 16,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
              fontSize: !isMobile ? 14 : height >= 1024 || width >=1023 ? 24 : 14,
            }}
          >
            {environment.adminURL}
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: isMobile && "end",
              marginLeft: !isMobile && "auto",
              marginTop: isMobile && 24,
            }}
          >
            <Box style={{ marginLeft: !isMobile && "auto" }}>
              <Button
                onClick={() => copyToClipboard("adminURL")}
                style={{
                  fontFamily: "Rubik",
                  fontWeight: 600,
                  color: "#FFFFFF",
                  backgroundColor: "#EA345F",
                  borderRadius: height >= 1024 || width >=1023 ? 40 : 20,
                  width: height >= 1024 || width >=1023 && 120,
                  textTransform: "inherit",
                  fontSize: !isMobile ? 14 : height >= 1024 || width >=1023 ? 24 : 14,
                  marginRight: 8,
                }}
              >
                {t("links.copy.string")}
              </Button>
            </Box>

            <Box>
              <a
                href={environment.adminURL}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <Button
                  style={{
                    fontFamily: "Rubik",
                    fontWeight: 600,
                    color: "#FFFFFF",
                    backgroundColor: "#52596B",
                    borderRadius: height >= 1024 || width >=1023 ? 40 : 20,
                    width: height >= 1024 || width >=1023 && 120,
                    textTransform: "inherit",
                    fontSize: !isMobile ? 14 : height >= 1024 || width >=1023 ? 24 : 14,
                  }}
                >
                  {t("links.go.string")}
                </Button>
              </a>
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            display: "flex",
            marginTop: 30,
            paddingLeft: !isMobile && 8,
          }}
        >
          <Box
            style={{
              fontFamily: "Rubik",
              fontSize: !isMobile ? 14 : height >= 1024 || width >=1023 ? 24 : 14,
              fontWeight: 600,
              color: "#17191E",
            }}
          >
            {t("links.agent.url")}
            {/* Link de agente */}
          </Box>
        </Box>
        <Box
          style={{
            display: !isMobile && "flex",
            marginTop: isMobile && 8,
            alignItems: "center",
          }}
        >
          <Box
            id="agentURL"
            style={{
              backgroundColor: "rgb(230, 231, 243)",
              marginRight: 4,
              display: "flex",
              paddingLeft: !isMobile && 16,
              paddingRight: !isMobile && 16,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
              fontSize: !isMobile ? 14 : height >= 1024 || width >=1023 ? 24 : 14,
            }}
          >
            {environment.agentURL}
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: isMobile && "end",
              marginLeft: !isMobile && "auto",
              marginTop: isMobile && 24,
            }}
          >
            <Box style={{ marginLeft: "auto" }}>
              <Button
                onClick={() => copyToClipboard("agentURL")}
                style={{
                  fontFamily: "Rubik",
                  fontWeight: 600,
                  color: "#FFFFFF",
                  backgroundColor: "#EA345F",
                  borderRadius: height >= 1024 || width >=1023 ? 40 : 20,
                  width: height >= 1024 || width >=1023 && 120,
                  textTransform: "inherit",
                  fontSize: !isMobile ? 14 : height >= 1024 || width >=1023 ? 24 : 14,
                  marginRight: 8,
                }}
              >
                {t("links.copy.string")}
              </Button>
            </Box>
            <Box>
              <a
                href={environment.agentURL}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <Button
                  style={{
                    fontFamily: "Rubik",
                    fontWeight: 600,
                    color: "#FFFFFF",
                    backgroundColor: "#52596B",
                    borderRadius: height >= 1024 || width >=1023 ? 40 : 20,
                    width: height >= 1024 || width >=1023 && 120,
                    textTransform: "inherit",
                    fontSize: !isMobile ? 14 : height >= 1024 || width >=1023 ? 24 : 14,
                  }}
                >
                  {t("links.go.string")}
                </Button>
              </a>
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            display: "flex",
            marginTop: 30,
            paddingLeft: !isMobile && 8,
          }}
        >
          <Box
            style={{
              fontFamily: "Rubik",
              fontSize: !isMobile ? 14 : height >= 1024 || width >=1023 ? 24 : 14,
              fontWeight: 600,
              color: "#17191E",
            }}
          >
            {t("links.supervisor.url")}
            {/* Link de supervisor */}
          </Box>
        </Box>
        <Box
          style={{
            display: !isMobile && "flex",
            marginTop: isMobile && 8,
            alignItems: "center",
          }}
        >
          <Box
            id="adminURL"
            style={{
              backgroundColor: "rgb(230, 231, 243)",
              marginRight: 4,
              display: "flex",
              paddingLeft: !isMobile && 16,
              paddingRight: !isMobile && 16,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
              fontSize: !isMobile ? 14 : height >= 1024 || width >=1023 ? 24 : 14,
            }}
          >
            {environment.adminURL}
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: isMobile && "end",
              marginLeft: !isMobile && "auto",
              marginTop: isMobile && 24,
            }}
          >
            <Box style={{ marginLeft: "auto" }}>
              <Button
                style={{
                  fontFamily: "Rubik",
                  fontWeight: 600,
                  color: "#FFFFFF",
                  backgroundColor: "#EA345F",
                  borderRadius: height >= 1024 || width >=1023 ? 40 : 20,
                  width: height >= 1024 || width >=1023 && 120,
                  textTransform: "inherit",
                  fontSize: !isMobile ? 14 : height >= 1024 || width >=1023 ? 24 : 14,
                  marginRight: 8,
                }}
                onClick={() => copyToClipboard("adminURL")}
              >
                {t("links.copy.string")}
              </Button>
            </Box>
            <Box>
              <a
                href={environment.adminURL}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <Button
                  style={{
                    fontFamily: "Rubik",
                    fontWeight: 600,
                    color: "#FFFFFF",
                    backgroundColor: "#52596B",
                    borderRadius: height >= 1024 || width >=1023 ? 40 : 20,
                    width: height >= 1024 || width >=1023 && 120,
                    textTransform: "inherit",
                    fontSize: !isMobile ? 14 : height >= 1024 || width >=1023 ? 24 : 14,
                  }}
                >
                  {t("links.go.string")}
                </Button>
              </a>
            </Box>
          </Box>
        </Box>
        <Box
          style={{
            display: "flex",
            marginTop: 30,
            paddingLeft: !isMobile && 8,
          }}
        >
          <Box
            style={{
              fontFamily: "Rubik",
              fontSize: !isMobile ? 14 : height >= 1024 || width >=1023 ? 24 : 14,
              fontWeight: 600,
              color: "#17191E",
            }}
          >
            {t("links.contact.url")}
          </Box>
        </Box>
        <Box
          style={{
            display: !isMobile && "flex",
            marginTop: isMobile && 8,
            alignItems: "center",
          }}
        >
          <Box
            id="contactCenterURL"
            style={{
              backgroundColor: "rgb(230, 231, 243)",
              marginRight: 4,
              display: "flex",
              paddingLeft: !isMobile && 16,
              paddingRight: !isMobile && 16,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
              fontSize: !isMobile ? 14 : height >= 1024 || width >=1023 ? 24 : 14,
            }}
          >
            {`https://${contactCenterURL}`}
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: isMobile && "end",
              marginLeft: !isMobile && "auto",
              marginTop: isMobile && 24,
            }}
          >
            <Box style={{ marginLeft: "auto" }}>
              <Button
                style={{
                  fontFamily: "Rubik",
                  fontWeight: 600,
                  color: "#FFFFFF",
                  backgroundColor: "#EA345F",
                  borderRadius: height >= 1024 || width >=1023 ? 40 : 20,
                  width: height >= 1024 || width >=1023 && 120,
                  textTransform: "inherit",
                  fontSize: !isMobile ? 14 : height >= 1024 || width >=1023 ? 24 : 14,
                  marginRight: 8,
                }}
                onClick={() => copyToClipboard("contactCenterURL")}
              >
                {t("links.copy.string")}
              </Button>
            </Box>
            <Box>
              <a
                href={`https://${contactCenterURL}`}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <Button
                  style={{
                    fontFamily: "Rubik",
                    fontWeight: 600,
                    color: "#FFFFFF",
                    backgroundColor: "#52596B",
                    borderRadius: height >= 1024 || width >=1023 ? 40 : 20,
                    width: height >= 1024 || width >=1023 && 120,
                    textTransform: "inherit",
                    fontSize: !isMobile ? 14 : height >= 1024 || width >=1023 ? 24 : 14,
                  }}
                >
                  {t("links.go.string")}
                </Button>
              </a>
            </Box>
          </Box>
        </Box>
        {isMobile ? (
          <Box style={{ marginTop: 32 }}>
            {/* <Button
              onClick={() => onFinish()}
              fullWidth
              style={{
                textTransform: "inherit",
                borderRadius: 20,
                fontSize: 14,
                fontWeight: 700,
                boxShadow: "none",
                height: 32,
                color: "#FFFFFF",
                backgroundColor: "#EB3E67",
                marginBottom: 8,
                fontFamily: "Rubik",
              }}
              variant="contained"
              size="small"
            >
              Finalizar
            </Button> */}
            {/* <Button
              onClick={() => onBack()}
              fullWidth
              style={{
                textTransform: "inherit",
                borderRadius: 20,
                fontSize: 14,
                fontWeight: 700,
                boxShadow: "none",
                backgroundColor: "#FFFFFF",
                color: "#52596B",
                border: "1px solid #52596B",
                height: 32,
                marginBottom: 8,
                fontFamily: "Rubik",
              }}
              variant="contained"
            >
              Volver
            </Button> */}
          </Box>
        ) : (
          <Box
            style={{ display: "flex", justifyContent: "end", marginTop: 48 }}
          >
            {/* <Button
              onClick={() => onBack()}
              style={{
                textTransform: "inherit",
                borderRadius: 20,
                fontSize: 14,
                fontWeight: 700,
                boxShadow: "none",
                width: "25%",
                marginRight: 12,
                backgroundColor: "#FFFFFF",
                color: "#52596B",
                border: "1px solid #52596B",
                height: 32,
                fontFamily: "Rubik",
              }}
              variant="contained"
            >
              Volver
            </Button> */}
            {/* <Button
              onClick={() => onFinish()}
              style={{
                textTransform: "inherit",
                borderRadius: 20,
                fontSize: 14,
                fontWeight: 700,
                boxShadow: "none",
                width: "25%",
                height: 32,
                color: "#FFFFFF",
                backgroundColor: "#EB3E67",
                fontFamily: "Rubik",
              }}
              variant="contained"
              size="small"
            >
              Finalizar
            </Button> */}
          </Box>
        )}
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={2000}
        onClose={() => {
          setOpen(false);
        }}
        message="Copiado al portapapeles"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />
    </Box>
  );

  function copyToClipboard(id) {
    const text = document.getElementById(id);
    navigator.clipboard.writeText(text.textContent);
    setOpen(true);
  }
}

export default ListLinks;
