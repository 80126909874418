import { useState, useEffect } from "react";
import { Box, Dialog, Backdrop } from "@mui/material";
import { styled } from "@mui/material/styles";

import ContactCenterForm from "../../components/contactCenterForm";
import { consts } from "../../consts";
import VideolinkHook from "../../components/videolinkHook";

function MobileView({
  t,
  lang,
  setLang,
  banner,
  handleChageBanner,
  principalColor,
  setPrincipalColor,
  openColorPicker,
  setOpenColorPicker,
  buttonColor,
  setButtonColor,
  openColorPickerButton,
  setOpenColorPickerButton,
  navigate,
  onUpdatePreferences,
  isLoading,
  showTour,
  isOpen,
}) {
  const [isOpenDialog] = useState(true);
  const [isShowHook, setIsShowHook] = useState(false);
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
 
  return (
    <Box style={{ height: "100vh", overflow: isOpen && "hidden" }}>
      {!isShowHook ? (
        <>
          <Box
            style={{
              height: isOpenDialog
                ? height >= 1180
                  ? "15vh"
                  : "25vh"
                : "45vh",
              width: "100%",
              background:
                "linear-gradient(153.91deg, #E53463 0.87%, #4B27EF 83.55%)",
              backgroundSize: "cover",
              display: "flex",
              padding: 12,
            }}
          ></Box>
          <Box
            style={{
              // display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              // margin: "auto",
              marginTop:
                height < 600
                  ? -130
                  : height > 844 && height < 1366
                  ? -145
                  : -175,
              paddingLeft: 8,
              paddingRight: 8,
            }}
          >
            <ContactCenterForm
              t={t}
              lang={lang}
              setLang={setLang}
              banner={banner}
              handleChageBanner={handleChageBanner}
              principalColor={principalColor}
              setPrincipalColor={setPrincipalColor}
              openColorPicker={openColorPicker}
              setOpenColorPicker={setOpenColorPicker}
              buttonColor={buttonColor}
              setButtonColor={setButtonColor}
              openColorPickerButton={openColorPickerButton}
              setOpenColorPickerButton={setOpenColorPickerButton}
              onUpdatePreferences={onUpdatePreferences}
              navigate={navigate}
              isMobile={true}
              isLoading={isLoading}
              setIsShowHook={setIsShowHook}
              showTour={showTour}
              height={height}
              width={width}
            />
          </Box>
        </>
      ) : (
        <Box
          style={{
            height: "100vh",
            width: "100%",
            background:
              "linear-gradient(153.91deg, #E53463 0.87%, #4B27EF 83.55%)",
            backgroundSize: "cover",
          }}
        >
          <VideolinkHook
            t={t}
            lang={lang}
            setLang={setLang}
            banner={banner}
            principalColor={principalColor}
            buttonColor={buttonColor}
            page={consts.mode_contact}
            isMobile={true}
            setIsShowHook={setIsShowHook}
            height={height}
          />
        </Box>
      )}
    </Box>
  );
}

export default MobileView;
