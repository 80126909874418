import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { TourProvider } from "@reactour/tour";
import { components } from "@reactour/tour";
import { Box, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import Swal from "sweetalert2";
import useMediaQuery from "@mui/material/useMediaQuery";

import { EasyContext } from "../context";
import { environment, REACT_URL } from "../config";
import i18n from "../i18n";
import MobileView from "../views/rolesConfig/mobile";
import DesktopView from "../views/rolesConfig/desktop";
import service from "../service";

function RolesConfig() {
  const theme = useTheme();
  const navigate = useNavigate();

  const mobileBreakpoint = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation();
  const { state, setConfigRoles } = useContext(EasyContext);
  const [lang, setLang] = useState(state.user ? state.user.lang : "es");
  const [adminName, setAdminName] = useState("");
  const [supervisorName, setSupervisorName] = useState("");
  const [agentName, setAgentName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [supervisorEmail, setSupervisorEmail] = useState("");
  const [agentEmail, setAgentEmail] = useState("");
  const [roles, setRoles] = useState(!state.roles ? [] : state.roles);
  const [isSmall, setIsSmall] = useState(true);
  const [agentLastName, setAgentLastName] = useState("");
  const [adminLastName, setAdminLastName] = useState("");
  const [supervisorLastName, setSupervisorLastName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const steps = [
    {
      selector: ".roles-container-step",
      content: (
        <Box style={{ padding: 4 }}>
          <Box
            style={{
              fontFamily: "Rubik",
              fontWeight: "bold",
              fontSize: 14,
              color: "#17191E",
              marginBottom: 8,
            }}
          >
            {t("tour.role.step1.title")}
          </Box>
          <Box
            style={{
              fontFamily: "Rubik",
              // fontWeight: "bold",
              fontSize: 12,
              color: "#17191E",
            }}
          >
            {t("tour.role.step1.description")}
          </Box>
        </Box>
      ),
    },
    {
      selector: ".admin-step",
      content: (
        <Box style={{ padding: 4 }}>
          <Box
            style={{
              fontFamily: "Rubik",
              fontWeight: "bold",
              fontSize: 14,
              color: "#17191E",
              marginBottom: 8,
            }}
          >
            {t("tour.role.step2.title")}
          </Box>
          <Box
            style={{
              fontFamily: "Rubik",
              // fontWeight: "bold",
              fontSize: 12,
              color: "#17191E",
            }}
          >
            {t("tour.role.step2.description")}
          </Box>
        </Box>
      ),
    },
    {
      selector: !mobileBreakpoint ? ".listRoles-step" : ".ohters-roles",
      content: (
        <Box style={{ padding: 4 }}>
          <Box
            style={{
              fontFamily: "Rubik",
              fontWeight: "bold",
              fontSize: 14,
              color: "#17191E",
              marginBottom: 8,
            }}
          >
            {!mobileBreakpoint
              ? t("tour.contact.step6.title")
              : t("tour.role.step4.title")}
          </Box>
          <Box
            style={{
              fontFamily: "Rubik",
              // fontWeight: "bold",
              fontSize: 12,
              color: "#17191E",
            }}
          >
            {!mobileBreakpoint
              ? t("tour.role.step3.description")
              : t("tour.role.step4.description")}
          </Box>
        </Box>
      ),
    },

    {
      selector: mobileBreakpoint ? ".listRoles-step" : ".ohters-roles",
      content: (
        <Box style={{ padding: 4 }}>
          <Box
            style={{
              fontFamily: "Rubik",
              fontWeight: "bold",
              fontSize: 14,
              color: "#17191E",
              marginBottom: 8,
            }}
          >
            {mobileBreakpoint
              ? t("tour.contact.step6.title")
              : t("tour.role.step4.title")}
          </Box>
          <Box
            style={{
              fontFamily: "Rubik",
              // fontWeight: "bold",
              fontSize: 12,
              color: "#17191E",
            }}
          >
            {mobileBreakpoint
              ? t("tour.role.step3.description")
              : t("tour.role.step4.description")}
          </Box>
        </Box>
      ),
    },

    {
      selector: ".btn-accept-step",
      content: (
        <Box style={{ padding: 4 }}>
          <Box
            style={{
              fontFamily: "Rubik",
              fontWeight: "bold",
              fontSize: 14,
              color: "#17191E",
              marginBottom: 8,
            }}
          >
            {t("tour.role.step5.title")}
          </Box>
          <Box
            style={{
              fontFamily: "Rubik",
              // fontWeight: "bold",
              fontSize: 12,
              color: "#17191E",
            }}
          >
            {t("tour.role.step5.description")}
          </Box>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  useEffect(() => {
    if (!localStorage.getItem("account")) navigate("/");
  }, []);

  const RolesConfigComponent = ({ isOpen }) => {
    return !mobileBreakpoint ? (
      <MobileView
        t={t}
        lang={lang}
        setLang={setLang}
        onBack={onBack}
        adminName={adminName}
        setAdminName={setAdminName}
        supervisorName={supervisorName}
        setSupervisorName={setSupervisorName}
        agentName={agentName}
        setAgentName={setAgentName}
        adminEmail={adminEmail}
        setAdminEmail={setAdminEmail}
        supervisorEmail={supervisorEmail}
        setSupervisorEmail={setSupervisorEmail}
        agentEmail={agentEmail}
        setAgentEmail={setAgentEmail}
        roles={[{ id: 1, name: "example", lastName: "example", type: "admin" }]}
        addRol={addRol}
        deleteRol={deleteRol}
        onCreateUsers={onCreateUsers}
        isSmall={isSmall}
        setIsSmall={setIsSmall}
        agentLastName={agentLastName}
        setAgentLastName={setAgentLastName}
        supervisorLastName={supervisorLastName}
        setSupervisorLastName={setSupervisorLastName}
        adminLastName={adminLastName}
        setAdminLastName={setAdminLastName}
        isLoading={isLoading}
        open={open}
        setOpen={setOpen}
        user={state.user}
        showTour={showTour}
        isOpen={isOpen}
      />
    ) : (
      <DesktopView
        t={t}
        lang={lang}
        setLang={setLang}
        onBack={onBack}
        adminName={adminName}
        setAdminName={setAdminName}
        supervisorName={supervisorName}
        setSupervisorName={setSupervisorName}
        agentName={agentName}
        setAgentName={setAgentName}
        adminEmail={adminEmail}
        setAdminEmail={setAdminEmail}
        supervisorEmail={supervisorEmail}
        setSupervisorEmail={setSupervisorEmail}
        agentEmail={agentEmail}
        setAgentEmail={setAgentEmail}
        roles={roles}
        addRol={addRol}
        deleteRol={deleteRol}
        onCreateUsers={onCreateUsers}
        isSmall={isSmall}
        setIsSmall={setIsSmall}
        agentLastName={agentLastName}
        setAgentLastName={setAgentLastName}
        supervisorLastName={supervisorLastName}
        setSupervisorLastName={setSupervisorLastName}
        adminLastName={adminLastName}
        setAdminLastName={setAdminLastName}
        isLoading={isLoading}
        open={open}
        setOpen={setOpen}
        user={state.user}
        showTour={showTour}
      />
    );
  };

  return isOpen ? (
    <TourProvider
      steps={steps}
      defaultOpen={true}
      onClickClose={() => setIsOpen(false)}
      disableInteraction={true}
      onClickMask={() => {
        setIsOpen(false);
      }}
      position="right"
      styles={{
        controls: (base) => ({ ...base, justifyContent: "center" }),
      }}
      components={{ Badge }}
      badgeContent={({ totalSteps, currentStep }) =>
        `${currentStep + 1}/${totalSteps}`
      }
      nextButton={({ currentStep, setCurrentStep, stepsLength }) => (
        <IconButton
          disabled={currentStep + 1 === stepsLength}
          onClick={() => {
            if (!mobileBreakpoint) {
              if (currentStep === 0) {
                document.getElementById("panel1d-header").click();
                isSmall
                  ? console.log()
                  : document.getElementById("panel1d-header1").click();
                document.getElementById("panel1d-header2").click();
                setTimeout(() => {
                  setCurrentStep(currentStep + 1);
                }, 300);
              } else {
                setCurrentStep(currentStep + 1);
              }
            } else {
              setCurrentStep(currentStep + 1);
            }
          }}
        >
          <ArrowForwardIcon />
        </IconButton>
      )}
      prevButton={({ currentStep, setCurrentStep }) => (
        <IconButton
          disabled={currentStep === 0}
          onClick={() => {
            setCurrentStep(currentStep - 1);
          }}
        >
          <ArrowBackIcon />
        </IconButton>
      )}
    >
      <RolesConfigComponent isOpen={isOpen} />
    </TourProvider>
  ) : !mobileBreakpoint ? (
    <MobileView
      t={t}
      lang={lang}
      setLang={setLang}
      onBack={onBack}
      adminName={adminName}
      setAdminName={setAdminName}
      supervisorName={supervisorName}
      setSupervisorName={setSupervisorName}
      agentName={agentName}
      setAgentName={setAgentName}
      adminEmail={adminEmail}
      setAdminEmail={setAdminEmail}
      supervisorEmail={supervisorEmail}
      setSupervisorEmail={setSupervisorEmail}
      agentEmail={agentEmail}
      setAgentEmail={setAgentEmail}
      roles={roles}
      addRol={addRol}
      deleteRol={deleteRol}
      onCreateUsers={onCreateUsers}
      isSmall={isSmall}
      setIsSmall={setIsSmall}
      agentLastName={agentLastName}
      setAgentLastName={setAgentLastName}
      supervisorLastName={supervisorLastName}
      setSupervisorLastName={setSupervisorLastName}
      adminLastName={adminLastName}
      setAdminLastName={setAdminLastName}
      isLoading={isLoading}
      open={open}
      setOpen={setOpen}
      user={state.user}
      showTour={showTour}
    />
  ) : (
    <DesktopView
      t={t}
      lang={lang}
      setLang={setLang}
      onBack={onBack}
      adminName={adminName}
      setAdminName={setAdminName}
      supervisorName={supervisorName}
      setSupervisorName={setSupervisorName}
      agentName={agentName}
      setAgentName={setAgentName}
      adminEmail={adminEmail}
      setAdminEmail={setAdminEmail}
      supervisorEmail={supervisorEmail}
      setSupervisorEmail={setSupervisorEmail}
      agentEmail={agentEmail}
      setAgentEmail={setAgentEmail}
      roles={roles}
      addRol={addRol}
      deleteRol={deleteRol}
      onCreateUsers={onCreateUsers}
      isSmall={isSmall}
      setIsSmall={setIsSmall}
      agentLastName={agentLastName}
      setAgentLastName={setAgentLastName}
      supervisorLastName={supervisorLastName}
      setSupervisorLastName={setSupervisorLastName}
      adminLastName={adminLastName}
      setAdminLastName={setAdminLastName}
      isLoading={isLoading}
      open={open}
      setOpen={setOpen}
      user={state.user}
      showTour={showTour}
    />
  );

  function onBack() {
    setConfigRoles(roles, isSmall, true);
    return navigate("/attentions/config");
  }

  function showTour() {
    setIsOpen(true);
  }

  function Badge({ children }) {
    return (
      <components.Badge
        styles={{
          badge: (base) => ({
            ...base,
            backgroundColor: "#17191E",
            // width: 34,
          }),
        }}
      >
        {children}
      </components.Badge>
    );
  }

  async function addRol(payload) {
    const user = await service.getUser(payload.email);

    if (user.length > 0) {
      Swal.fire({
        customClass: {
          confirmButton: "class1",
        },
        icon: "error",
        text: "Ya existe el correo electrónico",
      });
    } else {
      const isExist = roles.find((rol) => rol.email === payload.email);
      if (isExist) {
        Swal.fire({
          customClass: {
            confirmButton: "class1",
          },
          icon: "error",
          text: "Ya existe el correo electrónico",
        });
      } else {
        setRoles([
          ...roles,
          {
            id: roles.length + 1,
            name: payload.name,
            lastName: payload.lastName,
            email: payload.email,
            type: payload.type,
          },
        ]);
      }
    }
  }

  function deleteRol(id) {
    const newRoles = roles.filter((role) => role.id !== id);
    setRoles(newRoles);
  }

  async function onCreateUsers() {
    try {
      const membershipData = {
        CC: true,
        FB: false,
        WA: false,
        admin: roles.filter((el) => el.type === "admin").length,
        agent: roles.filter((el) => el.type === "agent").length,
        analitycs: true,
        integration_web_mobile: 0,
        storage: 10,
        supervisor: 0,
        tecnical_support: "24/07/365",
        widget: 0,
      };
      setOpen(false);
      setIsLoading(true);
      const users = await service.createUsers({
        users: roles,
        companyId: state.user.company._id,
        baseURL: {
          admin: environment.adminURL,
          agent: environment.agentURL,
        },
        owner: `${state.user.firstname} ${state.user.lastname}`,
      });
      await service.updateMembership({
        type: "free",
        currentMembership: membershipData,
      });
      await setConfigRoles(roles, isSmall);
      setIsLoading(false);
      navigate("/links");
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      Swal.fire({
        customClass: {
          confirmButton: "class1",
        },
        icon: "error",
        text: t(err.dataResponse.msg),
      });
      console.log("[Err create users] => ", err);
    }
  }
}

export default RolesConfig;
