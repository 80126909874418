import Swal from "sweetalert2";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { TourProvider } from "@reactour/tour";
import { components } from "@reactour/tour";
import { Box, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import i18n from "../i18n";
import MobileView from "../views/attentionConfig/mobile";
import DesktopView from "../views/attentionConfig/desktop";
import service from "../service";
import { EasyContext } from "../context";
import { iconsData } from "../components/listIcons";

function AttentionsConfig() {
  const theme = useTheme();
  const navigate = useNavigate();
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation();
  const { state, setConfigAttentions } = useContext(EasyContext);
  const [lang, setLang] = useState(state.user ? state.user.lang : "en");
  const [hookTitle, setHookTitle] = useState("");
  const [hookSubtitle, setHookSubtitle] = useState("");
  const [buttonText, setButtonText] = useState(
    !state.buttonTitle ? "" : state.buttonTitle
  );
  const [isOpenButtonColorPicker, setIsOpenButtonColorPicker] = useState(false);
  const [buttonColor, setButtonColor] = useState(
    !state.buttonAttentionsColor ? "#AFB8CD" : state.buttonAttentionsColor
  );
  const [attentions, setAttentions] = useState([]);
  const [iconColor, seticonColor] = useState(
    !state.iconColor ? "#AFB8CD" : state.iconColor
  );
  const [selectedAttention, setSelectedAttention] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isCreation, setIsCreation] = useState(false);
  const steps = [
    {
      selector: ".title-step",
      content: (
        <Box style={{ padding: 4 }}>
          <Box
            style={{
              fontFamily: "Rubik",
              fontWeight: "bold",
              fontSize: 14,
              color: "#17191E",
              marginBottom: 8,
            }}
          >
            {t("tour.attention.step1.title")}
          </Box>
          <Box
            style={{
              fontFamily: "Rubik",
              // fontWeight: "bold",
              fontSize: 12,
              color: "#17191E",
            }}
          >
            {t("tour.attention.step1.descripion")}
          </Box>
        </Box>
      ),
    },
    {
      selector: ".color-step",
      content: (
        <Box style={{ padding: 4 }}>
          <Box
            style={{
              fontFamily: "Rubik",
              fontWeight: "bold",
              fontSize: 14,
              color: "#17191E",
              marginBottom: 8,
            }}
          >
            {t("tour.attention.step2.title")}
          </Box>
          <Box
            style={{
              fontFamily: "Rubik",
              // fontWeight: "bold",
              fontSize: 12,
              color: "#17191E",
            }}
          >
            {t("tour.attention.step2.description")}
          </Box>
        </Box>
      ),
    },
    {
      selector: ".step-attention-0",

      content: (
        <Box style={{ padding: 4 }}>
          <Box
            style={{
              fontFamily: "Rubik",
              fontWeight: "bold",
              fontSize: 14,
              color: "#17191E",
              marginBottom: 8,
            }}
          >
            {t("tour.attention.step3.title")}
          </Box>
          <Box
            style={{
              fontFamily: "Rubik",
              // fontWeight: "bold",
              fontSize: 12,
              color: "#17191E",
            }}
          >
            {t("tour.attention.step3.description")}
          </Box>
        </Box>
      ),
    },
    {
      selector: ".icon-attention-0",
      content: (
        <Box style={{ padding: 4 }}>
          <Box
            style={{
              fontFamily: "Rubik",
              fontWeight: "bold",
              fontSize: 14,
              color: "#17191E",
              marginBottom: 8,
            }}
          >
            {t("tour.attention.step4.title")}
          </Box>
          <Box
            style={{
              fontFamily: "Rubik",
              // fontWeight: "bold",
              fontSize: 12,
              color: "#17191E",
            }}
          >
            {t("tour.attention.step4.description")}
          </Box>
        </Box>
      ),
    },
    {
      selector: ".title-step-0",
      content: (
        <Box style={{ padding: 4 }}>
          <Box
            style={{
              fontFamily: "Rubik",
              fontWeight: "bold",
              fontSize: 14,
              color: "#17191E",
              marginBottom: 8,
            }}
          >
            {t("tour.attention.step5.title")}
          </Box>
          <Box
            style={{
              fontFamily: "Rubik",
              // fontWeight: "bold",
              fontSize: 12,
              color: "#17191E",
            }}
          >
            {t("tour.attention.step5.description")}
          </Box>
        </Box>
      ),
    },
    {
      selector: ".add-step",
      content: (
        <Box style={{ padding: 4 }}>
          <Box
            style={{
              fontFamily: "Rubik",
              fontWeight: "bold",
              fontSize: 14,
              color: "#17191E",
              marginBottom: 8,
            }}
          >
            {`+ ${t("tour.attention.step3.title")}`}
          </Box>
          <Box
            style={{
              fontFamily: "Rubik",
              // fontWeight: "bold",
              fontSize: 12,
              color: "#17191E",
            }}
          >
            {t("tour.attention.step6.description")}
          </Box>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    i18n.changeLanguage(lang);
    setHookTitle(!state.title ? "" : state.title);
    setHookSubtitle(!state.description ? "" : state.description);

    if (!state.attentions) {
      if (attentions.length === 0) {
        setAttentions([
          ...attentions,
          {
            id: 0,
            icon: "sentiment_satisfied",
            color: "#AFB8CD",
            title: "",
            description: "",
          },
          {
            id: 1,
            icon: "sentiment_satisfied",
            color: "#AFB8CD",
            title: "",
            description: "",
          },
        ]);
      }
    } else {
      setAttentions(state.attentions);
    }
  }, []);
  useEffect(() => {
    if (!localStorage.getItem("account")) navigate("/");
  }, []);

  const AttentionConfigComponent = ({ isOpen }) => {
    return !mobileBreakpoint ? (
      <MobileView
        t={t}
        lang={lang}
        setLang={setLang}
        banner={state.banner}
        principalColor={state.principalColor}
        buttonColor={state.buttonColor}
        hookTitle={hookTitle}
        setHookTitle={setHookTitle}
        hookSubtitle={hookSubtitle}
        setHookSubtitle={setHookSubtitle}
        buttonText={buttonText}
        setButtonText={setButtonText}
        attentions={attentions}
        isOpenButtonColorPicker={isOpenButtonColorPicker}
        setIsOpenButtonColorPicker={setIsOpenButtonColorPicker}
        buttonColorAttentions={buttonColor}
        setButtonColorAttentions={setButtonColor}
        onDeleteAttention={onDeleteAttention}
        onAddAttention={onAddAttention}
        onChangeColor={onChangeColor}
        selectedAttention={selectedAttention}
        setSelectedAttention={setSelectedAttention}
        onHandleChangeTitle={onHandleChangeTitle}
        onHandleChangeDescription={onHandleChangeDescription}
        updateAttentions={updateAttentions}
        onBack={onBack}
        isLoading={isLoading}
        onChangeIcon={onChangeIcon}
        iconColor={iconColor}
        showTour={showTour}
        isCreation={isCreation}
        setIsCreation={setIsCreation}
        isOpen={isOpen}
      />
    ) : (
      <DesktopView
        t={t}
        lang={lang}
        setLang={setLang}
        banner={state.banner}
        principalColor={state.principalColor}
        buttonColor={state.buttonColor}
        hookTitle={hookTitle}
        setHookTitle={setHookTitle}
        hookSubtitle={hookSubtitle}
        setHookSubtitle={setHookSubtitle}
        buttonText={buttonText}
        setButtonText={setButtonText}
        attentions={attentions}
        isOpenButtonColorPicker={isOpenButtonColorPicker}
        setIsOpenButtonColorPicker={setIsOpenButtonColorPicker}
        buttonColorAttentions={buttonColor}
        setButtonColorAttentions={setButtonColor}
        onDeleteAttention={onDeleteAttention}
        onAddAttention={onAddAttention}
        onChangeColor={onChangeColor}
        selectedAttention={selectedAttention}
        setSelectedAttention={setSelectedAttention}
        onHandleChangeTitle={onHandleChangeTitle}
        onHandleChangeDescription={onHandleChangeDescription}
        updateAttentions={updateAttentions}
        onBack={onBack}
        isLoading={isLoading}
        onChangeIcon={onChangeIcon}
        iconColor={iconColor}
        showTour={showTour}
      />
    );
  };

  return isOpen ? (
    <TourProvider
      steps={steps}
      defaultOpen={true}
      onClickClose={() => {
        if (isCreation) setIsCreation(false);
        setIsOpen(false);
      }}
      disableInteraction={true}
      onClickMask={() => {
        if (isCreation) setIsCreation(false);
        setIsOpen(false);
      }}
      position="right"
      components={{ Badge }}
      badgeContent={({ totalSteps, currentStep }) =>
        `${currentStep + 1}/${totalSteps}`
      }
      styles={{
        controls: (base) => ({ ...base, justifyContent: "center" }),
      }}
      nextButton={({ currentStep, setCurrentStep, stepsLength }) => (
        <IconButton
          disabled={currentStep + 1 === stepsLength}
          onClick={() => {
            if (!mobileBreakpoint) {
              if (currentStep === 1) {
                setIsCreation(true);
                setTimeout(() => {
                  document.querySelector(".step-attention-0-click").click();
                  setCurrentStep(currentStep + 1);
                }, 100);
              } else {
                setCurrentStep(currentStep + 1);
              }
            } else {
              setCurrentStep(currentStep + 1);
            }
          }}
        >
          <ArrowForwardIcon />
        </IconButton>
      )}
      prevButton={({ currentStep, setCurrentStep }) => (
        <IconButton
          disabled={currentStep === 0}
          onClick={() => {
            if (currentStep <= 2) {
              document.getElementById("back-step-id") &&
                document.getElementById("back-step-id").click();
            }
            setCurrentStep(currentStep - 1);
          }}
        >
          <ArrowBackIcon />
        </IconButton>
      )}
    >
      <AttentionConfigComponent isOpen={isOpen} />
    </TourProvider>
  ) : !mobileBreakpoint ? (
    <MobileView
      t={t}
      lang={lang}
      setLang={setLang}
      banner={state.banner}
      principalColor={state.principalColor}
      buttonColor={state.buttonColor}
      hookTitle={hookTitle}
      setHookTitle={setHookTitle}
      hookSubtitle={hookSubtitle}
      setHookSubtitle={setHookSubtitle}
      buttonText={buttonText}
      setButtonText={setButtonText}
      attentions={attentions}
      isOpenButtonColorPicker={isOpenButtonColorPicker}
      setIsOpenButtonColorPicker={setIsOpenButtonColorPicker}
      buttonColorAttentions={buttonColor}
      setButtonColorAttentions={setButtonColor}
      onDeleteAttention={onDeleteAttention}
      onAddAttention={onAddAttention}
      onChangeColor={onChangeColor}
      selectedAttention={selectedAttention}
      setSelectedAttention={setSelectedAttention}
      onHandleChangeTitle={onHandleChangeTitle}
      onHandleChangeDescription={onHandleChangeDescription}
      updateAttentions={updateAttentions}
      onBack={onBack}
      isLoading={isLoading}
      onChangeIcon={onChangeIcon}
      iconColor={iconColor}
      showTour={showTour}
      isCreation={isCreation}
      setIsCreation={setIsCreation}
    />
  ) : (
    <DesktopView
      t={t}
      lang={lang}
      setLang={setLang}
      banner={state.banner}
      principalColor={state.principalColor}
      buttonColor={state.buttonColor}
      hookTitle={hookTitle}
      setHookTitle={setHookTitle}
      hookSubtitle={hookSubtitle}
      setHookSubtitle={setHookSubtitle}
      buttonText={buttonText}
      setButtonText={setButtonText}
      attentions={attentions}
      isOpenButtonColorPicker={isOpenButtonColorPicker}
      setIsOpenButtonColorPicker={setIsOpenButtonColorPicker}
      buttonColorAttentions={buttonColor}
      setButtonColorAttentions={setButtonColor}
      onDeleteAttention={onDeleteAttention}
      onAddAttention={onAddAttention}
      onChangeColor={onChangeColor}
      selectedAttention={selectedAttention}
      setSelectedAttention={setSelectedAttention}
      onHandleChangeTitle={onHandleChangeTitle}
      onHandleChangeDescription={onHandleChangeDescription}
      updateAttentions={updateAttentions}
      onBack={onBack}
      isLoading={isLoading}
      onChangeIcon={onChangeIcon}
      iconColor={iconColor}
      showTour={showTour}
    />
  );

  function showTour() {
    if (isCreation) {
      setIsCreation(false);
    }
    setIsOpen(true);
  }

  function Badge({ children }) {
    return (
      <components.Badge
        styles={{
          badge: (base) => ({
            ...base,
            backgroundColor: "#17191E",
            // width: 34,
          }),
        }}
      >
        {children}
      </components.Badge>
    );
  }

  function onDeleteAttention(id) {
    const data = [...attentions];
    const newAttentions = data.filter((item) => item.id !== id);
    setAttentions(newAttentions);
  }

  function onAddAttention() {
    setAttentions([
      ...attentions,
      {
        id: Math.floor(Math.random() * 10000),
        icon: "sentiment_satisfied",
        color: iconColor,
        title: "",
        description: "",
      },
    ]);
  }

  function onChangeColor(color) {
    seticonColor(color);
    const data = [...attentions];
    data.forEach((el) => (el.color = color));
    setAttentions(data);
  }

  function onChangeIcon(id, iconId) {
    const data = [...attentions];

    const currentAttention = data.findIndex((item) => item.id === id);
    data[currentAttention].icon = iconId;

    setAttentions(data);
  }

  function onHandleChangeTitle(id, event) {
    const data = [...attentions];
    const currentAttention = data.findIndex((item) => item.id === id);
    data[currentAttention].title = event.target.value;
    setAttentions(data);
    console.log(data);
  }

  function onHandleChangeDescription(id, event) {
    const data = [...attentions];
    const currentAttention = data.findIndex((item) => item.id === id);
    data[currentAttention].description = event.target.value;
    setAttentions(data);
  }

  async function updateAttentions(
    title,
    description,
    buttonTitle,
    buttonAttentionsColor,
    attentions
  ) {
    try {
      setIsLoading(true);
      const formatAttentions = attentions.map((attention) => ({
        company: state.user.company._id,
        name: attention.title,
        description: attention.description,
        icon: attention.icon,
        // color: attention.color,
      }));
      const preferences = await service.updatePreference({
        title,
        description,
        buttonTitle,
        buttonAttentionsColor,
        companyId: state.user.company._id,
      });

      const abilities = await service.createAbilities({
        abilities: formatAttentions,
        companyId: state.user.company._id,
        iconColor,
      });
      setConfigAttentions(
        title,
        description,
        buttonTitle,
        buttonAttentionsColor,
        attentions,
        iconColor
      );
      setIsLoading(false);
      navigate("/roles/config");
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      Swal.fire({
        customClass: {
          confirmButton: "class1",
        },
        icon: "error",
        text: t(err.dataResponse.msg),
      });
      console.log("[Err update preferences] => ", err);
    }
  }

  function onBack(
    title,
    description,
    buttonTitle,
    buttonAttentionsColor,
    attentions
  ) {
    setConfigAttentions(
      title,
      description,
      buttonTitle,
      buttonAttentionsColor,
      attentions,
      iconColor
    );
    return navigate("/contact/config");
  }
}

export default AttentionsConfig;
