import { useState } from "react";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import InfoIcon from "@mui/icons-material/Info";
import { OutlinedInput, Button, Menu } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";

import HeaderConfig from "./hedaerConfig";
import StepperVideolink from "./stepper";

import ListIcons, { iconsData } from "./listIcons";
import trash from "../assets/trash.svg";
import addIcon from "../assets/addIcon.svg";
import OutsideAlerter from "./outsideClick";

function AttentionsForm({
  t,
  isMobile,
  hookTitle,
  setHookTitle,
  hookSubtitle,
  setHookSubtitle,
  attentions,
  buttonText,
  setButtonText,
  setButtonColorAttentions,
  buttonColorAttentions,
  onAddAttention,
  onChangeColor,
  onHandleChangeTitle,
  onHandleChangeDescription,
  updateAttentions,
  onBack,
  setIsShowHook,
  isLoading,
  onChangeIcon,
  iconColor,
  onDeleteAttention,
  isCreation,
  setIsCreation,
  showTour,
  height,
  width,
}) {
  const activeStep = 1;
  const [selected, setSelected] = useState(undefined);
  const [mobileAttentionSelected, setMobileAttentionSelected] =
    useState(undefined);
  const [anchorEl, setAnchorEl] = useState(null);

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor:
        selected !== undefined ? "#FFFFFF !important" : "#17191E !important",
      width: "auto !important",
      color: "#FFFFFF !important",
      fontSize: 14,
      borderRadius: selected !== undefined ? 10 : 5,
      boxShadow:
        selected !== undefined ? "0px 4px 4px rgba(0, 0, 0, 0.25)" : "none",
      display: selected !== undefined && "flex",
      padding: selected !== undefined && 12,
      border: selected !== undefined && "1px solid #AFB8CD",
      height: selected !== undefined && 250,
      overflow: selected !== undefined && "auto",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color:
        selected !== undefined ? "#FFFFFF !important" : "#17191E !important",
    },
  }));

  return (
    <Box
      style={{
        maxWidth: !isMobile ? 750 : height >= 1180 ? 895 : 750,
        margin: "auto",
        background: isMobile && "#FFFFFF",
        paddingBottom: 8,
        paddingTop: isMobile && 16,
        borderTopLeftRadius: isMobile && 10,
        borderTopRightRadius: isMobile && 10,
        paddingLeft: height < 1180 && width > 750 && 60,
        paddingRight: height < 1180 && width > 750 && 60,
      }}
    >
      <HeaderConfig
        t={t}
        isMobile={isMobile}
        showTour={showTour}
        height={height}
      />
      <StepperVideolink activeStep={activeStep} />

      {!isMobile ? (
        <>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 12,
              paddingLeft: 24,
            }}
          >
            <Box
              style={{
                fontFamily: "Rubik",
                fontSize: 20,
                fontWeight: 600,
              }}
            >
              {t("attentions.type.center")}
            </Box>
            <Box style={{ paddingTop: 8, paddingLeft: 4 }}>
              <LightTooltip
                title={t("attentions.type.tooltip")}
                arrow
                placement="right"
              >
                <InfoIcon color="primary" style={{ width: 16 }} />
              </LightTooltip>
            </Box>
          </Box>

          <Box
            style={{
              backgroundColor: !isMobile && "#FBFBFF",
              padding: 24,

              border: !isMobile && "1px solid #AFB8CD",
            }}
          >
            <Box className="title-step">
              <Box style={{ display: !isMobile && "flex", width: "100%" }}>
                <Box
                  style={{
                    width: "40%",
                    marginRight: 8,
                    display: "flex",
                    color: "#17191E",
                    fontFamily: "Rubik",
                    fontWeight: 600,
                    fontSize: 14,
                  }}
                >
                  {t("attentions.welcome.title")}
                </Box>
                <Box
                  style={{
                    width: "60%",
                    display: "flex",
                    color: "#17191E",
                    fontFamily: "Rubik",
                    fontWeight: 600,
                    fontSize: 14,
                  }}
                >
                  {t("attentions.description")}
                </Box>
              </Box>
              <Box style={{ display: "flex", width: "100%" }}>
                <Box style={{ width: "40%", marginRight: 8 }}>
                  <OutlinedInput
                    type="text"
                    disabled={isLoading}
                    autoComplete="off"
                    required
                    inputProps={{ maxLength: 100 }}
                    multiline
                    rows={3}
                    value={hookTitle}
                    onChange={(event) => setHookTitle(event.target.value)}
                    size="small"
                    style={{
                      maxWidth: !isMobile && 550,
                      borderRadius: "4px",
                      background: "#E6E7F3",
                      borderWidth: 0,
                    }}
                    placeholder={t("attentions.hook.title")}
                    id="title-attentions"
                    fullWidth
                  />
                  <Box
                    style={{
                      display: "flex",
                      fontSize: 10,
                      fontFamily: "Rubik",
                    }}
                  >
                    {` ${100 - hookTitle.length} ${t("attentions.characters")}`}
                  </Box>
                </Box>
                <Box style={{ width: "60%" }}>
                  <OutlinedInput
                    type="text"
                    disabled={isLoading}
                    autoComplete="off"
                    required
                    inputProps={{ maxLength: 100 }}
                    multiline
                    rows={3}
                    value={hookSubtitle}
                    onChange={(event) => setHookSubtitle(event.target.value)}
                    size="small"
                    style={{
                      maxWidth: !isMobile && 550,
                      borderRadius: "4px",
                      background: "#E6E7F3",
                      borderWidth: 0,
                    }}
                    placeholder={t("attentions.hook.subtitle")}
                    id="description-attentions"
                    fullWidth
                  />
                  <Box
                    style={{
                      display: "flex",
                      fontSize: 10,
                      fontFamily: "Rubik",
                    }}
                  >
                    {`${100 - hookSubtitle.length} ${t(
                      "attentions.characters"
                    )}`}
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box style={{ marginTop: 12 }} className="color-step">
              <Box style={{ display: "flex", width: "100%" }}>
                <Box style={{ width: "40%", marginRight: 8 }}>
                  <Box
                    style={{
                      display: "flex",
                      fontFamily: "Rubik",
                      fontWeight: 600,
                      fontSize: 14,
                    }}
                  >
                    {t("attentions.button.title")}
                  </Box>
                  <OutlinedInput
                    type="text"
                    disabled={isLoading}
                    inputProps={{ maxLength: 15 }}
                    autoComplete="off"
                    required
                    value={buttonText}
                    onChange={(event) => setButtonText(event.target.value)}
                    size="small"
                    style={{
                      maxWidth: !isMobile && 550,
                      borderRadius: "4px",
                      background: "#E6E7F3",
                      borderWidth: 0,
                    }}
                    placeholder={"Seleccionar"}
                    id="button-attentionss"
                    fullWidth
                  />
                </Box>
                <Box style={{ width: "60%" }}>
                  <Box style={{ width: "100%", display: "flex" }}>
                    <Box
                      style={{
                        display: "flex",
                        fontFamily: "Rubik",
                        fontWeight: 600,
                        fontSize: 14,
                        width: "50%",
                        paddingLeft: 14,
                        // color: "rgb(251, 251, 255)",
                      }}
                    >
                      {t("attentions.button.color")}
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        fontFamily: "Rubik",
                        fontWeight: 600,
                        fontSize: 14,
                        width: "50%",
                        paddingLeft: 14,

                        // color: "rgb(251, 251, 255)",
                      }}
                    >
                      {t("attentions.icon.color")}
                    </Box>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: -3,
                    }}
                  >
                    <Box
                      style={{
                        margin: "auto",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                        borderRadius: !isMobile ? 32 : height >= 1180 ? 60 : 32,
                        padding: 8,
                        marginTop: 8,
                        width: "50%",
                        paddingLeft: 12,
                        paddingRight: 12,
                        height: !isMobile ? 32 : height >= 1180 ? 60 : 32,
                      }}
                      className="color-picket-background"
                      onClick={() =>
                        document.getElementById("colorBackground").click()
                      }
                    >
                      <input
                        type="color"
                        id="colorBackground"
                        className="colorPicker-vl"
                        value={
                          buttonColorAttentions
                            ? buttonColorAttentions
                            : "#AFB8CD"
                        }
                        onChange={(event) =>
                          setButtonColorAttentions(event.target.value)
                        }
                      />

                      <Box style={{ width: "70%", marginLeft: 12 }}>
                        {buttonColorAttentions
                          ? buttonColorAttentions
                          : "#AFB8CD"}
                      </Box>
                      <Box
                        style={{ width: "15%" }}
                        onClick={() =>
                          document.getElementById("colorBackground").click()
                        }
                      >
                        <KeyboardArrowDownIcon />
                      </Box>
                    </Box>
                    <Box
                      style={{
                        margin: "auto",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                        borderRadius: 20,
                        padding: 8,
                        marginTop: 8,
                        width: "50%",
                        paddingLeft: 12,
                        paddingRight: 12,
                        height: 35,
                        marginLeft: 8,
                      }}
                      className="color-picket-background"
                      onClick={() =>
                        document.getElementById("colorBackground2").click()
                      }
                    >
                      <input
                        type="color"
                        id="colorBackground2"
                        className="colorPicker-vl"
                        value={iconColor}
                        onChange={(event) => onChangeColor(event.target.value)}
                      />

                      <Box style={{ width: "70%", marginLeft: 12 }}>
                        {iconColor ? iconColor : "#AFB8CD"}
                      </Box>
                      <Box
                        style={{ width: "15%" }}
                        onClick={() =>
                          document.getElementById("colorBackground2").click()
                        }
                      >
                        <KeyboardArrowDownIcon />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          {attentions.length === 0 ? (
            <Box
              style={{
                marginTop: 32,
                marginBottom: 32,
              }}
            >
              <img
                className="select-icon"
                src={addIcon}
                alt=""
                onClick={() => onAddAttention()}
                id="add-attention-img"
              />
              <Box
                style={{
                  color: "#17191E",
                  fontSize: 14,
                  fontWeight: "bold",
                }}
              >
                {t("attentions.add.more")}
              </Box>
            </Box>
          ) : (
            attentions.map((attention, index) => (
              <Box
                style={{
                  marginTop: 14,
                  background: "#FBFBFF",

                  border: "1px solid #AFB8CD",
                  paddingBottom: 24,
                  paddingTop: 12,
                }}
                key={attention.id}
                className={"step-attention-" + index}
              >
                <Box
                  style={{
                    display: "flex",
                    paddingBottom: 12,
                    borderBottom: "solid 1px #AFB8CD",
                    paddingLeft: 24,
                    paddingRight: 24,
                    alignItems: "center",
                  }}
                >
                  <Box
                    style={{
                      fontFamily: "Rubik",
                      color: "#17191E",
                      fontSize: 14,
                    }}
                  >
                    {`${t("attentions.string")} ${index + 1}`}
                  </Box>
                  <Box
                    style={{ marginLeft: "auto" }}
                    onClick={() => onDeleteAttention(attention.id)}
                    className="delete-attention-vl"
                  >
                    <img
                      src={trash}
                      alt=""
                      id={`delete-attention-${index + 1}`}
                    />
                  </Box>
                </Box>

                <Box
                  style={{
                    display: "flex",
                    paddingLeft: 24,
                    paddingRight: 24,
                    paddingTop: 19,
                  }}
                >
                  <Box style={{ display: "flex", width: "100%" }}>
                    <Box
                      style={{ width: "15%", marginRight: 12 }}
                      className={"icon-attention-" + index}
                    >
                      <Box
                        style={{
                          display: "flex",
                          fontWeight: 600,
                          fontFamily: "Rubik",
                          fontSize: 14,
                        }}
                      >
                        {t("attentions.icon.string")}
                        {/* Icono */}
                      </Box>
                      <LightTooltip
                        title={
                          <OutsideAlerter
                            onClick={() => setSelected(undefined)}
                          >
                            <ListIcons
                              onChangeIcon={onChangeIcon}
                              setSelected={setSelected}
                              attentionId={attention.id}
                              iconColor={iconColor}
                            />
                          </OutsideAlerter>
                        }
                        arrow={selected !== undefined ? false : true}
                        placement={
                          selected !== undefined ? "bottom-end" : "bottom"
                        }
                        open={selected === attention.id && true}
                        // disableFocusListener
                        disableHoverListener
                        // disableTouchListener
                      >
                        <Box
                          style={{ width: "100%", marginBottom: 24 }}
                          onClick={() => setSelected(attention.id)}
                        >
                          <Box
                            style={{
                              margin: "auto",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              background: "#E6E7F3",
                              borderRadius: 4,
                              height: 40,
                            }}
                            className="select-icon"
                            id={"icon-attention-" + index}
                          >
                            <Box
                              style={{
                                width: "85%",
                                marginLeft: 12,
                                display: "flex",
                              }}
                            >
                              <span
                                className="material-icons"
                                style={{
                                  fontSize: 24,
                                  color: iconColor,
                                  width: 24,
                                }}
                                // onClick={() => {
                                //   onChangeIcon(attentionId, icon.icon);
                                //   setSelected(undefined);
                                // }}
                              >
                                {
                                  iconsData.find(
                                    (icon) => icon.icon === attention.icon
                                  )?.icon
                                }
                              </span>
                            </Box>
                            <Box
                              style={{
                                width: "15%",
                                display: "flex",
                                justifyContent: "end",
                                paddingRight: 10,
                              }}
                            >
                              <KeyboardArrowDownIcon />
                            </Box>
                          </Box>
                        </Box>
                      </LightTooltip>
                    </Box>
                    <Box
                      style={{ display: "flex", width: "100%" }}
                      className={"title-step-" + index}
                    >
                      <Box style={{ width: "45%", marginRight: 12 }}>
                        <Box
                          style={{
                            display: "flex",
                            fontWeight: 600,
                            fontFamily: "Rubik",
                            fontSize: 14,
                          }}
                        >
                          {t("attentions.item.title")}
                        </Box>
                        <OutlinedInput
                          type="text"
                          multiline
                          rows={2}
                          inputProps={{ maxLength: 30 }}
                          autoComplete="off"
                          required
                          value={attention.title}
                          onChange={(event) =>
                            onHandleChangeTitle(attention.id, event)
                          }
                          size="small"
                          style={{
                            maxWidth: !isMobile && 550,
                            borderRadius: "4px",
                            background: "#E6E7F3",
                            borderWidth: 0,
                            heigth: 27,
                          }}
                          placeholder={t("attentions.item.title")}
                          fullWidth
                          id={"attention-name" + index}
                        />
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            fontSize: 10,
                            fontFamily: "Rubik",
                          }}
                        >
                          {`${30 - attention.title.length} ${t(
                            "attentions.characters"
                          )}`}
                        </Box>
                      </Box>
                      <Box style={{ width: "55%" }}>
                        <Box
                          style={{
                            display: "flex",
                            fontFamily: "Rubik",
                            fontWeight: 600,
                            fontSize: 14,
                          }}
                        >
                          {t("attention.item.description")}
                        </Box>
                        <OutlinedInput
                          type="text"
                          disabled={isLoading}
                          inputProps={{ maxLength: 100 }}
                          autoComplete="off"
                          required
                          multiline
                          rows={2}
                          value={attention.description}
                          onChange={(event) =>
                            onHandleChangeDescription(attention.id, event)
                          }
                          size="small"
                          style={{
                            maxWidth: !isMobile && 550,
                            borderRadius: "4px",
                            background: "#E6E7F3",
                            borderWidth: 0,
                          }}
                          placeholder={t("attention.item.description")}
                          id={"attention-description" + index}
                          fullWidth
                        />
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            fontSize: 10,
                            fontFamily: "Rubik",
                          }}
                        >
                          {`${100 - attention.description.length} ${t(
                            "attentions.characters"
                          )}`}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            ))
          )}
          <Box
            style={{
              width: "100%",
              marginTop: 12,
            }}
          >
            <Box
              style={{
                display: attentions.length === 0 ? "none" : "flex",
                marginTop: 12,
              }}
            >
              <Button
                style={{
                  textTransform: "inherit",
                  borderRadius: 20,
                  fontSize: 14,
                  fontWeight: 700,
                  boxShadow: "none",
                  color: "#FFFFFF",
                  backgroundColor: isDisabled() ? "#AFB8CD" : "#EB3E67",
                  fontFamily: "Rubik",
                }}
                variant="contained"
                disabled={isDisabled()}
                size="small"
                onClick={() => onAddAttention()}
                className="add-step"
                id="add-more-attentions"
              >
                {t("attentions.add.more")}

                {/* Agregar más atenciones */}
              </Button>
            </Box>

            <Box style={{ display: "flex", justifyContent: "end" }}>
              <Button
                id="back-button"
                onClick={() =>
                  onBack(
                    hookTitle,
                    hookSubtitle,
                    buttonText,
                    buttonColorAttentions,
                    attentions,
                    iconColor
                  )
                }
                style={{
                  textTransform: "inherit",
                  borderRadius: height >= 1180 ? 40 : 32,
                  fontSize: 14,
                  fontWeight: 700,
                  boxShadow: "none",
                  width: "33%",
                  marginRight: 12,
                  backgroundColor: "#FFFFFF",
                  color: "#52596B",
                  border: "1px solid #52596B",
                  backgroundColor: "#FFFFFF",
                  height: height >= 1180 ? 60 : 32,
                  fontFamily: "Rubik",
                }}
                variant="contained"
                disabled={isLoading}
              >
                {t("registry.back")}
                {/* Volver */}
              </Button>
              <LoadingButton
                id="button-next"
                loadingPosition="start"
                loading={isLoading}
                onClick={() =>
                  updateAttentions(
                    hookTitle,
                    hookSubtitle,
                    buttonText,
                    buttonColorAttentions,
                    attentions
                  )
                }
                style={{
                  textTransform: "inherit",
                  borderRadius: !isMobile ? 20 : height >= 1180 ? 40 : 20,
                  fontSize: 14,
                  fontWeight: 700,
                  boxShadow: "none",
                  width: "33%",
                  fontFamily: "Rubik",
                  height: !isMobile ? 32 : height >= 1180 ? 60 : 32,
                  color: "#FFFFFF",
                  backgroundColor:
                    isDisabledNextButton() || isLoading ? "#AFB8CD" : "#EB3E67",
                }}
                variant="contained"
                disabled={isDisabledNextButton() || isLoading}
                size="small"
                id="button-next"
              >
                {t("button.next.step")}
                {/* Siguiente */}
              </LoadingButton>
            </Box>
          </Box>
        </>
      ) : isCreation ? (
        <>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              background: "#FFFFFF",
              marginTop: 8,
              padding: isMobile && 8,
              paddingLeft: isMobile && 24,
              maxWidth: !isMobile ? 550 : 702,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Box
              style={{
                fontFamily: "Rubik",
                fontSize: !isMobile ? 16 : height >= 1180 ? 27 : 16,
                fontWeight: 600,
              }}
            >
              {t("attention.creation.title")}
              {/* Creación Atenciones */}
            </Box>
            <Box style={{ paddingTop: 6, paddingLeft: 10 }}>
              <LightTooltip
                title={t("contact.center.info")}
                arrow
                placement="right"
              >
                <InfoIcon color="primary" />
              </LightTooltip>
            </Box>
          </Box>
          {attentions.length === 0 ? (
            <Box
              className="select-icon"
              style={{
                marginTop: 32,
                marginBottom: 32,
                textAlign: "center",
              }}
            >
              <img
                src={addIcon}
                alt=""
                onClick={() => onAddAttention()}
                id="add-attention-img"
              />
              <Box
                style={{
                  color: "#17191E",
                  fontSize: !isMobile ? 14 : height >= 1180 ? 27 : 14,
                  fontWeight: "bold",
                }}
              >
                {t("attentions.add.more")}

                {/* Agregar más atenciones */}
              </Box>
            </Box>
          ) : (
            attentions.map((attention, index) => (
              <Box
                style={{
                  boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                  borderRadius: 10,
                  maxWidth: 702,
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: 8,
                }}
                key={index}
                defaultExpanded={mobileAttentionSelected === attention.id}
                className={`step-attention-0`}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding:
                      mobileAttentionSelected === attention.id
                        ? "12px 12px 0px 12px"
                        : "12px 12px 12px 12px",
                  }}
                  aria-controls="panel1d-content"
                  id={`panel1d-header${index}`}
                  className="step-attention-0-click"
                  onClick={() =>
                    mobileAttentionSelected === attention.id
                      ? setMobileAttentionSelected(undefined)
                      : setMobileAttentionSelected(attention.id)
                  }
                >
                  <Box
                    style={{
                      fontSize: !isMobile ? 14 : height >= 1180 ? 27 : 14,
                      fontFamily: "Rubik",
                    }}
                  >{`${t("attentions.string")} ${index + 1}`}</Box>
                  <Box
                    onClick={() => onDeleteAttention(attention.id)}
                    className="delete-attention-vl"
                    style={{ paddingLeft: 19.83 }}
                  >
                    <img
                      src={trash}
                      alt=""
                      style={{ width: height >= 1180 && 30 }}
                    />
                  </Box>
                  <Box style={{ marginLeft: "auto" }}>
                    {mobileAttentionSelected === attention.id ? (
                      <KeyboardArrowDownIcon />
                    ) : (
                      <ArrowForwardIosSharpIcon style={{ fontSize: 13 }} />
                    )}
                  </Box>
                </Box>
                {mobileAttentionSelected === attention.id && (
                  <Box>
                    <hr />
                    <Box style={{ padding: 16 }}>
                      <Box className="title-step-0">
                        <Box
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            style={{
                              marginRight: 8,
                              display: "flex",
                              color: "#17191E",
                              fontFamily: "Rubik",
                              fontWeight: 600,
                              fontSize: !isMobile
                                ? 14
                                : height >= 1180
                                ? 27
                                : 14,
                            }}
                          >
                            {t("attentions.item.title")}
                            {/* Título de la atención */}
                          </Box>
                        </Box>
                        <Box style={{ width: "100%" }}>
                          <OutlinedInput
                            type="text"
                            inputProps={{ maxLength: 30 }}
                            autoComplete="off"
                            required
                            value={attention.title}
                            onChange={(event) =>
                              onHandleChangeTitle(attention.id, event)
                            }
                            size="small"
                            style={{
                              maxWidth: !isMobile && 550,
                              borderRadius: "4px",
                              background: "#E6E7F3",
                              borderWidth: 0,
                              fontSize: !isMobile
                                ? 14
                                : height >= 1180
                                ? 27
                                : 14,
                              height: height >= 1180 ? 60 : 40,
                            }}
                            placeholder={t("attentions.item.title")}
                            id="outlined-basic"
                            fullWidth
                          />
                          <Box
                            style={{
                              display: "flex",
                              fontSize: 10,
                              fontFamily: "Rubik",
                              marginTop: 8,
                              justifyContent: "end",
                            }}
                          >
                            {`${30 - attention.title.length} ${t(
                              "attentions.characters"
                            )}`}
                          </Box>
                        </Box>
                      </Box>
                      <Box className="icon-attention-0">
                        <Box
                          style={{
                            display: "flex",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            style={{
                              marginRight: 8,
                              display: "flex",
                              color: "#17191E",
                              fontFamily: "Rubik",
                              fontWeight: 600,
                              fontSize: !isMobile
                                ? 14
                                : height >= 1180
                                ? 27
                                : 14,
                            }}
                          >
                            {t("attentions.icon.string")}
                            {/* Icono */}
                          </Box>
                        </Box>{" "}
                        <LightTooltip
                          onClose={() => setSelected(undefined)}
                          title={
                            <ListIcons
                              onChangeIcon={onChangeIcon}
                              setSelected={setSelected}
                              attentionId={attention.id}
                              iconColor={iconColor}
                            />
                          }
                          arrow={selected !== undefined ? false : true}
                          placement={
                            height > 1023
                              ? "bottom-start"
                              : selected !== undefined
                              ? "bottom-end"
                              : "bottom"
                          }
                          open={selected === attention.id && true}
                          disableFocusListener
                          disableHoverListener
                          // disableTouchListener
                        >
                          <Box
                            style={{ width: "100%", marginBottom: 24 }}
                            onClick={() => setSelected(attention.id)}
                          >
                            <Box
                              style={{
                                margin: "auto",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                background: "#E6E7F3",
                                borderRadius: 4,
                                height: height >= 1180 ? 60 : 40,
                              }}
                            >
                              <Box
                                style={{
                                  width: "85%",
                                  marginLeft: 12,
                                  display: "flex",
                                }}
                              >
                                <span
                                  className="material-icons"
                                  style={{
                                    fontSize: !isMobile
                                      ? 24
                                      : height >= 1180
                                      ? 36
                                      : 24,
                                    color: iconColor,
                                    width: 24,
                                  }}
                                >
                                  {
                                    iconsData.find(
                                      (icon) => icon.icon === attention.icon
                                    )?.icon
                                  }
                                </span>
                              </Box>
                              <Box
                                style={{
                                  width: "15%",
                                  display: "flex",
                                  justifyContent: "end",
                                  paddingRight: 10,
                                }}
                              >
                                <KeyboardArrowDownIcon />
                              </Box>
                            </Box>
                          </Box>
                        </LightTooltip>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          style={{
                            marginRight: 8,
                            display: "flex",
                            color: "#17191E",
                            fontFamily: "Rubik",
                            fontWeight: 600,
                            fontSize: !isMobile ? 14 : height >= 1180 ? 27 : 14,
                          }}
                        >
                          {t("attention.item.description")}
                          {/* Descripción de la atención */}
                        </Box>
                      </Box>
                      <Box style={{ width: "100%" }}>
                        <OutlinedInput
                          ype="text"
                          inputProps={{ maxLength: 100 }}
                          autoComplete="off"
                          required
                          multiline
                          rows={2}
                          value={attention.description}
                          onChange={(event) =>
                            onHandleChangeDescription(attention.id, event)
                          }
                          size="small"
                          style={{
                            maxWidth: !isMobile && 550,
                            borderRadius: "4px",
                            background: "#E6E7F3",
                            borderWidth: 0,
                            fontSize: !isMobile ? 14 : height >= 1180 ? 27 : 14,
                          }}
                          placeholder={t("attention.item.description")}
                          id="outlined-basic"
                          fullWidth
                        />
                        <Box
                          style={{
                            display: "flex",
                            fontSize: 10,
                            fontFamily: "Rubik",
                            marginTop: 8,
                            justifyContent: "end",
                          }}
                        >
                          {`${100 - attention.description.length} ${t(
                            "attentions.characters"
                          )}`}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            ))
          )}
          <Box
            style={{
              display: "flex",
              marginTop: 12,
              maxWidth: 702,
              marginLeft: "auto",
              marginRight: "auto",
              display: attentions.length === 0 && "none",
            }}
          >
            <Button
              style={{
                textTransform: "inherit",
                borderRadius: height >= 1180 ? 40 : 32,
                fontSize: !isMobile ? 14 : height >= 1180 ? 27 : 14,
                fontWeight: 700,
                boxShadow: "none",
                color: "#FFFFFF",
                backgroundColor: isDisabled() ? "#AFB8CD" : "#EB3E67",
                fontFamily: "Rubik",
                height: height >= 1180 ? 60 : 32,
              }}
              variant="contained"
              disabled={isDisabled()}
              size="small"
              onClick={() => onAddAttention()}
              className="add-step"
            >
              {t("attentions.add.more")}
            </Button>
          </Box>
          <Box style={{ textAlign: "center", marginBottom: 22, marginTop: 22 }}>
            <Button
              onClick={() => setIsShowHook(true)}
              style={{
                textTransform: "inherit",
                borderRadius: height >= 1180 ? 40 : 32,
                width: "50%",
                fontSize: !isMobile ? 14 : height >= 1180 ? 27 : 14,
                fontWeight: 700,
                boxShadow: "none",
                backgroundColor: "#FFFFFF",
                color: "#52596B",
                border: "1px solid #52596B",
                height: height >= 1180 ? 60 : 32,
                marginBottom: 8,
                fontFamily: "Rubik",
              }}
              variant="contained"
            >
              {t("attentions.preview.string")}
              {/* Visualizar */}
            </Button>
          </Box>

          <Box
            style={{
              marginTop: 16,
              maxWidth: 702,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <LoadingButton
              loadingPosition="start"
              loading={isLoading}
              onClick={() =>
                updateAttentions(
                  hookTitle,
                  hookSubtitle,
                  buttonText,
                  buttonColorAttentions,
                  attentions
                )
              }
              fullWidth
              style={{
                textTransform: "inherit",
                borderRadius: !isMobile ? 32 : height >= 1180 ? 60 : 32,
                fontSize: !isMobile ? 14 : height >= 1180 ? 27 : 14,
                fontWeight: 700,
                boxShadow: "none",
                height: !isMobile ? 32 : height >= 1180 ? 60 : 32,
                color: "#FFFFFF",
                backgroundColor:
                  isDisabledNextButton() || isLoading ? "#AFB8CD" : "#EB3E67",
                marginBottom: 8,
              }}
              variant="contained"
              disabled={isDisabledNextButton() || isLoading}
              size="small"
              id="button-next"
            >
              {t("button.next.step")}
              {/* Siguiente */}
            </LoadingButton>
            <Button
              loadingPosition="start"
              loading={isLoading}
              onClick={() => setIsCreation(false)}
              fullWidth
              style={{
                textTransform: "inherit",
                borderRadius: height >= 1180 ? 40 : 32,
                fontSize: !isMobile ? 14 : height >= 1180 ? 27 : 14,
                fontWeight: 700,
                boxShadow: "none",
                backgroundColor: "#FFFFFF",
                color: "#52596B",
                border: "1px solid #52596B",
                height: height >= 1180 ? 60 : 32,
                marginBottom: 8,
                ntFamily: "Rubik",
              }}
              variant="contained"
              id="back-step-id"
            >
              {t("registry.back")}
              {/* Volver */}
            </Button>
          </Box>
        </>
      ) : (
        <>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: !isMobile ? 16 : 8,
              padding: isMobile && 8,
              maxWidth: !isMobile ? 550 : 702,
              margin: "auto",
            }}
          >
            <Box
              style={{
                fontFamily: "Rubik",
                fontSize: !isMobile ? 20 : height >= 1180 ? 38 : 16,
                fontWeight: 600,
              }}
            >
              {t("attentions.type.center")}
              {/* Diseño de Atenciones */}
            </Box>
            <Box style={{ paddingTop: 6, paddingLeft: 10 }}>
              <LightTooltip
                title={t("contact.center.info")}
                arrow
                placement="right"
              >
                <InfoIcon color="primary" />
              </LightTooltip>
            </Box>
          </Box>
          <Box
            style={{
              backgroundColor: "#FBFBFF",
              borderRadius: 10,
              padding: 24,
              border: "solid 1px #AFB8CD",
            }}
          >
            <Box className="title-step">
              <Box
                style={{
                  display: "flex",
                  maxWidth: 702,
                  margin: "auto",
                  // marginBottom: 16,
                  alignItems: "center",
                }}
              >
                <Box
                  style={{
                    marginRight: 8,
                    display: "flex",
                    color: "#17191E",
                    fontFamily: "Rubik",
                    fontWeight: 600,
                    fontSize: height >= 1180 ? 27 : 14,
                  }}
                >
                  {t("attentions.welcome.title")}
                </Box>
              </Box>
              <Box
                style={{
                  width: "100%",
                }}
              >
                <OutlinedInput
                  type="text"
                  autoComplete="off"
                  required
                  inputProps={{ maxLength: 100 }}
                  multiline
                  rows={2}
                  value={hookTitle}
                  onChange={(event) => setHookTitle(event.target.value)}
                  size="small"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    maxWidth: !isMobile ? 550 : 702,
                    borderRadius: "4px",
                    background: "#E6E7F3",
                    borderWidth: 0,
                    fontSize: height >= 1180 ? 27 : 14,
                    margin: isMobile && "auto",
                  }}
                  placeholder={t("attentions.hook.title")}
                  id="outlined-basic"
                  fullWidth
                />
                <Box
                  style={{
                    maxWidth: 702,
                    display: "flex",
                    fontSize: 10,
                    fontFamily: "Rubik",
                    marginTop: 8,
                    margin: "auto",
                    marginBottom: 16,
                  }}
                >
                  {` ${100 - hookTitle.length} ${t("attentions.characters")}`}
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  maxWidth: 702,
                  margin: "auto",
                  alignItems: "center",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    color: "#17191E",
                    fontFamily: "Rubik",
                    fontWeight: 600,
                    fontSize: height >= 1180 ? 27 : 14,
                  }}
                >
                  {t("attentions.description")}
                  {/* Mensaje secundario */}
                </Box>
              </Box>
              <Box
                style={{
                  width: "100%",
                  // margin: "auto",
                  // display: "flex",
                  // flexDirection: "column",
                }}
              >
                <OutlinedInput
                  autoComplete="off"
                  required
                  inputProps={{ maxLength: 100 }}
                  multiline
                  rows={3}
                  value={hookSubtitle}
                  onChange={(event) => setHookSubtitle(event.target.value)}
                  size="small"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "auto",
                    maxWidth: !isMobile ? 550 : 702,
                    borderRadius: "4px",
                    background: "#E6E7F3",
                    borderWidth: 0,
                    fontSize: height >= 1180 ? 27 : 14,
                  }}
                  placeholder={t("attentions.hook.subtitle")}
                  id="outlined-basic"
                  fullWidth
                />
                <Box
                  style={{
                    maxWidth: 702,
                    display: "flex",
                    fontSize: 10,
                    fontFamily: "Rubik",
                    marginTop: 8,
                    margin: "auto",
                    marginBottom: 16,
                  }}
                >
                  {` ${100 - hookSubtitle.length} ${t(
                    "attentions.characters"
                  )}`}
                </Box>
              </Box>
            </Box>
            <Box className="color-step">
              <Box
                style={{
                  display: "flex",
                  maxWidth: 702,
                  margin: "auto",
                  alignItems: "center",
                  marginTop: "16px !important",
                }}
              >
                <Box
                  style={{
                    marginRight: 8,
                    display: "flex",
                    color: "#17191E",
                    fontFamily: "Rubik",
                    fontWeight: 600,
                    fontSize: height >= 1180 ? 27 : 14,
                  }}
                >
                  {t("attentions.button.attentions.title")}
                  {/* Botones en las atenciones */}
                </Box>
                <Box style={{ paddingTop: 6 }}>
                  <LightTooltip
                    title={t("contact.center.info")}
                    arrow
                    placement="right"
                  >
                    <InfoIcon color="primary" style={{ width: 18 }} />
                  </LightTooltip>
                </Box>
              </Box>
              <Box style={{ textAlign: "center" }}>
                <Box
                  style={{
                    fontFamily: "Rubik",
                    fontSize: height >= 1180 ? 27 : 14,
                    fontWeight: 600,
                    marginBottom: 8,
                  }}
                >
                  {t("attentions.text.string")}
                  {/* Texto */}
                </Box>
                <Box style={{ marginBottom: 8 }}>
                  <OutlinedInput
                    type="text"
                    autoComplete="off"
                    required
                    inputProps={{
                      maxLength: 15,
                      style: { textAlign: "center" },
                    }}
                    value={buttonText}
                    onChange={(event) => setButtonText(event.target.value)}
                    size="small"
                    style={{
                      maxWidth: !isMobile && 550,
                      borderRadius: !isMobile ? 20 : height >= 1180 ? 60 : 20,
                      background: buttonColorAttentions
                        ? buttonColorAttentions
                        : "#D9D9D9",
                      borderWidth: 0,
                      width: "75%",
                      textAlign: "center",
                      height: !isMobile ? 33 : height >= 1180 ? 60 : 33,
                      fontSize: height >= 1180 ? 27 : 14,
                      color: "#FFFFFF",
                    }}
                    placeholder={t("attentions.select.string")}
                    id="outlined-basic"
                    fullWidth
                  />
                </Box>
                <Box
                  style={{
                    fontFamily: "Rubik",
                    fontSize: height >= 1180 ? 27 : 14,
                    fontWeight: 600,
                    marginBottom: 8,
                  }}
                >
                  Color del botón
                </Box>
                <Box
                  style={{
                    margin: "auto",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: !isMobile ? 40 : height >= 1180 ? 60 : 40,
                    padding: 8,
                    marginTop: 8,
                    width: "75%",
                    paddingLeft: 12,
                    paddingRight: 12,
                    height: !isMobile ? 35 : height >= 1180 ? 60 : 35,
                  }}
                  onClick={() =>
                    document.getElementById("colorBackground").click()
                  }
                >
                  <input
                    type="color"
                    id="colorBackground"
                    className="colorPicker-vl"
                    value={
                      buttonColorAttentions ? buttonColorAttentions : "#AFB8CD"
                    }
                    onChange={(event) =>
                      setButtonColorAttentions(event.target.value)
                    }
                  />
                  <Box
                    style={{
                      width: "80%",
                      marginLeft: 12,
                      fontSize: height >= 1180 ? 27 : 14,
                    }}
                  >
                    {buttonColorAttentions ? buttonColorAttentions : "#AFB8CD"}
                  </Box>
                  <Box style={{ width: "15%" }}>
                    <KeyboardArrowDownIcon />
                  </Box>
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  maxWidth: 702,
                  margin: "auto",
                  alignItems: "center",
                  paddingTop: 47,
                }}
              >
                <Box
                  style={{
                    paddingRight: 8,
                    display: "flex",
                    color: "#17191E",
                    fontFamily: "Rubik",
                    fontWeight: 600,
                    fontSize: height >= 1180 ? 27 : 14,
                  }}
                >
                  {t("attentions.icon.color.for")}
                  {/* Iconos para las atenciones */}
                </Box>
                <Box style={{ paddingTop: 6 }}>
                  <LightTooltip
                    title={t("contact.center.info")}
                    arrow
                    placement="right"
                  >
                    <InfoIcon
                      color="primary"
                      style={{ width: height >= 1180 ? 36 : 18 }}
                    />
                  </LightTooltip>
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  maxWidth: 702,
                  margin: "auto",
                  paddingTop: 32,
                }}
              >
                <Box
                  style={{
                    background: "#FFFFFF",
                    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                    borderRadius: 14,
                    textAlign: "center",
                    padding: 8,
                    display: "flex",
                    alignItems: "center",
                    width: "20%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <span
                    className="material-icons"
                    style={{
                      fontSize: height >= 1180 ? 68 : 48,
                      color: iconColor,
                      // width: 24,
                    }}
                  >
                    {iconsData.find((icon, index) => index === 14).icon}
                  </span>
                </Box>
                <Box style={{ marginLeft: 17, width: "70%" }}>
                  <Box
                    style={{
                      fontFamily: "Rubik",
                      fontWeight: 600,
                      fontSize: height >= 1180 ? 27 : 14,
                    }}
                  >
                    {t("attentions.icon.color")}
                  </Box>
                  <Box
                    style={{
                      margin: "auto",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
                      borderRadius: !isMobile ? 20 : height >= 1180 ? 40 : 20,
                      padding: 8,
                      marginTop: 8,
                      width: "100%",
                      paddingLeft: 12,
                      paddingRight: 12,
                      height: !isMobile ? 35 : height >= 1180 ? 60 : 35,
                    }}
                    onClick={() =>
                      document
                        .getElementById("colorBackground-allIcons")
                        .click()
                    }
                  >
                    <input
                      type="color"
                      id="colorBackground-allIcons"
                      className="colorPicker-vl"
                      value={iconColor}
                      onChange={(event) => onChangeColor(event.target.value)}
                    />
                    <Box
                      style={{
                        width: "80%",
                        marginLeft: 12,
                        fontSize: height >= 1180 ? 27 : 14,
                        textAlign: "center",
                      }}
                    >
                      {iconColor}
                    </Box>
                    <Box style={{ width: "15%" }}>
                      <KeyboardArrowDownIcon />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box
            style={{
              textAlign: "center",
              marginBottom: 22,
              marginTop: 22,
            }}
          >
            <Button
              onClick={() => setIsShowHook(true)}
              style={{
                textTransform: "inherit",
                borderRadius: height >= 1180 ? 40 : 20,
                width: "50%",
                fontSize: !isMobile ? 14 : height >= 1180 ? 27 : 14,
                fontWeight: 700,
                boxShadow: "none",
                backgroundColor: "#FFFFFF",
                color: "#52596B",
                border: "1px solid #52596B",
                height: height >= 1180 ? 60 : 32,
                marginBottom: 8,
                fontFamily: "Rubik",
              }}
              variant="contained"
            >
              {t("attentions.preview.string")}
              {/* Visualizar */}
            </Button>
          </Box>
          <Box style={{ textAlign: "center" }}>
            <LoadingButton
              onClick={() => setIsCreation(true)}
              style={{
                width: height >= 1180 ? "90%" : "100%",
                textTransform: "inherit",
                borderRadius: !isMobile ? 20 : height >= 1180 ? 40 : 20,
                fontSize: !isMobile ? 14 : height >= 1180 ? 27 : 14,
                fontWeight: 700,
                boxShadow: "none",
                height: !isMobile ? 32 : height >= 1180 ? 60 : 32,
                color: "#FFFFFF",
                backgroundColor:
                  !hookTitle ||
                  !hookSubtitle ||
                  !buttonText ||
                  !buttonColorAttentions ||
                  isLoading
                    ? "#AFB8CD"
                    : "#EB3E67",
                marginBottom: 8,
              }}
              variant="contained"
              disabled={
                !hookTitle ||
                !hookSubtitle ||
                !buttonText ||
                !buttonColorAttentions ||
                isLoading
              }
              size="small"
              id="next-label"
            >
              {t("button.next.step")}
              {/* Siguiente */}
            </LoadingButton>
            <Button
              onClick={() =>
                onBack(
                  hookTitle,
                  hookSubtitle,
                  buttonText,
                  buttonColorAttentions,
                  attentions,
                  iconColor
                )
              }
              fullWidth
              style={{
                textTransform: "inherit",
                borderRadius: !isMobile ? 32 : height >= 1180 ? 60 : 32,
                fontSize: !isMobile ? 14 : height >= 1180 ? 27 : 14,
                fontWeight: 700,
                boxShadow: "none",
                backgroundColor: "#FFFFFF",
                color: "#52596B",
                border: "1px solid #52596B",
                height: !isMobile ? 32 : height >= 1180 ? 60 : 32,
                marginBottom: 8,
                fontFamily: "Rubik",
                width: height >= 1180 ? "90%" : "100%",
              }}
              variant="contained"
            >
              {t("registry.back")}
              {/* Volver */}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );

  function isDisabled() {
    const disabled = attentions.find(
      (attention) =>
        !attention.title ||
        !attention.description ||
        // !attention.icon ||
        !attention.color ||
        isLoading
    );
    return disabled ? true : false;
  }

  function isDisabledNextButton() {
    const disabled = attentions.find(
      (attention) =>
        !attention.title ||
        !attention.description ||
        // !attention.icon ||
        !attention.color
    );
    return disabled ||
      !hookTitle ||
      !hookSubtitle ||
      !buttonText ||
      !buttonColorAttentions ||
      attentions.length === 0
      ? true
      : false;
  }
}

export default AttentionsForm;
