import { useReducer } from "react";
import { EasyContext } from "./index";
import { EasyReducer } from "./reducer";
import { types } from "../types";

const init = () => {
  const user = JSON.parse(localStorage.getItem("account"));
  const banner = JSON.parse(localStorage.getItem("banner"));
  const principalColor = localStorage.getItem("principalColor");
  const buttonColor = localStorage.getItem("buttonColor");
  const attentions = JSON.parse(localStorage.getItem("attentions"));
  const roles = JSON.parse(localStorage.getItem("roles"));
  const title = localStorage.getItem("title");
  const description = localStorage.getItem("description");
  const buttonTitle = localStorage.getItem("buttonTitle");
  const buttonAttentionsColor = localStorage.getItem("buttonAttentionsColor");
  const companyType = Boolean(localStorage.getItem("companyType"));
  const iconColor = localStorage.getItem("iconColor");
  const lang = localStorage.getItem("lang");

  return {
    user: user ? user : undefined,
    banner: banner ? banner : undefined,
    principalColor: principalColor ? principalColor : undefined,
    buttonColor: buttonColor ? buttonColor : undefined,
    attentions: attentions ? attentions : undefined,
    roles: roles ? roles : undefined,
    title: title ? title : undefined,
    description: description ? description : undefined,
    buttonTitle: buttonTitle ? buttonTitle : undefined,
    buttonAttentionsColor: buttonAttentionsColor
      ? buttonAttentionsColor
      : undefined,
    companyType: companyType ? companyType : undefined,
    iconColor: iconColor ? iconColor : undefined,
    lang: lang ? lang : "es",
  };
};

export const EasyProvider = ({ children }) => {
  const [state, dispatch] = useReducer(EasyReducer, {}, init);

  const setUser = (user) => {
    dispatch({
      type: types.verify_account,
      payload: user,
    });
    localStorage.setItem("account", JSON.stringify(user));
  };

  const setConfigContactCenter = (
    banner,
    principalColor,
    buttonColor,
    asset
  ) => {
    dispatch({
      type: types.config_contact,
      payload: {
        banner,
        principalColor,
        buttonColor,
        asset,
      },
    });
    localStorage.setItem("banner", JSON.stringify(banner));
    localStorage.setItem("principalColor", principalColor);
    localStorage.setItem("buttonColor", buttonColor);
    localStorage.setItem("asset", JSON.stringify(asset));
  };

  const setConfigAttentions = (
    title,
    description,
    buttonTitle,
    buttonAttentionsColor,
    attentions,
    iconColor
  ) => {
    dispatch({
      type: types.config_attentions,
      payload: {
        title,
        description,
        buttonTitle,
        buttonAttentionsColor,
        attentions,
        iconColor,
      },
    });
    localStorage.setItem("title", title);
    localStorage.setItem("description", description);
    localStorage.setItem("buttonTitle", buttonTitle);
    localStorage.setItem("buttonAttentionsColor", buttonAttentionsColor);
    localStorage.setItem("attentions", JSON.stringify(attentions));
    localStorage.setItem("iconColor", iconColor);
  };

  const setConfigRoles = (roles, type, isBack) => {
    dispatch({
      type: types.config_roles,
      payload: {
        roles,
        companyType: type,
      },
    });
    localStorage.setItem("roles", JSON.stringify(roles));
    if (!isBack) {
      localStorage.setItem("companyType", type);
    }
  };

  const setLang = (lang) => {
    dispatch({
      type: types.config_lang,
      payload: {
        lang,
      },
    });
    localStorage.setItem("lang", lang);
  };

  return (
    <EasyContext.Provider
      value={{
        state,
        setUser: setUser,
        setConfigContactCenter: setConfigContactCenter,
        setConfigAttentions: setConfigAttentions,
        setConfigRoles: setConfigRoles,
        setLanguage: setLang,
      }}
    >
      {children}
    </EasyContext.Provider>
  );
};
