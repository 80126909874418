import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import useMediaQuery from "@mui/material/useMediaQuery";

import i18n from "../i18n";
import MobileView from "../views/conditions/mobile";
import DesktopView from "../views/conditions/desktop";
import service from "../service";
import { EasyContext } from "../context";

function Conditions() {
  const theme = useTheme();
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation();
  const { state } = useContext(EasyContext);

  const [lang] = useState(state.user ? state.user.lang : state.lang);

  const [width, setWidth] = useState(window.innerWidth);

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth' });
    
  };

  
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  return !mobileBreakpoint ? <MobileView t={t} /> : <DesktopView t={t} width={width} handleClickScroll={handleClickScroll}/>;
}

export default Conditions;
