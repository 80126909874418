import Swal from "sweetalert2";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { TourProvider } from "@reactour/tour";
import { components } from "@reactour/tour";
import { Box, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import i18n from "../i18n";
import MobileView from "../views/contactCenterConfig/mobile";
import DesktopView from "../views/contactCenterConfig/desktop";
import service from "../service";
import { environment } from "../config";
import { EasyContext } from "../context";

function ContactCenterConfig() {
  const theme = useTheme();
  const navigate = useNavigate();
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation();
  const { state, setConfigContactCenter } = useContext(EasyContext);
  const [lang, setLang] = useState(state.user ? state.user.lang : "es");
  const [openColorPicker, setOpenColorPicker] = useState(false);
  const [openColorPickerButton, setOpenColorPickerButton] = useState(false);
  const [asset, setAsset] = useState(!state.asset ? undefined : state.asset);
  const [banner, setBanner] = useState(
    !state.banner ? undefined : state.banner
  );
  const [principalColor, setPrincipalColor] = useState(
    !state.principalColor ? "#000000" : state.principalColor
  );
  const [buttonColor, setButtonColor] = useState(
    !state.buttonColor ? "#AFB8CD" : state.buttonColor
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const steps = [
    {
      selector: ".stepper",
      content: (
        <Box style={{ padding: 4 }}>
          <Box
            style={{
              fontFamily: "Rubik",
              fontWeight: "bold",
              fontSize: 14,
              color: "#17191E",
              marginBottom: 8,
            }}
          >
            {t("tour.contact.step1")}
          </Box>
          <Box
            style={{
              fontFamily: "Rubik",
              // fontWeight: "bold",
              fontSize: 12,
              color: "#17191E",
            }}
          >
            {t("tour.contact.step1.description")}
          </Box>
        </Box>
      ),
    },
    {
      selector: ".contact-center",
      content: (
        <Box style={{ padding: 4 }}>
          <Box
            style={{
              fontFamily: "Rubik",
              fontWeight: "bold",
              fontSize: 14,
              color: "#17191E",
              marginBottom: 8,
            }}
          >
            {t("tour.contact.step2.title")}
          </Box>
          <Box
            style={{
              fontFamily: "Rubik",
              // fontWeight: "bold",
              fontSize: 12,
              color: "#17191E",
            }}
          >
            {t("tour.contact.step2.description")}
          </Box>
        </Box>
      ),
    },
    {
      selector: ".banner-step",
      content: (
        <Box style={{ padding: 4 }}>
          <Box
            style={{
              fontFamily: "Rubik",
              fontWeight: "bold",
              fontSize: 14,
              color: "#17191E",
              marginBottom: 8,
            }}
          >
            {t("tour.contact.step3.title")}
          </Box>
          <Box
            style={{
              fontFamily: "Rubik",
              // fontWeight: "bold",
              fontSize: 12,
              color: "#17191E",
            }}
          >
            {t("tour.contact.step3.description")}
          </Box>
        </Box>
      ),
    },
    {
      selector: ".color-picket-background",
      content: (
        <Box style={{ padding: 4 }}>
          <Box
            style={{
              fontFamily: "Rubik",
              fontWeight: "bold",
              fontSize: 14,
              color: "#17191E",
              marginBottom: 8,
            }}
          >
            {t("tour.contact.step4.title")}
          </Box>
          <Box
            style={{
              fontFamily: "Rubik",
              // fontWeight: "bold",
              fontSize: 12,
              color: "#17191E",
            }}
          >
            {t("tour.contact.step4.description")}
          </Box>
        </Box>
      ),
    },
    {
      selector: ".color-picket-backgroun2",
      content: (
        <Box style={{ padding: 4 }}>
          <Box
            style={{
              fontFamily: "Rubik",
              fontWeight: "bold",
              fontSize: 14,
              color: "#17191E",
              marginBottom: 8,
            }}
          >
            {t("tour.contact.step4.title")}
          </Box>
          <Box
            style={{
              fontFamily: "Rubik",
              // fontWeight: "bold",
              fontSize: 12,
              color: "#17191E",
            }}
          >
            {t("tour.contact.step5.description")}
          </Box>
        </Box>
      ),
    },
    {
      selector: ".contact-senter-step",
      content: (
        <Box style={{ padding: 4 }}>
          <Box
            style={{
              fontFamily: "Rubik",
              fontWeight: "bold",
              fontSize: 14,
              color: "#17191E",
              marginBottom: 8,
            }}
          >
            {t("tour.contact.step6.title")}
          </Box>
          <Box
            style={{
              fontFamily: "Rubik",
              // fontWeight: "bold",
              fontSize: 12,
              color: "#17191E",
            }}
          >
            {t("tour.contact.step6.description")}
          </Box>
        </Box>
      ),
    },
    {
      selector: ".button-screen-step",
      content: (
        <Box style={{ padding: 4 }}>
          <Box
            style={{
              fontFamily: "Rubik",
              fontWeight: "bold",
              fontSize: 14,
              color: "#17191E",
              marginBottom: 8,
            }}
          >
            {t("tour.contact.step7.title")}
          </Box>
          <Box
            style={{
              fontFamily: "Rubik",
              // fontWeight: "bold",
              fontSize: 12,
              color: "#17191E",
            }}
          >
            {t("tour.contact.step7.description")}
          </Box>
        </Box>
      ),
    },
  ];

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);
  useEffect(() => {
    if (!localStorage.getItem("account")) navigate("/");
  }, []);

  const ContactCenterConfigComponent = ({ isOpen }) => {
    return !mobileBreakpoint ? (
      <MobileView
        t={t}
        lang={lang}
        setLang={setLang}
        banner={banner}
        handleChageBanner={handleChageBanner}
        principalColor={principalColor}
        setPrincipalColor={setPrincipalColor}
        openColorPicker={openColorPicker}
        setOpenColorPicker={setOpenColorPicker}
        buttonColor={buttonColor}
        setButtonColor={setButtonColor}
        openColorPickerButton={openColorPickerButton}
        setOpenColorPickerButton={setOpenColorPickerButton}
        onUpdatePreferences={onUpdatePreferences}
        navigate={navigate}
        isLoading={isLoading}
        showTour={showTour}
        isOpen={isOpen}
      />
    ) : (
      <DesktopView
        t={t}
        lang={lang}
        setLang={setLang}
        banner={banner}
        handleChageBanner={handleChageBanner}
        principalColor={principalColor}
        setPrincipalColor={setPrincipalColor}
        openColorPicker={openColorPicker}
        setOpenColorPicker={setOpenColorPicker}
        buttonColor={buttonColor}
        setButtonColor={setButtonColor}
        openColorPickerButton={openColorPickerButton}
        setOpenColorPickerButton={setOpenColorPickerButton}
        onUpdatePreferences={onUpdatePreferences}
        navigate={navigate}
        isLoading={isLoading}
        showTour={showTour}
      />
    );
  };

  return isOpen ? (
    <TourProvider
      steps={steps}
      defaultOpen={true}
      onClickClose={() => {
        if (document.getElementById("back-hook"))
          document.getElementById("back-hook").click();
        setIsOpen(false);
      }}
      disableInteraction={true}
      onClickMask={() => {
        if (document.getElementById("back-hook"))
          document.getElementById("back-hook").click();
        setIsOpen(false);
      }}
      position="right"
      components={{ Badge }}
      badgeContent={({ totalSteps, currentStep }) =>
        `${currentStep + 1}/${totalSteps}`
      }
      nextButton={({ currentStep, setCurrentStep, stepsLength }) => (
        <IconButton
          disabled={currentStep + 1 === stepsLength}
          onClick={() => {
            if (!mobileBreakpoint) {
              currentStep === 5 && document.getElementById("previewId").click();
              setCurrentStep(currentStep + 1);
            } else {
              setCurrentStep(currentStep + 1);
            }
          }}
        >
          <ArrowForwardIcon />
        </IconButton>
      )}
      styles={{
        controls: (base) => ({ ...base, justifyContent: "center" }),
      }}
      prevButton={({ currentStep, setCurrentStep }) => (
        <IconButton
          disabled={currentStep === 0}
          onClick={() => {
            if (currentStep !== 5) {
              document.getElementById("back-hook") &&
                document.getElementById("back-hook").click();
            }
            setCurrentStep(currentStep - 1);
          }}
        >
          <ArrowBackIcon />
        </IconButton>
      )}
    >
      <ContactCenterConfigComponent isOpen={isOpen} />
    </TourProvider>
  ) : !mobileBreakpoint ? (
    <MobileView
      t={t}
      lang={lang}
      setLang={setLang}
      banner={banner}
      handleChageBanner={handleChageBanner}
      principalColor={principalColor}
      setPrincipalColor={setPrincipalColor}
      openColorPicker={openColorPicker}
      setOpenColorPicker={setOpenColorPicker}
      buttonColor={buttonColor}
      setButtonColor={setButtonColor}
      openColorPickerButton={openColorPickerButton}
      setOpenColorPickerButton={setOpenColorPickerButton}
      onUpdatePreferences={onUpdatePreferences}
      navigate={navigate}
      isLoading={isLoading}
      showTour={showTour}
    />
  ) : (
    <DesktopView
      t={t}
      lang={lang}
      setLang={setLang}
      banner={banner}
      handleChageBanner={handleChageBanner}
      principalColor={principalColor}
      setPrincipalColor={setPrincipalColor}
      openColorPicker={openColorPicker}
      setOpenColorPicker={setOpenColorPicker}
      buttonColor={buttonColor}
      setButtonColor={setButtonColor}
      openColorPickerButton={openColorPickerButton}
      setOpenColorPickerButton={setOpenColorPickerButton}
      onUpdatePreferences={onUpdatePreferences}
      navigate={navigate}
      isLoading={isLoading}
      showTour={showTour}
    />
  );

  function showTour() {
    setIsOpen(true);
  }

  function Badge({ children }) {
    return (
      <components.Badge
        styles={{
          badge: (base) => ({
            ...base,
            backgroundColor: "#17191E",
            // width: 34,
          }),
        }}
      >
        {children}
      </components.Badge>
    );
  }

  function convertToBase64(blob) {
    return new Promise((resolve) => {
      var reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  }

  async function handleChageBanner(event) {
    const file = event.target.files[0];
    const resizedString = await convertToBase64(file);
    setBanner({
      image: resizedString,
      name: file.name,
      size: parseFloat(file.size / 1024).toFixed(2),
    });
    setAsset(file);
  }

  async function onUpdatePreferences(banner, principalColor, buttonColor) {
    try {
      setIsLoading(true);
      if (asset) {
        const image = await service.uploadBanner(asset, state.user.company._id);
        const urlBanner = `${environment.storage}/c/${image._id}`;
        localStorage.setItem("urlBanner", urlBanner);
        const data = await service.updatePreference({
          banner: urlBanner,
          principalColor,
          buttonColor,
          companyId: state.user.company._id,
        });
        await setConfigContactCenter(
          banner,
          principalColor,
          buttonColor,
          asset
        );
        setIsLoading(false);
        navigate("/attentions/config");
      } else {
        const data = await service.updatePreference({
          banner: localStorage.getItem("urlBanner"),
          principalColor,
          buttonColor,
          companyId: state.user.company._id,
        });
        await setConfigContactCenter(
          banner,
          principalColor,
          buttonColor,
          asset
        );
        setIsLoading(false);
        navigate("/attentions/config");
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      Swal.fire({
        customClass: {
          confirmButton: "class1",
        },
        icon: "error",
        text: t(err.dataResponse.msg),
      });
      console.log("[Err update preferences] => ", err);
    }
  }
}

export default ContactCenterConfig;
