import { useState } from "react";
import { Box, OutlinedInput, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

export const iconsData = [
  { icon: "3d_rotation" },
  { icon: "ac_unit" },
  { icon: "access_alarm" },
  { icon: "access_alarms" },
  { icon: "access_time" },
  { icon: "accessibility" },
  { icon: "accessible" },
  { icon: "account_balance" },
  { icon: "account_balance_wallet" },
  { icon: "account_box" },
  { icon: "account_circle" },
  { icon: "adb" },
  { icon: "add" },
  { icon: "add_a_photo" },
  { icon: "sentiment_satisfied" },
  { icon: "add_alarm" },
  { icon: "add_alert" },
  { icon: "add_box" },
  { icon: "add_circle" },
  { icon: "add_circle_outline" },
  { icon: "add_location" },
  { icon: "add_shopping_cart" },
  { icon: "add_to_photos" },
  { icon: "add_to_queue" },
  { icon: "adjust" },
  { icon: "airline_seat_flat" },
  { icon: "airline_seat_flat_angled" },
  { icon: "airline_seat_individual_suite" },
  { icon: "airline_seat_legroom_extra" },
  { icon: "airline_seat_legroom_normal" },
  { icon: "airline_seat_legroom_reduced" },
  { icon: "airline_seat_recline_extra" },
  { icon: "airline_seat_recline_normal" },
  { icon: "airplanemode_active" },
  { icon: "airplanemode_inactive" },
  { icon: "airplay" },
  { icon: "airport_shuttle" },
  { icon: "alarm" },
  { icon: "alarm_add" },
  { icon: "alarm_off" },
  { icon: "alarm_on" },
  { icon: "album" },
  { icon: "all_inclusive" },
  { icon: "all_out" },
  { icon: "android" },
  { icon: "announcement" },
  { icon: "apps" },
  { icon: "archive" },
  { icon: "arrow_back" },
  { icon: "arrow_downward" },
  { icon: "arrow_drop_down" },
  { icon: "arrow_drop_down_circle" },
  { icon: "arrow_drop_up" },
  { icon: "arrow_forward" },
  { icon: "arrow_upward" },
  { icon: "art_track" },
  { icon: "aspect_ratio" },
  { icon: "assessment" },
  { icon: "assignment" },
  { icon: "assignment_ind" },
  { icon: "assignment_late" },
  { icon: "assignment_return" },
  { icon: "assignment_returned" },
  { icon: "assignment_turned_in" },
  { icon: "assistant" },
  { icon: "assistant_photo" },
  { icon: "attach_file" },
  { icon: "attach_money" },
  { icon: "attachment" },
  { icon: "audiotrack" },
  { icon: "autorenew" },
  { icon: "av_timer" },
  { icon: "backspace" },
  { icon: "backup" },
  { icon: "battery_alert" },
  { icon: "battery_charging_full" },
  { icon: "battery_full" },
  { icon: "battery_std" },
  { icon: "battery_unknown" },
  { icon: "beach_access" },
  { icon: "beenhere" },
  { icon: "block" },
  { icon: "bluetooth" },
  { icon: "bluetooth_audio" },
  { icon: "bluetooth_connected" },
  { icon: "bluetooth_disabled" },
  { icon: "bluetooth_searching" },
  { icon: "blur_circular" },
  { icon: "blur_linear" },
  { icon: "blur_off" },
  { icon: "blur_on" },
  { icon: "book" },
  { icon: "bookmark" },
  { icon: "bookmark_border" },
  { icon: "border_all" },
  { icon: "border_bottom" },
  { icon: "border_clear" },
  { icon: "border_color" },
  { icon: "border_horizontal" },
  { icon: "border_inner" },
  { icon: "border_left" },
  { icon: "border_outer" },
  { icon: "border_right" },
  { icon: "border_style" },
  { icon: "border_top" },
  { icon: "border_vertical" },
  { icon: "branding_watermark" },
  { icon: "brightness_1" },
  { icon: "brightness_2" },
  { icon: "brightness_3" },
  { icon: "brightness_4" },
  { icon: "brightness_5" },
  { icon: "brightness_6" },
  { icon: "brightness_7" },
  { icon: "brightness_auto" },
  { icon: "brightness_high" },
  { icon: "brightness_low" },
  { icon: "brightness_medium" },
  { icon: "broken_image" },
  { icon: "brush" },
  { icon: "bubble_chart" },
  { icon: "bug_report" },
  { icon: "build" },
  { icon: "burst_mode" },
  { icon: "business" },
  { icon: "business_center" },
  { icon: "cached" },
  { icon: "cake" },
  { icon: "call" },
  { icon: "call_end" },
  { icon: "call_made" },
  { icon: "call_merge" },
  { icon: "call_missed" },
  { icon: "call_missed_outgoing" },
  { icon: "call_received" },
  { icon: "call_split" },
  { icon: "call_to_action" },
  { icon: "camera" },
  { icon: "camera_alt" },
  { icon: "camera_enhance" },
  { icon: "camera_front" },
  { icon: "camera_rear" },
  { icon: "camera_roll" },
  { icon: "cancel" },
  { icon: "card_giftcard" },
  { icon: "card_membership" },
  { icon: "card_travel" },
  { icon: "casino" },
  { icon: "cast" },
  { icon: "cast_connected" },
  { icon: "center_focus_strong" },
  { icon: "center_focus_weak" },
  { icon: "change_history" },
  { icon: "chat" },
  { icon: "chat_bubble" },
  { icon: "chat_bubble_outline" },
  { icon: "check" },
  { icon: "check_box" },
  { icon: "check_box_outline_blank" },
  { icon: "check_circle" },
  { icon: "chevron_left" },
  { icon: "chevron_right" },
  { icon: "child_care" },
  { icon: "child_friendly" },
  { icon: "chrome_reader_mode" },
  { icon: "class" },
  { icon: "clear" },
  { icon: "clear_all" },
  { icon: "close" },
  { icon: "closed_caption" },
  { icon: "cloud" },
  { icon: "cloud_circle" },
  { icon: "cloud_done" },
  { icon: "cloud_download" },
  { icon: "cloud_off" },
  { icon: "cloud_queue" },
  { icon: "cloud_upload" },
  { icon: "code" },
  { icon: "collections" },
  { icon: "collections_bookmark" },
  { icon: "color_lens" },
  { icon: "colorize" },
  { icon: "comment" },
  { icon: "compare" },
  { icon: "compare_arrows" },
  { icon: "computer" },
  { icon: "confirmation_number" },
  { icon: "contact_mail" },
  { icon: "contact_phone" },
  { icon: "contacts" },
  { icon: "content_copy" },
  { icon: "content_cut" },
  { icon: "content_paste" },
  { icon: "control_point" },
  { icon: "control_point_duplicate" },
  { icon: "copyright" },
  { icon: "create" },
  { icon: "create_new_folder" },
  { icon: "credit_card" },
  { icon: "crop" },
  { icon: "crop_16_9" },
  { icon: "crop_3_2" },
  { icon: "crop_5_4" },
  { icon: "crop_7_5" },
  { icon: "crop_din" },
  { icon: "crop_free" },
  { icon: "crop_landscape" },
  { icon: "crop_original" },
  { icon: "crop_portrait" },
  { icon: "crop_rotate" },
  { icon: "crop_square" },
  { icon: "dashboard" },
  { icon: "data_usage" },
  { icon: "date_range" },
  { icon: "dehaze" },
  { icon: "delete" },
  { icon: "delete_forever" },
  { icon: "delete_sweep" },
  { icon: "description" },
  { icon: "desktop_mac" },
  { icon: "desktop_windows" },
  { icon: "details" },
  { icon: "developer_board" },
  { icon: "developer_mode" },
  { icon: "device_hub" },
  { icon: "devices" },
  { icon: "devices_other" },
  { icon: "dialer_sip" },
  { icon: "dialpad" },
  { icon: "directions" },
  { icon: "directions_bike" },
  { icon: "directions_boat" },
  { icon: "directions_bus" },
  { icon: "directions_car" },
  { icon: "directions_railway" },
  { icon: "directions_run" },
  { icon: "directions_subway" },
  { icon: "directions_transit" },
  { icon: "directions_walk" },
  { icon: "disc_full" },
  { icon: "dns" },
  { icon: "do_not_disturb" },
  { icon: "do_not_disturb_alt" },
  { icon: "do_not_disturb_off" },
  { icon: "do_not_disturb_on" },
  { icon: "dock" },
  { icon: "domain" },
  { icon: "done" },
  { icon: "done_all" },
  { icon: "donut_large" },
  { icon: "donut_small" },
  { icon: "drafts" },
  { icon: "drag_handle" },
  { icon: "drive_eta" },
  { icon: "dvr" },
  { icon: "edit" },
  { icon: "edit_location" },
  { icon: "eject" },
  { icon: "email" },
  { icon: "enhanced_encryption" },
  { icon: "equalizer" },
  { icon: "error" },
  { icon: "error_outline" },
  { icon: "euro_symbol" },
  { icon: "ev_station" },
  { icon: "event" },
  { icon: "event_available" },
  { icon: "event_busy" },
  { icon: "event_note" },
  { icon: "event_seat" },
  { icon: "exit_to_app" },
  { icon: "expand_less" },
  { icon: "expand_more" },
  { icon: "explicit" },
  { icon: "explore" },
  { icon: "exposure" },
  { icon: "exposure_neg_1" },
  { icon: "exposure_neg_2" },
  { icon: "exposure_plus_1" },
  { icon: "exposure_plus_2" },
  { icon: "exposure_zero" },
  { icon: "extension" },
  { icon: "face" },
  { icon: "fast_forward" },
  { icon: "fast_rewind" },
  { icon: "favorite" },
  { icon: "favorite_border" },
  { icon: "featured_play_list" },
  { icon: "featured_video" },
  { icon: "feedback" },
  { icon: "fiber_dvr" },
  { icon: "fiber_manual_record" },
  { icon: "fiber_new" },
  { icon: "fiber_pin" },
  { icon: "fiber_smart_record" },
  { icon: "file_download" },
  { icon: "file_upload" },
  { icon: "filter" },
  { icon: "filter_1" },
  { icon: "filter_2" },
  { icon: "filter_3" },
  { icon: "filter_4" },
  { icon: "filter_5" },
  { icon: "filter_6" },
  { icon: "filter_7" },
  { icon: "filter_8" },
  { icon: "filter_9" },
  { icon: "filter_9_plus" },
  { icon: "filter_b_and_w" },
  { icon: "filter_center_focus" },
  { icon: "filter_drama" },
  { icon: "filter_frames" },
  { icon: "filter_hdr" },
  { icon: "filter_list" },
  { icon: "filter_none" },
  { icon: "filter_tilt_shift" },
  { icon: "filter_vintage" },
  { icon: "find_in_page" },
  { icon: "find_replace" },
  { icon: "fingerprint" },
  { icon: "first_page" },
  { icon: "fitness_center" },
  { icon: "flag" },
  { icon: "flare" },
  { icon: "flash_auto" },
  { icon: "flash_off" },
  { icon: "flash_on" },
  { icon: "flight" },
  { icon: "flight_land" },
  { icon: "flight_takeoff" },
  { icon: "flip" },
  { icon: "flip_to_back" },
  { icon: "flip_to_front" },
  { icon: "folder" },
  { icon: "folder_open" },
  { icon: "folder_shared" },
  { icon: "folder_special" },
  { icon: "font_download" },
  { icon: "format_align_center" },
  { icon: "format_align_justify" },
  { icon: "format_align_left" },
  { icon: "format_align_right" },
  { icon: "format_bold" },
  { icon: "format_clear" },
  { icon: "format_color_fill" },
  { icon: "format_color_reset" },
  { icon: "format_color_text" },
  { icon: "format_indent_decrease" },
  { icon: "format_indent_increase" },
  { icon: "format_italic" },
  { icon: "format_line_spacing" },
  { icon: "format_list_bulleted" },
  { icon: "format_list_numbered" },
  { icon: "format_paint" },
  { icon: "format_quote" },
  { icon: "format_shapes" },
  { icon: "format_size" },
  { icon: "format_strikethrough" },
  { icon: "format_textdirection_l_to_r" },
  { icon: "format_textdirection_r_to_l" },
  { icon: "format_underlined" },
  { icon: "forum" },
  { icon: "forward" },
  { icon: "forward_10" },
  { icon: "forward_30" },
  { icon: "forward_5" },
  { icon: "free_breakfast" },
  { icon: "fullscreen" },
  { icon: "fullscreen_exit" },
  { icon: "functions" },
  { icon: "g_translate" },
  { icon: "gamepad" },
  { icon: "games" },
  { icon: "gavel" },
  { icon: "gesture" },
  { icon: "get_app" },
  { icon: "gif" },
  { icon: "golf_course" },
  { icon: "gps_fixed" },
  { icon: "gps_not_fixed" },
  { icon: "gps_off" },
  { icon: "grade" },
  { icon: "gradient" },
  { icon: "grain" },
  { icon: "graphic_eq" },
  { icon: "grid_off" },
  { icon: "grid_on" },
  { icon: "group" },
  { icon: "group_add" },
  { icon: "group_work" },
  { icon: "hd" },
  { icon: "hdr_off" },
  { icon: "hdr_on" },
  { icon: "hdr_strong" },
  { icon: "hdr_weak" },
  { icon: "headset" },
  { icon: "headset_mic" },
  { icon: "healing" },
  { icon: "hearing" },
  { icon: "help" },
  { icon: "help_outline" },
  { icon: "high_quality" },
  { icon: "highlight" },
  { icon: "highlight_off" },
  { icon: "history" },
  { icon: "home" },
  { icon: "hot_tub" },
  { icon: "hotel" },
  { icon: "hourglass_empty" },
  { icon: "hourglass_full" },
  { icon: "http" },
  { icon: "https" },
  { icon: "image" },
  { icon: "image_aspect_ratio" },
  { icon: "import_contacts" },
  { icon: "import_export" },
  { icon: "important_devices" },
  { icon: "inbox" },
  { icon: "indeterminate_check_box" },
  { icon: "info" },
  { icon: "info_outline" },
  { icon: "input" },
  { icon: "insert_chart" },
  { icon: "insert_comment" },
  { icon: "insert_drive_file" },
  { icon: "insert_emoticon" },
  { icon: "insert_invitation" },
  { icon: "insert_link" },
  { icon: "insert_photo" },
  { icon: "invert_colors" },
  { icon: "invert_colors_off" },
  { icon: "iso" },
  { icon: "keyboard" },
  { icon: "keyboard_arrow_down" },
  { icon: "keyboard_arrow_left" },
  { icon: "keyboard_arrow_right" },
  { icon: "keyboard_arrow_up" },
  { icon: "keyboard_backspace" },
  { icon: "keyboard_capslock" },
  { icon: "keyboard_hide" },
  { icon: "keyboard_return" },
  { icon: "keyboard_tab" },
  { icon: "keyboard_voice" },
  { icon: "kitchen" },
  { icon: "label" },
  { icon: "label_outline" },
  { icon: "landscape" },
  { icon: "language" },
  { icon: "laptop" },
  { icon: "laptop_chromebook" },
  { icon: "laptop_mac" },
  { icon: "laptop_windows" },
  { icon: "last_page" },
  { icon: "launch" },
  { icon: "layers" },
  { icon: "layers_clear" },
  { icon: "leak_add" },
  { icon: "leak_remove" },
  { icon: "lens" },
  { icon: "library_add" },
  { icon: "library_books" },
  { icon: "library_music" },
  { icon: "lightbulb_outline" },
  { icon: "line_style" },
  { icon: "line_weight" },
  { icon: "linear_scale" },
  { icon: "link" },
  { icon: "linked_camera" },
  { icon: "list" },
  { icon: "live_help" },
  { icon: "live_tv" },
  { icon: "local_activity" },
  { icon: "local_airport" },
  { icon: "local_atm" },
  { icon: "local_bar" },
  { icon: "local_cafe" },
  { icon: "local_car_wash" },
  { icon: "local_convenience_store" },
  { icon: "local_dining" },
  { icon: "local_drink" },
  { icon: "local_florist" },
  { icon: "local_gas_station" },
  { icon: "local_grocery_store" },
  { icon: "local_hospital" },
  { icon: "local_hotel" },
  { icon: "local_laundry_service" },
  { icon: "local_library" },
  { icon: "local_mall" },
  { icon: "local_movies" },
  { icon: "local_offer" },
  { icon: "local_parking" },
  { icon: "local_pharmacy" },
  { icon: "local_phone" },
  { icon: "local_pizza" },
  { icon: "local_play" },
  { icon: "local_post_office" },
  { icon: "local_printshop" },
  { icon: "local_see" },
  { icon: "local_shipping" },
  { icon: "local_taxi" },
  { icon: "location_city" },
  { icon: "location_disabled" },
  { icon: "location_off" },
  { icon: "location_on" },
  { icon: "location_searching" },
  { icon: "lock" },
  { icon: "lock_open" },
  { icon: "lock_outline" },
  { icon: "looks" },
  { icon: "looks_3" },
  { icon: "looks_4" },
  { icon: "looks_5" },
  { icon: "looks_6" },
  { icon: "looks_one" },
  { icon: "looks_two" },
  { icon: "loop" },
  { icon: "loupe" },
  { icon: "low_priority" },
  { icon: "loyalty" },
  { icon: "mail" },
  { icon: "mail_outline" },
  { icon: "map" },
  { icon: "markunread" },
  { icon: "markunread_mailbox" },
  { icon: "memory" },
  { icon: "menu" },
  { icon: "merge_type" },
  { icon: "message" },
  { icon: "mic" },
  { icon: "mic_none" },
  { icon: "mic_off" },
  { icon: "mms" },
  { icon: "mode_comment" },
  { icon: "mode_edit" },
  { icon: "monetization_on" },
  { icon: "money_off" },
  { icon: "monochrome_photos" },
  { icon: "mood" },
  { icon: "mood_bad" },
  { icon: "more" },
  { icon: "more_horiz" },
  { icon: "more_vert" },
  { icon: "motorcycle" },
  { icon: "mouse" },
  { icon: "move_to_inbox" },
  { icon: "movie" },
  { icon: "movie_creation" },
  { icon: "movie_filter" },
  { icon: "multiline_chart" },
  { icon: "music_note" },
  { icon: "music_video" },
  { icon: "my_location" },
  { icon: "nature" },
  { icon: "nature_people" },
  { icon: "navigate_before" },
  { icon: "navigate_next" },
  { icon: "navigation" },
  { icon: "near_me" },
  { icon: "network_cell" },
  { icon: "network_check" },
  { icon: "network_locked" },
  { icon: "network_wifi" },
  { icon: "new_releases" },
  { icon: "next_week" },
  { icon: "nfc" },
  { icon: "no_encryption" },
  { icon: "no_sim" },
  { icon: "not_interested" },
  { icon: "note" },
  { icon: "note_add" },
  { icon: "notifications" },
  { icon: "notifications_active" },
  { icon: "notifications_none" },
  { icon: "notifications_off" },
  { icon: "notifications_paused" },
  { icon: "offline_pin" },
  { icon: "ondemand_video" },
  { icon: "opacity" },
  { icon: "open_in_browser" },
  { icon: "open_in_new" },
  { icon: "open_with" },
  { icon: "pages" },
  { icon: "pageview" },
  { icon: "palette" },
  { icon: "pan_tool" },
  { icon: "panorama" },
  { icon: "panorama_fish_eye" },
  { icon: "panorama_horizontal" },
  { icon: "panorama_vertical" },
  { icon: "panorama_wide_angle" },
  { icon: "party_mode" },
  { icon: "pause" },
  { icon: "pause_circle_filled" },
  { icon: "pause_circle_outline" },
  { icon: "payment" },
  { icon: "people" },
  { icon: "people_outline" },
  { icon: "perm_camera_mic" },
  { icon: "perm_contact_calendar" },
  { icon: "perm_data_setting" },
  { icon: "perm_device_information" },
  { icon: "perm_identity" },
  { icon: "perm_media" },
  { icon: "perm_phone_msg" },
  { icon: "perm_scan_wifi" },
  { icon: "person" },
  { icon: "person_add" },
  { icon: "person_outline" },
  { icon: "person_pin" },
  { icon: "person_pin_circle" },
  { icon: "personal_video" },
  { icon: "pets" },
  { icon: "phone" },
  { icon: "phone_android" },
  { icon: "phone_bluetooth_speaker" },
  { icon: "phone_forwarded" },
  { icon: "phone_in_talk" },
  { icon: "phone_iphone" },
  { icon: "phone_locked" },
  { icon: "phone_missed" },
  { icon: "phone_paused" },
  { icon: "phonelink" },
  { icon: "phonelink_erase" },
  { icon: "phonelink_lock" },
  { icon: "phonelink_off" },
  { icon: "phonelink_ring" },
  { icon: "phonelink_setup" },
  { icon: "photo" },
  { icon: "photo_album" },
  { icon: "photo_camera" },
  { icon: "photo_filter" },
  { icon: "photo_library" },
  { icon: "photo_size_select_actual" },
  { icon: "photo_size_select_large" },
  { icon: "photo_size_select_small" },
  { icon: "picture_as_pdf" },
  { icon: "picture_in_picture" },
  { icon: "picture_in_picture_alt" },
  { icon: "pie_chart" },
  { icon: "pie_chart_outlined" },
  { icon: "pin_drop" },
  { icon: "place" },
  { icon: "play_arrow" },
  { icon: "play_circle_filled" },
  { icon: "play_circle_outline" },
  { icon: "play_for_work" },
  { icon: "playlist_add" },
  { icon: "playlist_add_check" },
  { icon: "playlist_play" },
  { icon: "plus_one" },
  { icon: "poll" },
  { icon: "polymer" },
  { icon: "pool" },
  { icon: "portable_wifi_off" },
  { icon: "portrait" },
  { icon: "power" },
  { icon: "power_input" },
  { icon: "power_settings_new" },
  { icon: "pregnant_woman" },
  { icon: "present_to_all" },
  { icon: "print" },
  { icon: "priority_high" },
  { icon: "public" },
  { icon: "publish" },
  { icon: "query_builder" },
  { icon: "question_answer" },
  { icon: "queue" },
  { icon: "queue_music" },
  { icon: "queue_play_next" },
  { icon: "radio" },
  { icon: "radio_button_checked" },
  { icon: "radio_button_unchecked" },
  { icon: "rate_review" },
  { icon: "receipt" },
  { icon: "recent_actors" },
  { icon: "record_voice_over" },
  { icon: "redeem" },
  { icon: "redo" },
  { icon: "refresh" },
  { icon: "remove" },
  { icon: "remove_circle" },
  { icon: "remove_circle_outline" },
  { icon: "remove_from_queue" },
  { icon: "remove_red_eye" },
  { icon: "remove_shopping_cart" },
  { icon: "reorder" },
  { icon: "repeat" },
  { icon: "repeat_one" },
  { icon: "replay" },
  { icon: "replay_10" },
  { icon: "replay_30" },
  { icon: "replay_5" },
  { icon: "reply" },
  { icon: "reply_all" },
  { icon: "report" },
  { icon: "report_problem" },
  { icon: "restaurant" },
  { icon: "restaurant_menu" },
  { icon: "restore" },
  { icon: "restore_page" },
  { icon: "ring_volume" },
  { icon: "room" },
  { icon: "room_service" },
  { icon: "rotate_90_degrees_ccw" },
  { icon: "rotate_left" },
  { icon: "rotate_right" },
  { icon: "rounded_corner" },
  { icon: "router" },
  { icon: "rowing" },
  { icon: "rss_feed" },
  { icon: "rv_hookup" },
  { icon: "satellite" },
  { icon: "save" },
  { icon: "scanner" },
  { icon: "schedule" },
  { icon: "school" },
  { icon: "screen_lock_landscape" },
  { icon: "screen_lock_portrait" },
  { icon: "screen_lock_rotation" },
  { icon: "screen_rotation" },
  { icon: "screen_share" },
  { icon: "sd_card" },
  { icon: "sd_storage" },
  { icon: "search" },
  { icon: "security" },
  { icon: "select_all" },
  { icon: "send" },
  { icon: "sentiment_dissatisfied" },
  { icon: "sentiment_neutral" },
  { icon: "sentiment_satisfied" },
  { icon: "sentiment_very_dissatisfied" },

  { icon: "settings" },
  { icon: "settings_applications" },
  { icon: "settings_backup_restore" },
  { icon: "settings_bluetooth" },
  { icon: "settings_brightness" },
  { icon: "settings_cell" },
  { icon: "settings_ethernet" },
  { icon: "settings_input_antenna" },
  { icon: "settings_input_component" },
  { icon: "settings_input_composite" },
  { icon: "settings_input_hdmi" },
  { icon: "settings_input_svideo" },
  { icon: "settings_overscan" },
  { icon: "settings_phone" },
  { icon: "settings_power" },
  { icon: "settings_remote" },
  { icon: "settings_system_daydream" },
  { icon: "settings_voice" },
  { icon: "share" },
  { icon: "shop" },
  { icon: "shop_two" },
  { icon: "shopping_basket" },
  { icon: "shopping_cart" },
  { icon: "short_text" },
  { icon: "show_chart" },
  { icon: "shuffle" },
  { icon: "signal_cellular_4_bar" },
  { icon: "signal_cellular_connected_no_internet_4_bar" },
  { icon: "signal_cellular_no_sim" },
  { icon: "signal_cellular_null" },
  { icon: "signal_cellular_off" },
  { icon: "signal_wifi_4_bar" },
  { icon: "signal_wifi_4_bar_lock" },
  { icon: "signal_wifi_off" },
  { icon: "sim_card" },
  { icon: "sim_card_alert" },
  { icon: "skip_next" },
  { icon: "skip_previous" },
  { icon: "slideshow" },
  { icon: "slow_motion_video" },
  { icon: "smartphone" },
  { icon: "smoke_free" },
  { icon: "smoking_rooms" },
  { icon: "sms" },
  { icon: "sms_failed" },
  { icon: "snooze" },
  { icon: "sort" },
  { icon: "sort_by_alpha" },
  { icon: "spa" },
  { icon: "space_bar" },
  { icon: "speaker" },
  { icon: "speaker_group" },
  { icon: "speaker_notes" },
  { icon: "speaker_notes_off" },
  { icon: "speaker_phone" },
  { icon: "spellcheck" },
  { icon: "star" },
  { icon: "star_border" },
  { icon: "star_half" },
  { icon: "stars" },
  { icon: "stay_current_landscape" },
  { icon: "stay_current_portrait" },
  { icon: "stay_primary_landscape" },
  { icon: "stay_primary_portrait" },
  { icon: "stop" },
  { icon: "stop_screen_share" },
  { icon: "storage" },
  { icon: "store" },
  { icon: "store_mall_directory" },
  { icon: "straighten" },
  { icon: "streetview" },
  { icon: "strikethrough_s" },
  { icon: "style" },
  { icon: "subdirectory_arrow_left" },
  { icon: "subdirectory_arrow_right" },
  { icon: "subject" },
  { icon: "subscriptions" },
  { icon: "subtitles" },
  { icon: "subway" },
  { icon: "supervisor_account" },
  { icon: "surround_sound" },
  { icon: "swap_calls" },
  { icon: "swap_horiz" },
  { icon: "swap_vert" },
  { icon: "swap_vertical_circle" },
  { icon: "switch_camera" },
  { icon: "switch_video" },
  { icon: "sync" },
  { icon: "sync_disabled" },
  { icon: "sync_problem" },
  { icon: "system_update" },
  { icon: "system_update_alt" },
  { icon: "tab" },
  { icon: "tab_unselected" },
  { icon: "tablet" },
  { icon: "tablet_android" },
  { icon: "tablet_mac" },
  { icon: "tag_faces" },
  { icon: "tap_and_play" },
  { icon: "terrain" },
  { icon: "text_fields" },
  { icon: "text_format" },
  { icon: "textsms" },
  { icon: "texture" },
  { icon: "theaters" },
  { icon: "thumb_down" },
  { icon: "thumb_up" },
  { icon: "thumbs_up_down" },
  { icon: "time_to_leave" },
  { icon: "timelapse" },
  { icon: "timeline" },
  { icon: "timer" },
  { icon: "timer_10" },
  { icon: "timer_3" },
  { icon: "timer_off" },
  { icon: "title" },
  { icon: "toc" },
  { icon: "today" },
  { icon: "toll" },
  { icon: "tonality" },
  { icon: "touch_app" },
  { icon: "toys" },
  { icon: "track_changes" },
  { icon: "traffic" },
  { icon: "train" },
  { icon: "tram" },
  { icon: "transfer_within_a_station" },
  { icon: "transform" },
  { icon: "translate" },
  { icon: "trending_down" },
  { icon: "trending_flat" },
  { icon: "trending_up" },
  { icon: "tune" },
  { icon: "turned_in" },
  { icon: "turned_in_not" },
  { icon: "tv" },
  { icon: "unarchive" },
  { icon: "undo" },
  { icon: "unfold_less" },
  { icon: "unfold_more" },
  { icon: "update" },
  { icon: "usb" },
  { icon: "verified_user" },
  { icon: "vertical_align_bottom" },
  { icon: "vertical_align_center" },
  { icon: "vertical_align_top" },
  { icon: "vibration" },
  { icon: "video_call" },
  { icon: "video_label" },
  { icon: "video_library" },
  { icon: "videocam" },
  { icon: "videocam_off" },
  { icon: "videogame_asset" },
  { icon: "view_agenda" },
  { icon: "view_array" },
  { icon: "view_carousel" },
  { icon: "view_column" },
  { icon: "view_comfy" },
  { icon: "view_compact" },
  { icon: "view_day" },
  { icon: "view_headline" },
  { icon: "view_list" },
  { icon: "view_module" },
  { icon: "view_quilt" },
  { icon: "view_stream" },
  { icon: "view_week" },
  { icon: "vignette" },
  { icon: "visibility" },
  { icon: "visibility_off" },
  { icon: "voice_chat" },
  { icon: "voicemail" },
  { icon: "volume_down" },
  { icon: "volume_mute" },
  { icon: "volume_off" },
  { icon: "volume_up" },
  { icon: "vpn_key" },
  { icon: "vpn_lock" },
  { icon: "wallpaper" },
  { icon: "warning" },
  { icon: "watch" },
  { icon: "watch_later" },
  { icon: "wb_auto" },
  { icon: "wb_cloudy" },
  { icon: "wb_incandescent" },
  { icon: "wb_iridescent" },
  { icon: "wb_sunny" },
  { icon: "wc" },
  { icon: "web" },
  { icon: "web_asset" },
  { icon: "weekend" },
  { icon: "whatshot" },
  { icon: "widgets" },
  { icon: "wifi" },
  { icon: "wifi_lock" },
  { icon: "wifi_tethering" },
  { icon: "work" },
  { icon: "wrap_text" },
  { icon: "youtube_searched_for" },
  { icon: "zoom_in" },
  { icon: "zoom_out" },
  { icon: "zoom_out_map" },
];

const ListIcons = ({ onChangeIcon, attentionId, setSelected, iconColor }) => {
  const [search, setSearch] = useState("");
  return (
    <Box>
      <Box style={{ color: "black", marginBottom: 12 }}>
        <OutlinedInput
          autoComplete="off"
          required
          inputProps={{ maxLength: 20, fontSize: 14 }}
          placeholder="Buscar..."
          value={search}
          onChange={(event) => setSearch(event.target.value)}
          style={{
            maxWidth: 550,
            borderRadius: "4px",
            background: "##E6E7F3",
            borderWidth: 0,
            height: 24,
          }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          id="outlined-basic"
          fullWidth
        />
      </Box>
      <Box
        className="select-icon"
        style={{
          zIndex: 9999,
          overflowX: "hidden",
          overflowY: "auto",
          // display: "flex",
          // justifyContent: "flex-start",
          // alignContent: "center",
          // flexWrap: "wrap",
          // flexDirection: "row",
          // alignItems: "flex-start",
        }}
      >
        {iconsData
          .filter((item) =>
            item.icon.toLocaleLowerCase().includes(search.toLocaleLowerCase())
          )
          .map((icon, index) => (
            <span
              id={`icon-${icon.icon}`}
              className="material-icons"
              style={{ fontSize: 24, color: iconColor, width: 24 }}
              onClick={() => {
                onChangeIcon(attentionId, icon.icon);
                setSelected(undefined);
              }}
            >
              {icon.icon}
            </span>
          ))}
      </Box>
    </Box>
  );
};

export default ListIcons;
