import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import LoadingButton from "@mui/lab/LoadingButton";

import InfoIcon from "@mui/icons-material/Info";

import { styled } from "@mui/material/styles";

import StepperVideolink from "./stepper";
import HeaderConfig from "./hedaerConfig";
import { OutlinedInput } from "@mui/material";

import userIcon from "../assets/user.svg";
import emailIcon from "../assets/email.svg";
import StoreMallDirectoryIcon from "../assets/empresa_grande.svg";
import BusinessIcon from "../assets/empresa_pequeño.svg";
import StoreMallDirectoryIcon2 from "../assets/empresa_grande2.svg";
import BusinessIcon2 from "../assets/empresa_pequeño2.svg";
import trash from "../assets/trash.svg";
import { REACT_URL } from "../config";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#17191E !important",
    width: "auto !important",
    color: "#FFFFFF !important",
    fontSize: 14,
    borderRadius: 5,
    boxShadow: "none",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#17191E !important",
  },
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 8,
  marginBottom: 12,
  "&:not(:last-child)": {
    // borderBottom: 0,
    boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.15)",
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  fontFamily: "Rubik",
  fontSize: 16,
  fontWeight: 600,
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  // padding: theme.spacing(2),
  padding: 0,
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function RolesForm({
  t,
  lang,
  setLang,
  isMobile,
  onBack,
  adminName,
  setAdminName,
  supervisorName,
  setSupervisorName,
  agentName,
  setAgentName,
  adminLastName,
  agentLastName,
  supervisorLastName,
  setAdminLastName,
  setAgentLastName,
  setSupervisorLastName,
  adminEmail,
  setAdminEmail,
  supervisorEmail,
  setSupervisorEmail,
  agentEmail,
  setAgentEmail,
  roles,
  addRol,
  deleteRol,
  isSmall,
  setIsSmall,
  isLoading,
  setOpen,
  user,
  showTour,
  height,
  width,
}) {
  const regexForName = /^[a-zA-Z\s\W]*$/;
  const regexForEmail =
    /^[A-Za-z0-9_]+(\.[_A-Za-z0-9_]+)*@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,15})$/;
  const activeStep = 2;
  return (
    <Box
      style={{
        maxWidth: !isMobile ? 750 : height >= 1180 ? 895 : 750,
        margin: "auto",
        background: isMobile && "#FFFFFF",
        paddingBottom: 8,
        paddingTop: isMobile && 16,
        borderTopLeftRadius: isMobile && 10,
        borderTopRightRadius: isMobile && 10,
        paddingLeft: height < 1180 && width > 750 && 60,
        paddingRight: height < 1180 && width > 750 && 60,
      }}
    >
      <HeaderConfig
        t={t}
        isMobile={isMobile}
        showTour={showTour}
        page={"roles"}
        height={height}
      />
      <StepperVideolink activeStep={activeStep} isMobile={isMobile} />
      {isMobile ? (
        <>
          {/* <Box
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 24,
              marginBottom: 8,
              padding: isMobile && 8,
              maxWidth: 702,
              marginLeft: "auto",
              marginRight: "auto",

              justifyContent: "center",
            }}
          >
            <Button
              onClick={() => setIsSmall(true)}
              disabled={localStorage.getItem("companyType") || isLoading}
              style={{
                width: "50%",
                fontSize: !isMobile ? 10 : height >= 1180 ? 24 : 10,
                fontFamily: "Rubik",
                textTransform: "inherit",
                marginRight: 8,
                backgroundColor: isSmall ? "#4A27ED" : "transparent",
                borderRadius: 10,
                color: isSmall ? "#FFFFFF" : "#17191E",
                fontWeight: 600,
              }}
              startIcon={
                <img
                  src={isSmall ? BusinessIcon2 : BusinessIcon}
                  style={{ width: !isMobile ? 24 : height >= 1180 ? 32 : 24 }}
                />
              }
            >
              {t("roles.small.companies")}
            </Button>
            <Box
              style={{
                fontSize: !isMobile ? 14 : height >= 1180 ? 27 : 14,
                fontFamily: "Rubik",
                color: "#000000",
              }}
            >
              O
            </Box>
            <Button
              onClick={() => setIsSmall(false)}
              disabled={localStorage.getItem("companyType") || isLoading}
              style={{
                width: "50%",
                marginLeft: 8,
                fontSize: !isMobile ? 10 : height >= 1180 ? 24 : 10,
                fontFamily: "Rubik",
                textTransform: "inherit",
                display: "flex",
                alignItems: "center",
                backgroundColor: !isSmall ? "#4A27ED" : "transparent",
                borderRadius: 10,
                color: !isSmall ? "#FFFFFF" : "#17191E",
                fontWeight: 600,
              }}
              startIcon={
                <img
                  src={
                    !isSmall ? StoreMallDirectoryIcon : StoreMallDirectoryIcon2
                  }
                  style={{ width: !isMobile ? 24 : height >= 1180 ? 32 : 24 }}
                />
              }
            >
              {t("roles.big.companies")}
            </Button>
          </Box> */}
          <Box
            style={{
              fontSize: !isMobile ? 12 : height >= 1180 ? 24 : 12,
              fontFamily: "Rubik",
              textAlign: "justify",
              marginBottom: "11.3px",
              paddingLeft: 25,
              paddingRight: 20,
              maxWidth: 702,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {t("roles.info")}
          </Box>
          <Box
            style={{
              boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: 8,
              paddingTop: 8,
              paddingBottom: 8,
              marginBottom: 18,
              maxWidth: 702,
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                paddingLeft: 27,
                paddingRight: 27,
              }}
            >
              <Box
                style={{
                  fontFamily: "Rubik",
                  fontSize: !isMobile ? 12 : height >= 1180 ? 24 : 12,
                  fontWeight: 600,
                  color: "#17191E",
                }}
              >
                {t("roles.superadmin")}
                {/* Super administrador */}
              </Box>
              <Box style={{ paddingTop: 6, paddingLeft: 10 }}>
                {/* <LightTooltip
                  title={t("attentions.type.tooltip")}
                  arrow
                  placement="right"
                >
                  <InfoIcon color="primary" style={{ width: 18, height: 18 }} />
                </LightTooltip> */}
              </Box>
            </Box>
            <Box
              style={{
                width: "100%",
                maxWidth: !isMobile ? 550 : height >= 1180 ? "none" : 700,
                marginTop: 17.5,
                marginBottom: !isMobile ? 19 : 19,
                paddingLeft: 8,
                paddingRight: 8,
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box style={{ margin: "auto", display: "flex" }}>
                  <img
                    src={userIcon}
                    alt=""
                    style={{
                      width: !isMobile ? 28 : height >= 1180 ? 42 : 28,
                      height: !isMobile ? 28 : height >= 1180 ? 42 : 28,
                    }}
                  />
                </Box>
                <Box
                  style={{
                    width: "45%",
                    // display: "flex",
                    paddingLeft: 8,
                    fontFamily: "Rubik",
                    fontSize: !isMobile ? 12 : height >= 1180 ? 24 : 12,
                    color: "#17191E",
                    whiteSpace: "nowrap",

                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {`${user?.firstname} ${user?.lastname}`}
                </Box>
                <Box style={{ margin: "auto", display: "flex" }}>
                  <img
                    src={emailIcon}
                    alt=""
                    style={{
                      width: !isMobile ? 28 : height >= 1180 ? 42 : 28,

                      height: !isMobile ? 28 : height >= 1180 ? 42 : 28,
                    }}
                  />
                </Box>
                <Box
                  style={{
                    width: "45%",
                    paddingLeft: 8,
                    // display: "flex",
                    fontFamily: "Rubik",
                    fontSize: !isMobile ? 12 : height >= 1180 ? 24 : 12,
                    color: "#17191E",
                    whiteSpace: "nowrap",

                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {`${user?.email}`}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            className="roles-container-step"
            style={{ maxWidth: 702, marginLeft: "auto", marginRight: "auto" }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 12,
                padding: isMobile && 8,
                paddingLeft: 29,
              }}
            >
              <Box
                style={{
                  fontFamily: "Rubik",
                  fontSize: !isMobile ? 20 : height >= 1180 ? 24 : 16,
                  fontWeight: 600,
                }}
              >
                Roles
              </Box>
              <Box style={{ paddingTop: 6, paddingLeft: 10 }}>
                <LightTooltip
                  title="Los roles son la función que se le asignará a un usuario en tu Contact Center."
                  arrow
                  placement="right"
                >
                  <InfoIcon color="primary" />
                </LightTooltip>
              </Box>
            </Box>
            <Accordion className="admin-step">
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <Box
                    style={{
                      fontFamily: "Rubik",
                      fontSize: !isMobile ? 12 : height >= 1180 ? 24 : 14,
                      fontWeight: 600,
                      color: "#17191E",
                    }}
                  >
                    {isSmall
                      ? t("roles.add.agent.admin")
                      : t("roles.add.admin")}
                  </Box>
                  <Box style={{ paddingTop: 6, paddingLeft: 10 }}></Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <Box
                    style={{
                      width: "100%",
                      maxWidth: !isMobile ? 550 : height >= 1180 ? "none" : 700,
                      marginTop: 17.5,
                      marginBottom: !isMobile ? 19 : 19,
                      paddingLeft: 27,
                      paddingRight: 27,
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box style={{ width: "10%" }}></Box>
                      <Box
                        style={{
                          fontFamily: "Rubik",
                          fontSize: !isMobile ? 12 : height >= 1180 ? 24 : 14,
                          fontWeight: "bold",
                          width: "45%",
                        }}
                      >
                        {t("registry.firstname")}
                      </Box>
                      <Box
                        style={{
                          fontFamily: "Rubik",
                          fontSize: !isMobile ? 12 : height >= 1180 ? 24 : 14,
                          fontWeight: "bold",
                          width: "45%",
                          paddingLeft: 8,
                        }}
                      >
                        {t("registry.lastname")}
                      </Box>
                    </Box>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Box style={{ width: "10%", textAlign: "center" }}>
                        <img
                          src={userIcon}
                          alt=""
                          style={{
                            width: !isMobile ? 24 : height >= 1180 ? 32 : 24,
                          }}
                        />
                      </Box>
                      <OutlinedInput
                        type="text"
                        disabled={isLoading}
                        value={adminName}
                        onChange={(event) => {
                          if (regexForName.test(event.target.value))
                            setAdminName(event.target.value);
                        }}
                        autoComplete="off"
                        required
                        inputProps={{ maxLength: 20 }}
                        size="small"
                        style={{
                          maxWidth: 700,
                          borderRadius: "4px",
                          background: "#E6E7F3",
                          borderWidth: 0,
                          fontSize: !isMobile ? 12 : height >= 1180 ? 24 : 14,
                          width: "45%",
                        }}
                        placeholder={t("registry.firstname")}
                        id="admin-name"
                        fullWidth
                      />
                      <OutlinedInput
                        type="text"
                        disabled={isLoading}
                        value={adminLastName}
                        onChange={(event) => {
                          if (regexForName.test(event.target.value))
                            setAdminLastName(event.target.value);
                        }}
                        autoComplete="off"
                        required
                        inputProps={{ maxLength: 20 }}
                        size="small"
                        style={{
                          maxWidth: 700,
                          borderRadius: "4px",
                          background: "#E6E7F3",
                          borderWidth: 0,
                          marginLeft: 8,
                          fontSize: !isMobile ? 12 : height >= 1180 ? 24 : 14,
                          width: "45%",
                        }}
                        placeholder={t("registry.lastname")}
                        id="admin-lastname"
                        fullWidth
                      />
                    </Box>

                    <Box
                      style={{
                        width: "100%",
                        // maxWidth: 550,
                        marginTop: 17.5,
                        marginBottom: !isMobile ? 19 : 19,
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box style={{ width: "10%" }}></Box>
                        <Box
                          style={{
                            fontFamily: "Rubik",
                            fontSize: 14,
                            fontWeight: 600,
                            fontSize: !isMobile ? 12 : height >= 1180 ? 24 : 14,
                          }}
                        >
                          {t("registry.email")}
                        </Box>
                      </Box>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <Box style={{ textAlign: "center", width: "10%" }}>
                          <img
                            src={emailIcon}
                            alt=""
                            style={{
                              width: !isMobile ? 24 : height >= 1180 ? 32 : 24,
                            }}
                          />
                        </Box>
                        <OutlinedInput
                          type="email"
                          disabled={isLoading}
                          value={adminEmail}
                          onChange={(event) =>
                            setAdminEmail(event.target.value)
                          }
                          autoComplete="off"
                          required
                          inputProps={{ maxLength: 100 }}
                          size="small"
                          style={{
                            width: "90%",
                            // maxWidth: 550,
                            borderRadius: "4px",
                            background: "#E6E7F3",
                            borderWidth: 0,
                            fontSize: !isMobile ? 12 : height >= 1180 ? 24 : 14,
                          }}
                          placeholder={t("registry.email")}
                          id="admin-email"
                          fullWidth
                        />
                      </Box>
                      {!regexForEmail.test(adminEmail) && adminEmail !== "" && (
                        <Box style={{ display: "flex", alignItems: "center" }}>
                          <Box
                            style={{ width: "10%", textAlign: "center" }}
                          ></Box>
                          <Box style={{ color: "#D41924", fontSize: 14 }}>
                            {t("registry.format.email")}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "100%",
                      paddingRight: 22,
                    }}
                  >
                    <Button
                      disabled={
                        !adminName ||
                        !adminEmail ||
                        !adminLastName ||
                        isLoading ||
                        !regexForEmail.test(adminEmail)
                      }
                      onClick={() => {
                        addRol({
                          name: adminName,
                          email: adminEmail,
                          lastName: adminLastName,
                          type: "admin",
                        });
                        setAdminEmail("");
                        setAdminName("");
                        setAdminLastName("");
                      }}
                      style={{
                        borderRadius: 20,
                        backgroundColor:
                          !adminName ||
                          !adminEmail ||
                          !adminLastName ||
                          isLoading ||
                          !regexForEmail.test(adminEmail)
                            ? "#D6D8E8"
                            : "#EA345F",
                        color: "#FFFFFF",
                        fontWeight: 600,
                        textTransform: "inherit",
                        width: 100,
                        fontFamily: "Rubik",
                      }}
                    >
                      {t("roles.add")}
                    </Button>
                  </Box>
                </Box>
                <Box
                  style={{
                    marginTop: 12,
                    backgroundColor: "rgba(242, 242, 242, 0.6)",
                  }}
                  className="listRoles-step"
                >
                  {roles
                    .filter((role) => role.type === "admin")
                    .map((item, index) => (
                      <Box
                        style={{
                          display: "flex",
                          paddingTop: 8,
                          paddingBottom: 8,
                          paddingLeft: 26,
                          paddingRight: 26,
                        }}
                        key={`admin-${index}`}
                      >
                        <Box
                          style={{
                            width: "47.5%",
                            fontSize: 14,
                            fontFamily: "Rubik",
                            margin: "auto",
                            whiteSpace: "nowrap",

                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {`${item.name} ${item.lastName}`}
                        </Box>
                        <Box
                          style={{
                            width: "47.5%",
                            background: "#E6E7F3",
                            borderRadius: 20,
                            color: "#17191E",
                            paddingLeft: 8,
                            paddingRight: 8,
                            fontSize: 14,
                            fontFamily: "Rubik",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            margin: "auto",
                          }}
                        >
                          {item.email}
                        </Box>
                        <Box
                          style={{ width: "5%", paddingLeft: 8 }}
                          onClick={() => deleteRol(item.id)}
                        >
                          <img src={trash} alt="" />
                        </Box>
                      </Box>
                    ))}
                </Box>
              </AccordionDetails>
            </Accordion>
            <Box className="ohters-roles">
              {!isSmall && (
                <Accordion>
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header1"
                  >
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Box
                        style={{
                          fontFamily: "Rubik",
                          fontSize: !isMobile ? 12 : height >= 1180 ? 24 : 14,
                          fontWeight: 600,
                          color: "#17191E",
                        }}
                      >
                        {`${t("roles.add.supervisor")} *${t(
                          "roles.option.string"
                        )}`}
                      </Box>
                      <Box style={{ paddingTop: 6, paddingLeft: 10 }}>
                        {/* <LightTooltip
                      title={t("attentions.type.tooltip")}
                      arrow
                      placement="right"
                    >
                      <InfoIcon
                        color="primary"
                        style={{ width: 18, height: 18 }}
                      />
                    </LightTooltip> */}
                      </Box>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",

                          padding: isMobile && 8,
                          paddingLeft: !isMobile && 24,
                        }}
                      ></Box>
                      <Box>
                        <Box
                          style={{
                            width: "100%",
                            maxWidth: !isMobile
                              ? 550
                              : height >= 1180
                              ? "none"
                              : 700,
                            // marginTop: 17.5,
                            marginBottom: !isMobile ? 19 : 19,
                            paddingLeft: 27,
                            paddingRight: 27,
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Box style={{ width: "10%" }}></Box>
                            <Box
                              style={{
                                fontFamily: "Rubik",
                                fontSize: !isMobile
                                  ? 12
                                  : height >= 1180
                                  ? 24
                                  : 14,
                                fontWeight: "bold",
                                width: "45%",
                              }}
                            >
                              {t("registry.firstname")}
                            </Box>
                            <Box
                              style={{
                                fontFamily: "Rubik",
                                fontSize: !isMobile
                                  ? 12
                                  : height >= 1180
                                  ? 24
                                  : 14,
                                fontWeight: "bold",
                                width: "45%",
                                paddingLeft: 8,
                              }}
                            >
                              {t("registry.lastname")}
                            </Box>
                          </Box>
                          <Box
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Box style={{ width: "10%", textAlign: "center" }}>
                              <img
                                src={userIcon}
                                alt=""
                                style={{
                                  width: !isMobile
                                    ? 26
                                    : height >= 1180
                                    ? 32
                                    : 26,
                                }}
                              />
                            </Box>
                            <OutlinedInput
                              type="text"
                              disabled={isLoading}
                              value={supervisorName}
                              onChange={(event) => {
                                if (regexForName.test(event.target.value))
                                  setSupervisorName(event.target.value);
                              }}
                              autoComplete="off"
                              required
                              inputProps={{ maxLength: 20 }}
                              size="small"
                              style={{
                                maxWidth: 550,
                                borderRadius: "4px",
                                background: "#E6E7F3",
                                borderWidth: 0,
                                // marginLeft: 8,
                                fontSize: !isMobile
                                  ? 12
                                  : height >= 1180
                                  ? 24
                                  : 14,
                                width: "45%",
                              }}
                              placeholder={t("registry.firstname")}
                              id="outlined-basic"
                              fullWidth
                            />
                            <OutlinedInput
                              type="text"
                              disabled={isLoading}
                              value={supervisorLastName}
                              onChange={(event) => {
                                if (regexForName.test(event.target.value))
                                  setSupervisorLastName(event.target.value);
                              }}
                              autoComplete="off"
                              required
                              inputProps={{ maxLength: 20 }}
                              size="small"
                              style={{
                                maxWidth: 550,
                                borderRadius: "4px",
                                background: "#E6E7F3",
                                borderWidth: 0,
                                marginLeft: 8,
                                fontSize: !isMobile
                                  ? 12
                                  : height >= 1180
                                  ? 24
                                  : 14,
                                width: "45%",
                              }}
                              placeholder={t("registry.lastname")}
                              id="outlined-basic"
                              fullWidth
                            />
                          </Box>
                        </Box>
                        <Box
                          style={{
                            width: "100%",
                            maxWidth: !isMobile
                              ? 550
                              : height >= 1180
                              ? "none"
                              : 700,
                            marginTop: 17.5,
                            marginBottom: !isMobile ? 19 : 19,
                            paddingLeft: 27,
                            paddingRight: 27,
                          }}
                        >
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Box style={{ width: "10%" }}></Box>
                            <Box
                              style={{
                                fontFamily: "Rubik",
                                fontSize: !isMobile
                                  ? 12
                                  : height >= 1180
                                  ? 24
                                  : 14,
                                fontWeight: 600,
                              }}
                            >
                              {t("registry.email")}
                            </Box>
                          </Box>
                          <Box
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Box style={{ textAlign: "center", width: "10%" }}>
                              <img
                                src={emailIcon}
                                alt=""
                                style={{
                                  width: !isMobile
                                    ? 26
                                    : height >= 1180
                                    ? 32
                                    : 26,
                                }}
                              />
                            </Box>
                            <OutlinedInput
                              type="email"
                              disabled={isLoading}
                              value={supervisorEmail}
                              onChange={(event) =>
                                setSupervisorEmail(event.target.value)
                              }
                              autoComplete="off"
                              required
                              inputProps={{ maxLength: 100 }}
                              size="small"
                              style={{
                                // maxWidth: 550,
                                width: "90%",
                                borderRadius: "4px",
                                background: "#E6E7F3",
                                borderWidth: 0,
                                fontSize: !isMobile
                                  ? 12
                                  : height >= 1180
                                  ? 24
                                  : 14,
                              }}
                              placeholder={t("registry.email")}
                              id="outlined-basic"
                              fullWidth
                            />
                          </Box>
                          {!regexForEmail.test(supervisorEmail) &&
                            supervisorEmail !== "" && (
                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Box
                                  style={{ width: "10%", textAlign: "center" }}
                                ></Box>
                                <Box style={{ color: "#D41924", fontSize: 14 }}>
                                  {t("registry.format.email")}
                                </Box>
                              </Box>
                            )}
                        </Box>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          width: "100%",
                          paddingRight: 22,
                        }}
                      >
                        <Button
                          disabled={
                            !supervisorName ||
                            !supervisorEmail ||
                            !supervisorLastName ||
                            !regexForEmail.test(supervisorEmail) ||
                            isLoading
                          }
                          onClick={() => {
                            addRol({
                              name: supervisorName,
                              email: supervisorEmail,
                              lastName: supervisorLastName,
                              type: "supervisor",
                            });
                            setSupervisorEmail("");
                            setSupervisorName("");
                            setSupervisorLastName("");
                          }}
                          style={{
                            borderRadius: 20,
                            backgroundColor:
                              !supervisorName ||
                              !supervisorEmail ||
                              !supervisorLastName ||
                              !regexForEmail.test(supervisorEmail) ||
                              isLoading
                                ? "#D6D8E8"
                                : "#EA345F",
                            color: "#FFFFFF",
                            fontWeight: 600,
                            textTransform: "inherit",
                            width: 100,
                            fontFamily: "Rubik",
                          }}
                        >
                          {t("roles.add")}
                        </Button>
                      </Box>
                      <Box
                        style={{
                          marginTop: 12,
                          backgroundColor: "rgba(242, 242, 242, 0.6)",
                        }}
                      >
                        {roles
                          .filter((role) => role.type === "supervisor")
                          .map((item, index) => (
                            <Box
                              style={{
                                display: "flex",
                                paddingBottom: 8,
                                paddingTop: 8,
                                paddingLeft: 26,
                                paddingRight: 26,
                              }}
                              key={`admin-${index}`}
                            >
                              <Box
                                style={{
                                  width: "47.5%",
                                  fontSize: 14,
                                  fontFamily: "Rubik",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  margin: "auto",

                                  textOverflow: "ellipsis",
                                }}
                              >
                                {`${item.name} ${item.lastName}`}
                              </Box>
                              <Box
                                style={{
                                  width: "47.5%",
                                  background: "#E6E7F3",
                                  borderRadius: 20,
                                  color: "#17191E",
                                  paddingLeft: 8,
                                  paddingRight: 8,
                                  fontSize: 14,
                                  fontFamily: "Rubik",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  margin: "auto",
                                }}
                              >
                                {item.email}
                              </Box>
                              <Box
                                disabled={isLoading}
                                style={{ width: "5%", paddingLeft: 8 }}
                                onClick={() => deleteRol(item.id)}
                              >
                                <img src={trash} alt="" />
                              </Box>
                            </Box>
                          ))}
                      </Box>
                    </Box>
                  </AccordionDetails>
                </Accordion>
              )}
              <Accordion>
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header2"
                >
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <Box
                      style={{
                        fontFamily: "Rubik",
                        fontSize: !isMobile ? 12 : height >= 1180 ? 24 : 14,
                        fontWeight: 600,
                        color: "#17191E",
                      }}
                    >
                      {`${t("roles.add.agent")} *${t("roles.option.string")}`}
                    </Box>
                    <Box style={{ paddingTop: 6, paddingLeft: 10 }}>
                      {/* <LightTooltip
                    title={t("attentions.type.tooltip")}
                    arrow
                    placement="right"
                  >
                    <InfoIcon
                      color="primary"
                      style={{ width: 18, height: 18 }}
                    />
                  </LightTooltip> */}
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <Box>
                      <Box
                        style={{
                          width: "100%",
                          maxWidth: !isMobile
                            ? 550
                            : height >= 1180
                            ? "none"
                            : 700,
                          marginTop: 17.5,
                          marginBottom: !isMobile ? 19 : 19,
                          paddingLeft: 27,
                          paddingRight: 27,
                        }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Box style={{ width: "10%" }}></Box>
                          <Box
                            style={{
                              fontFamily: "Rubik",
                              fontSize: !isMobile
                                ? 12
                                : height >= 1180
                                ? 24
                                : 14,
                              fontWeight: "bold",
                              width: "45%",
                            }}
                          >
                            {t("registry.firstname")}
                          </Box>
                          <Box
                            style={{
                              fontFamily: "Rubik",
                              fontSize: !isMobile
                                ? 12
                                : height >= 1180
                                ? 24
                                : 14,
                              fontWeight: "bold",
                              width: "45%",
                              paddingLeft: 8,
                            }}
                          >
                            {t("registry.lastname")}
                          </Box>
                        </Box>
                        <Box style={{ display: "flex", alignItems: "center" }}>
                          <Box style={{ width: "10%", textAlign: "center" }}>
                            <img
                              src={userIcon}
                              alt=""
                              style={{
                                width: !isMobile
                                  ? 26
                                  : height >= 1180
                                  ? 32
                                  : 26,
                              }}
                            />
                          </Box>
                          <OutlinedInput
                            type="text"
                            disabled={isLoading}
                            value={agentName}
                            onChange={(event) => {
                              if (regexForName.test(event.target.value))
                                setAgentName(event.target.value);
                            }}
                            autoComplete="off"
                            required
                            inputProps={{ maxLength: 20 }}
                            size="small"
                            style={{
                              maxWidth: 550,
                              borderRadius: "4px",
                              background: "#E6E7F3",
                              borderWidth: 0,
                              fontSize: !isMobile
                                ? 12
                                : height >= 1180
                                ? 24
                                : 14,
                              width: "45%",
                            }}
                            placeholder={t("registry.firstname")}
                            id="outlined-basic"
                            fullWidth
                          />
                          <OutlinedInput
                            type="text"
                            disbaled={isLoading}
                            value={agentLastName}
                            onChange={(event) =>
                              setAgentLastName(event.target.value)
                            }
                            autoComplete="off"
                            required
                            inputProps={{ maxLength: 20 }}
                            size="small"
                            style={{
                              maxWidth: 550,
                              borderRadius: "4px",
                              background: "#E6E7F3",
                              borderWidth: 0,
                              marginLeft: 8,
                              fontSize: !isMobile
                                ? 12
                                : height >= 1180
                                ? 24
                                : 14,
                              width: "45%",
                            }}
                            placeholder={t("registry.lastname")}
                            id="outlined-basic"
                            fullWidth
                          />
                        </Box>
                      </Box>
                      <Box
                        style={{
                          width: "100%",
                          maxWidth: !isMobile
                            ? 550
                            : height >= 1180
                            ? "none"
                            : 700,
                          marginTop: 17.5,
                          marginBottom: !isMobile ? 19 : 19,
                          paddingLeft: 27,
                          paddingRight: 27,
                        }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Box style={{ width: "10%" }}></Box>
                          <Box
                            style={{
                              fontFamily: "Rubik",
                              fontSize: !isMobile
                                ? 12
                                : height >= 1180
                                ? 24
                                : 14,
                              fontWeight: 600,
                            }}
                          >
                            {t("registry.email")}
                          </Box>
                        </Box>
                        <Box style={{ display: "flex", alignItems: "center" }}>
                          <Box style={{ textAlign: "center", width: "10%" }}>
                            <img
                              src={emailIcon}
                              alt=""
                              style={{
                                width: !isMobile
                                  ? 26
                                  : height >= 1180
                                  ? 32
                                  : 26,
                              }}
                            />
                          </Box>
                          <OutlinedInput
                            disabled={isLoading}
                            value={agentEmail}
                            onChange={(event) => {
                              if (regexForName.test(event.target.value))
                                setAgentEmail(event.target.value);
                            }}
                            autoComplete="off"
                            required
                            inputProps={{ maxLength: 100 }}
                            size="small"
                            style={{
                              width: "90%",
                              // maxWidth: 550,
                              borderRadius: "4px",
                              background: "#E6E7F3",
                              borderWidth: 0,
                              fontSize: !isMobile
                                ? 12
                                : height >= 1180
                                ? 24
                                : 14,
                            }}
                            placeholder={t("registry.email")}
                            id="outlined-basic"
                            fullWidth
                          />
                        </Box>
                        {!regexForEmail.test(agentEmail) &&
                          agentEmail !== "" && (
                            <Box
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Box
                                style={{ width: "10%", textAlign: "center" }}
                              ></Box>
                              <Box style={{ color: "#D41924", fontSize: 14 }}>
                                {t("registry.format.email")}
                              </Box>
                            </Box>
                          )}
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        width: "100%",
                        paddingRight: 22,
                      }}
                    >
                      <Button
                        disabled={
                          !agentName ||
                          !agentEmail ||
                          !agentLastName ||
                          !regexForEmail.test(agentEmail) ||
                          isLoading
                        }
                        onClick={() => {
                          addRol({
                            name: agentName,
                            email: agentEmail,
                            lastName: agentLastName,
                            type: "agent",
                          });
                          setAgentEmail("");
                          setAgentName("");
                          setAgentLastName("");
                        }}
                        style={{
                          borderRadius: 20,
                          backgroundColor:
                            !agentName ||
                            !agentEmail ||
                            !agentLastName ||
                            isLoading ||
                            !regexForEmail.test(agentEmail)
                              ? "#D6D8E8"
                              : "#EA345F",
                          color: "#FFFFFF",
                          fontWeight: 600,
                          textTransform: "inherit",
                          width: 100,
                          fontFamily: "Rubik",
                        }}
                      >
                        {t("roles.add")}
                      </Button>
                    </Box>
                    <Box
                      style={{
                        marginTop: 12,
                        backgroundColor: "rgba(242, 242, 242, 0.6)",
                      }}
                    >
                      {roles
                        .filter((role) => role.type === "agent")
                        .map((item, index) => (
                          <Box
                            style={{
                              display: "flex",
                              paddingBottom: 8,
                              paddingTop: 8,
                              paddingLeft: 26,
                              paddingRight: 26,
                            }}
                            key={`admin-${index}`}
                          >
                            <Box
                              style={{
                                width: "47.5%",
                                fontSize: 14,
                                fontFamily: "Rubik",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                margin: "auto",
                              }}
                            >
                              {`${item.name} ${item.lastName}`}
                            </Box>
                            <Box
                              style={{
                                width: "47.5%",
                                background: "#E6E7F3",
                                borderRadius: 20,
                                color: "#17191E",
                                paddingLeft: 8,
                                paddingRight: 8,
                                fontSize: 14,
                                fontFamily: "Rubik",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                margin: "auto",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item.email}
                            </Box>
                            <Box
                              style={{ width: "5%", paddingLeft: 8 }}
                              onClick={() => !isLoading && deleteRol(item.id)}
                            >
                              <img src={trash} alt="" />
                            </Box>
                          </Box>
                        ))}
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>

          <Box
            style={{ maxWidth: 702, marginLeft: "auto", marginRight: "auto" }}
          >
            <LoadingButton
              className="btn-accept-step"
              loadingPosition="start"
              loading={isLoading}
              onClick={() => setOpen(true)}
              // onClick={() => onCreateUsers()}
              fullWidth
              style={{
                textTransform: "inherit",
                borderRadius: !isMobile ? 32 : height >= 1180 ? 60 : 32,
                fontSize: !isMobile ? 12 : height >= 1180 ? 24 : 14,
                fontWeight: 700,
                boxShadow: "none",
                height: !isMobile ? 32 : height >= 1180 ? 60 : 32,
                marginTop: 22,
                color: "#FFFFFF",
                backgroundColor:
                  !isDisabled() || isLoading ? "#AFB8CD" : "#EB3E67",
                marginBottom: 8,
              }}
              variant="contained"
              disabled={!isDisabled() || isLoading}
              size="small"
            >
              {t("button.finish")}
            </LoadingButton>
            <Button
              disabled={isLoading}
              onClick={() => onBack()}
              fullWidth
              style={{
                textTransform: "inherit",
                borderRadius: !isMobile ? 32 : height >= 1180 ? 60 : 32,
                fontSize: !isMobile ? 12 : height >= 1180 ? 24 : 14,
                fontWeight: 700,
                boxShadow: "none",
                backgroundColor: "#FFFFFF",
                color: "#52596B",
                border: "1px solid #52596B",
                height: !isMobile ? 32 : height >= 1180 ? 60 : 32,
                marginBottom: 8,
                fontFamily: "Rubik",
              }}
              variant="contained"
            >
              Volver
            </Button>
          </Box>
        </>
      ) : (
        <>
          {/* <Box
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 24,
              padding: isMobile && 8,
              justifyContent: "center",
            }}
          >
            <Button
              id="setSmall"
              onClick={() => setIsSmall(true)}
              disabled={localStorage.getItem("companyType") || isLoading}
              style={{
                width: "40%",
                fontSize: 14,
                fontFamily: "Rubik",
                textTransform: "inherit",
                marginRight: 8,
                backgroundColor: isSmall ? "#4A27ED" : "transparent",
                borderRadius: 10,
                color: isSmall ? "#FFFFFF" : "#17191E",
                fontWeight: 600,
              }}
              startIcon={
                <img
                  src={isSmall ? BusinessIcon2 : BusinessIcon}
                  style={{ width: 24 }}
                />
              }
            >
              {t("roles.small.companies")}
            </Button>
            <Box
              style={{ fontSize: 14, fontFamily: "Rubik", color: "#000000" }}
            >
              O
            </Box>
            <Button
              disabled={localStorage.getItem("companyType") || isLoading}
              onClick={() => setIsSmall(false)}
              id="setSmall-1"
              style={{
                width: "40%",
                marginLeft: 8,
                fontSize: 14,
                fontFamily: "Rubik",
                textTransform: "inherit",
                display: "flex",
                alignItems: "center",
                backgroundColor: !isSmall ? "#4A27ED" : "transparent",
                borderRadius: 10,
                color: !isSmall ? "#FFFFFF" : "#17191E",
                fontWeight: 600,
              }}
              startIcon={
                <img
                  src={
                    !isSmall ? StoreMallDirectoryIcon : StoreMallDirectoryIcon2
                  }
                  style={{ width: 24 }}
                />
              }
            >
              {t("roles.big.companies")}
            </Button>
          </Box>
          <Box
            style={{
              fontFamily: "Rubik",
              marginTop: 25,
              paddingLeft: !isMobile && 24,
              textAlign: "justify",
              fontSize: 14,
            }}
          >
            {t("roles.info")}
          </Box>
          <Box
            style={{
              backgroundColor: "#FBFBFF",
              border: "1px solid #AFB8CD",
              width: "100%",
              padding: 8,
              marginTop: 10,
              marginBottom: 38.57,
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",

                padding: isMobile && 8,
                paddingLeft: !isMobile && 24,
              }}
            >
              <Box
                style={{
                  fontFamily: "Rubik",
                  fontSize: !isMobile ? 12 : 14,
                  fontWeight: 600,
                  color: "#17191E",
                }}
              >
                {t("roles.superadmin")}
              </Box>
              <Box style={{ paddingTop: 6, paddingLeft: 10 }}></Box>
            </Box>
            <Box style={{ display: "flex", paddingLeft: 16 }}>
              <Box
                style={{
                  width: "100%",
                  // maxWidth: 550,
                  marginTop: 17.5,
                  marginBottom: !isMobile ? 19 : 19,
                  paddingLeft: 8,
                  paddingRight: 8,
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <img
                      src={userIcon}
                      alt=""
                      style={{ width: 28, height: 26 }}
                    />
                  </Box>
                  <Box
                    style={{
                      width: "45%",
                      display: "flex",
                      paddingLeft: 27,
                      fontFamily: "Rubik",
                      fontSize: 14,
                      color: "#17191E",
                    }}
                  >
                    {`${user?.firstname} ${user?.lastname}`}
                  </Box>
                  <Box
                    style={{
                      textAlign: "center",
                      // display: "flex",
                      // alignItems: "center",
                    }}
                  >
                    <img
                      src={emailIcon}
                      alt=""
                      style={{ width: 28, height: 26 }}
                    />
                  </Box>
                  <Box
                    style={{
                      width: "45%",
                      paddingLeft: 27,
                      display: "flex",
                      fontFamily: "Rubik",
                      fontSize: 14,
                      color: "#17191E",
                    }}
                  >
                    {`${user?.email}`}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box> */}
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 12,
              padding: isMobile && 8,
              paddingLeft: !isMobile && 42,
            }}
          >
            <Box
              style={{
                fontFamily: "Rubik",
                fontSize: !isMobile ? 20 : 16,
                fontWeight: 600,
              }}
            >
              Roles
            </Box>
            <Box style={{ paddingTop: 6, paddingLeft: 10 }}>
              <LightTooltip title={t("roles.tooltip")} arrow placement="right">
                <InfoIcon color="primary" />
              </LightTooltip>
            </Box>
          </Box>
          <Box
            style={{
              backgroundColor: "#FBFBFF",
              border: "1px solid #AFB8CD",
              width: "100%",
              padding: 8,
            }}
            className="roles-container-step"
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",

                padding: isMobile && 8,
                paddingLeft: !isMobile && 24,
              }}
            >
              <Box
                style={{
                  fontFamily: "Rubik",
                  fontSize: !isMobile ? 12 : 14,
                  fontWeight: 600,
                  color: "#17191E",
                }}
              >
                {isSmall ? t("roles.add.agent.admin") : t("roles.add.admin")}
              </Box>
              <Box style={{ paddingTop: 6, paddingLeft: 10 }}></Box>
            </Box>
            <Box className="admin-step">
              <Box>
                <Box style={{ display: "flex" }}>
                  <Box
                    style={{
                      width: "100%",
                      // maxWidth: 550,
                      marginTop: 17.5,
                      marginBottom: !isMobile ? 19 : 19,
                      paddingLeft: 8,
                      paddingRight: 8,
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box style={{ width: "10%" }}></Box>
                      <Box
                        style={{
                          fontFamily: "Rubik",
                          fontSize: 14,
                          fontWeight: "bold",
                          width: "45%",

                          display: "flex",
                        }}
                      >
                        {t("registry.firstname")}
                        {/* Nombre */}
                      </Box>
                      <Box
                        style={{
                          fontFamily: "Rubik",
                          fontSize: 14,
                          fontWeight: "bold",
                          width: "45%",
                          paddingLeft: 12,
                          display: "flex",
                        }}
                      >
                        {t("registry.lastname")}
                      </Box>
                    </Box>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Box style={{ width: "10%", textAlign: "center" }}>
                        <img
                          src={userIcon}
                          alt=""
                          style={{ width: 28, height: 26 }}
                        />
                      </Box>
                      <Box style={{ width: "45%" }}>
                        <OutlinedInput
                          type="text"
                          disabled={isLoading}
                          value={adminName}
                          onChange={(event) =>
                            regexForName.test(event.target.value) &&
                            setAdminName(event.target.value)
                          }
                          autoComplete="off"
                          required
                          inputProps={{ maxLength: 20 }}
                          size="small"
                          style={{
                            maxWidth: 550,
                            borderRadius: "4px",
                            background: "#E6E7F3",
                            borderWidth: 0,
                            fontWeight: 500,
                            height: 27,
                          }}
                          placeholder={t("registry.firstname")}
                          id="admin-name"
                          fullWidth
                        />
                      </Box>
                      <Box style={{ width: "45%", paddingLeft: 8 }}>
                        <OutlinedInput
                          type="text"
                          disabled={isLoading}
                          value={adminLastName}
                          onChange={(event) =>
                            regexForName.test(event.target.value) &&
                            setAdminLastName(event.target.value)
                          }
                          autoComplete="off"
                          required
                          inputProps={{ maxLength: 20 }}
                          size="small"
                          style={{
                            maxWidth: 550,
                            borderRadius: "4px",
                            background: "#E6E7F3",
                            borderWidth: 0,
                            fontWeight: 500,
                            height: 27,
                          }}
                          placeholder={t("registry.lastname")}
                          id="admin-lastname"
                          fullWidth
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  style={{
                    width: "100%",
                    // maxWidth: 550,
                    marginTop: 17.5,
                    marginBottom: !isMobile ? 19 : 19,
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box style={{ width: "10%" }}></Box>
                    <Box
                      style={{
                        fontFamily: "Rubik",
                        fontSize: 14,
                        fontWeight: 600,
                      }}
                    >
                      {t("registry.email")}
                    </Box>
                  </Box>
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <Box style={{ width: "10%", textAlign: "center" }}>
                      <img
                        src={emailIcon}
                        alt=""
                        style={{ width: 28, height: 26 }}
                      />
                    </Box>
                    <OutlinedInput
                      type="email"
                      disabled={isLoading}
                      value={adminEmail}
                      onChange={(event) => setAdminEmail(event.target.value)}
                      autoComplete="off"
                      required
                      inputProps={{ maxLength: 100 }}
                      size="small"
                      style={{
                        // maxWidth: 550,
                        borderRadius: "4px",
                        background: "#E6E7F3",
                        borderWidth: 0,
                        fontWeight: 500,
                        height: 27,
                      }}
                      placeholder={t("registry.email")}
                      id="admin-email"
                      fullWidth
                    />
                  </Box>
                  {!regexForEmail.test(adminEmail) && adminEmail !== "" && (
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Box style={{ width: "10%", textAlign: "center" }}></Box>
                      <Box style={{ color: "#D41924", fontSize: 14 }}>
                        Debe ingresar un correo válido
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>

              <Box
                style={{
                  display: "flex",
                  justifyContent: "end",
                  width: "100%",
                }}
              >
                <Button
                  id="admin-add-role"
                  disabled={
                    !adminName ||
                    !adminEmail ||
                    !adminLastName ||
                    !regexForEmail.test(adminEmail) ||
                    isLoading
                  }
                  onClick={() => {
                    addRol({
                      name: adminName,
                      email: adminEmail,
                      lastName: adminLastName,
                      type: "admin",
                    });
                    setAdminEmail("");
                    setAdminName("");
                    setAdminLastName("");
                  }}
                  style={{
                    borderRadius: 20,
                    backgroundColor:
                      !adminName ||
                      !adminEmail ||
                      !adminLastName ||
                      isLoading ||
                      !regexForEmail.test(adminEmail)
                        ? "#D6D8E8"
                        : "#EA345F",
                    color: "#FFFFFF",
                    fontWeight: 600,
                    textTransform: "inherit",
                    width: 100,
                    fontFamily: "Rubik",
                  }}
                >
                  {t("roles.add")}
                </Button>
              </Box>
              <Box>
                <hr
                  style={{
                    height: 1,
                    backgroundColor: "#AFB8CD",
                    border: "none",
                  }}
                />
              </Box>
            </Box>
            <Box className="ohters-roles">
              {!isSmall && (
                <Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",

                      padding: isMobile && 8,
                      paddingLeft: !isMobile && 24,
                    }}
                  >
                    <Box
                      style={{
                        fontFamily: "Rubik",
                        fontSize: !isMobile ? 12 : 14,
                        fontWeight: 600,
                        color: "#17191E",
                      }}
                    >
                      {`${t("roles.add.supervisor")} *${t(
                        "roles.option.string"
                      )}`}
                    </Box>
                    <Box style={{ paddingTop: 6, paddingLeft: 10 }}>
                      {/* <LightTooltip
                      title={t("attentions.type.tooltip")}
                      arrow
                      placement="right"
                    >
                      <InfoIcon
                        color="primary"
                        style={{ width: 18, height: 18 }}
                      />
                    </LightTooltip> */}
                    </Box>
                  </Box>
                  <Box style={{ display: "flex" }}>
                    <Box
                      style={{
                        width: "100%",
                        // maxWidth: 550,
                        marginTop: 17.5,
                        marginBottom: !isMobile ? 19 : 19,
                        paddingLeft: 8,
                        paddingRight: 8,
                      }}
                    >
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box style={{ width: "10%" }}></Box>
                        <Box
                          style={{
                            fontFamily: "Rubik",
                            fontSize: 14,
                            fontWeight: "bold",
                            width: "45%",
                            display: "flex",
                          }}
                        >
                          {t("registry.firstname")}

                          {/* Nombre */}
                        </Box>
                        <Box
                          style={{
                            fontFamily: "Rubik",
                            fontSize: 14,
                            fontWeight: "bold",
                            width: "45%",
                            paddingLeft: 12,
                            display: "flex",
                          }}
                        >
                          {t("registry.lastname")}
                        </Box>
                      </Box>
                      <Box style={{ display: "flex", alignItems: "center" }}>
                        <Box style={{ width: "10%", textAlign: "center" }}>
                          <img
                            src={userIcon}
                            alt=""
                            style={{ width: 28, height: 26 }}
                          />
                        </Box>
                        <Box style={{ width: "45%" }}>
                          <OutlinedInput
                            type="text"
                            disabled={isLoading}
                            value={supervisorName}
                            onChange={(event) =>
                              regexForName.test(event.target.value) &&
                              setSupervisorName(event.target.value)
                            }
                            autoComplete="off"
                            required
                            inputProps={{ maxLength: 20 }}
                            size="small"
                            style={{
                              maxWidth: 550,
                              borderRadius: "4px",
                              background: "#E6E7F3",
                              borderWidth: 0,
                              fontWeight: 500,
                              height: 27,
                            }}
                            placeholder={t("registry.firstname")}
                            id="supervisor-name"
                            fullWidth
                          />
                        </Box>
                        <Box style={{ width: "45%", paddingLeft: 8 }}>
                          <OutlinedInput
                            type="text"
                            disabled={isLoading}
                            value={supervisorLastName}
                            onChange={(event) =>
                              regexForName.test(event.target.value) &&
                              setSupervisorLastName(event.target.value)
                            }
                            autoComplete="off"
                            required
                            inputProps={{ maxLength: 20 }}
                            size="small"
                            style={{
                              maxWidth: 550,
                              borderRadius: "4px",
                              background: "#E6E7F3",
                              borderWidth: 0,
                              fontWeight: 500,
                              height: 27,
                            }}
                            placeholder={t("registry.lastname")}
                            id="supervisor-lastname"
                            fullWidth
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    style={{
                      width: "100%",
                      // maxWidth: 550,
                      marginTop: 17.5,
                      marginBottom: !isMobile ? 19 : 19,
                      paddingLeft: 8,
                      paddingRight: 8,
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box style={{ width: "10%" }}></Box>
                      <Box
                        style={{
                          fontFamily: "Rubik",
                          fontSize: 14,
                          fontWeight: 600,
                        }}
                      >
                        {t("registry.email")}
                      </Box>
                    </Box>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Box style={{ width: "10%", textAlign: "center" }}>
                        <img
                          src={emailIcon}
                          alt=""
                          style={{ width: 28, height: 26 }}
                        />
                      </Box>
                      <OutlinedInput
                        type="email"
                        disabled={isLoading}
                        value={supervisorEmail}
                        onChange={(event) =>
                          setSupervisorEmail(event.target.value)
                        }
                        autoComplete="off"
                        required
                        inputProps={{ maxLength: 100 }}
                        size="small"
                        style={{
                          // maxWidth: 550,
                          borderRadius: "4px",
                          background: "#E6E7F3",
                          borderWidth: 0,
                          fontWeight: 500,
                          height: 27,
                        }}
                        placeholder={t("registry.email")}
                        id="supervisor-lastname"
                        fullWidth
                      />
                    </Box>
                    {!regexForEmail.test(supervisorEmail) &&
                      supervisorEmail !== "" && (
                        <Box style={{ display: "flex", alignItems: "center" }}>
                          <Box
                            style={{ width: "10%", textAlign: "center" }}
                          ></Box>
                          <Box style={{ color: "#D41924", fontSize: 14 }}>
                            Debe ingresar un correo válido
                          </Box>
                        </Box>
                      )}
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      width: "100%",
                    }}
                  >
                    <Button
                      id="supervisor-add-role"
                      disabled={
                        !supervisorName ||
                        !supervisorEmail ||
                        !supervisorLastName ||
                        !regexForEmail.test(supervisorEmail) ||
                        isLoading
                      }
                      onClick={() => {
                        addRol({
                          name: supervisorName,
                          email: supervisorEmail,
                          lastName: supervisorLastName,
                          type: "supervisor",
                        });
                        setSupervisorEmail("");
                        setSupervisorName("");
                        setSupervisorLastName("");
                      }}
                      style={{
                        borderRadius: 20,
                        backgroundColor:
                          !supervisorName ||
                          !supervisorEmail ||
                          !supervisorLastName ||
                          !regexForEmail.test(supervisorEmail) ||
                          isLoading
                            ? "#D6D8E8"
                            : "#EA345F",
                        color: "#FFFFFF",
                        fontWeight: 600,
                        textTransform: "inherit",
                        width: 100,
                        fontFamily: "Rubik",
                      }}
                    >
                      {t("roles.add")}
                    </Button>
                  </Box>
                  <Box>
                    <hr
                      style={{
                        height: 1,
                        backgroundColor: "#AFB8CD",
                        border: "none",
                      }}
                    />
                  </Box>
                </Box>
              )}

              <Box>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",

                    padding: isMobile && 8,
                    paddingLeft: !isMobile && 24,
                  }}
                >
                  <Box
                    style={{
                      fontFamily: "Rubik",
                      fontSize: !isMobile ? 12 : 14,
                      fontWeight: 600,
                      color: "#17191E",
                    }}
                  >
                    {`${t("roles.add.agent")} *${t("roles.option.string")}`}
                  </Box>
                  <Box style={{ paddingTop: 6, paddingLeft: 10 }}>
                    {/* <LightTooltip
                    title={t("attentions.type.tooltip")}
                    arrow
                    placement="right"
                  >
                    <InfoIcon
                      color="primary"
                      style={{ width: 18, height: 18 }}
                    />
                  </LightTooltip> */}
                  </Box>
                </Box>
                <Box style={{ display: "flex" }}>
                  <Box
                    style={{
                      width: "100%",
                      // maxWidth: 550,
                      marginTop: 17.5,
                      marginBottom: !isMobile ? 19 : 19,
                      paddingLeft: 8,
                      paddingRight: 8,
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box style={{ width: "10%" }}></Box>
                      <Box
                        style={{
                          fontFamily: "Rubik",
                          fontSize: 14,
                          fontWeight: "bold",
                          width: "45%",
                          display: "flex",
                        }}
                      >
                        {t("registry.firstname")}

                        {/* Nombre */}
                      </Box>
                      <Box
                        style={{
                          fontFamily: "Rubik",
                          fontSize: 14,
                          fontWeight: "bold",
                          width: "45%",
                          paddingLeft: 12,
                          display: "flex",
                        }}
                      >
                        {t("registry.lastname")}
                        {/* Apellido */}
                      </Box>
                    </Box>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Box style={{ width: "10%", textAlign: "center" }}>
                        <img
                          src={userIcon}
                          alt=""
                          style={{ width: 28, height: 26 }}
                        />
                      </Box>
                      <Box style={{ width: "45%" }}>
                        <OutlinedInput
                          type="text"
                          disabled={isLoading}
                          value={agentName}
                          onChange={(event) =>
                            regexForName.test(event.target.value) &&
                            setAgentName(event.target.value)
                          }
                          autoComplete="off"
                          required
                          inputProps={{ maxLength: 20 }}
                          size="small"
                          style={{
                            maxWidth: 550,
                            borderRadius: "4px",
                            background: "#E6E7F3",
                            borderWidth: 0,
                            fontWeight: 500,
                            height: 27,
                          }}
                          placeholder={t("registry.firstname")}
                          id="agent-name"
                          fullWidth
                        />
                      </Box>
                      <Box style={{ width: "45%", paddingLeft: 8 }}>
                        <OutlinedInput
                          type="text"
                          disabled={isLoading}
                          value={agentLastName}
                          onChange={(event) =>
                            regexForName.test(event.target.value) &&
                            setAgentLastName(event.target.value)
                          }
                          autoComplete="off"
                          required
                          inputProps={{ maxLength: 20 }}
                          size="small"
                          style={{
                            maxWidth: 550,
                            borderRadius: "4px",
                            background: "#E6E7F3",
                            borderWidth: 0,
                            fontWeight: 500,
                            height: 27,
                          }}
                          placeholder={t("registry.lastname")}
                          id="agent-lastname"
                          fullWidth
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  style={{
                    width: "100%",
                    // maxWidth: 550,
                    marginTop: 17.5,
                    marginBottom: !isMobile ? 19 : 19,
                    paddingLeft: 8,
                    paddingRight: 8,
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box style={{ width: "10%" }}></Box>
                    <Box
                      style={{
                        fontFamily: "Rubik",
                        fontSize: 14,
                        fontWeight: 600,
                      }}
                    >
                      {t("registry.email")}
                    </Box>
                  </Box>
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <Box style={{ width: "10%", textAlign: "center" }}>
                      <img
                        src={emailIcon}
                        alt=""
                        style={{ width: 28, height: 26 }}
                      />
                    </Box>
                    <OutlinedInput
                      type="email "
                      disabled={isLoading}
                      value={agentEmail}
                      onChange={(event) => setAgentEmail(event.target.value)}
                      autoComplete="off"
                      required
                      inputProps={{ maxLength: 100 }}
                      size="small"
                      style={{
                        // maxWidth: 550,
                        borderRadius: "4px",
                        background: "#E6E7F3",
                        borderWidth: 0,
                        fontWeight: 500,
                        height: 27,
                      }}
                      placeholder={t("registry.email")}
                      id="agent-email"
                      fullWidth
                    />
                  </Box>
                  {!regexForEmail.test(agentEmail) && agentEmail !== "" && (
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Box style={{ width: "10%", textAlign: "center" }}></Box>
                      <Box style={{ color: "#D41924", fontSize: 14 }}>
                        Debe ingresar un correo válido
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>

              <Box
                style={{
                  display: "flex",
                  justifyContent: "end",
                  width: "100%",
                }}
              >
                <Button
                  id="add-agent-rol"
                  disabled={
                    !agentName || !agentEmail || !agentLastName || isLoading
                  }
                  onClick={() => {
                    addRol({
                      name: agentName,
                      email: agentEmail,
                      lastName: agentLastName,
                      type: "agent",
                    });
                    setAgentEmail("");
                    setAgentName("");
                    setAgentLastName("");
                  }}
                  style={{
                    borderRadius: 20,
                    backgroundColor:
                      !agentName ||
                      !agentEmail ||
                      !regexForEmail.test(agentEmail) ||
                      !agentLastName ||
                      isLoading
                        ? "#D6D8E8"
                        : "#EA345F",
                    color: "#FFFFFF",
                    fontWeight: 600,
                    textTransform: "inherit",
                    width: 100,
                    fontFamily: "Rubik",
                  }}
                >
                  {t("roles.add")}
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            style={{ display: "flex", justifyContent: "end", marginTop: 24 }}
          >
            <Button
              disabled={isLoading}
              onClick={() => onBack()}
              style={{
                textTransform: "inherit",
                borderRadius: 20,
                fontSize: 14,
                fontWeight: 700,
                boxShadow: "none",
                width: "33%",
                marginRight: 12,
                backgroundColor: "#FFFFFF",
                color: "#52596B",
                border: "1px solid #52596B",
                height: 32,
                ntFamily: "Rubik",
              }}
              id="btn-back"
              variant="contained"
            >
              {t("registry.back")}
            </Button>
            <LoadingButton
              loadingPosition="start"
              loading={isLoading}
              onClick={() => setOpen(true)}
              // onClick={() => onCreateUsers()}
              disabled={!isDisabled() || isLoading}
              style={{
                textTransform: "inherit",
                borderRadius: 20,
                fontSize: 14,
                fontWeight: 700,
                boxShadow: "none",
                width: "33%",
                height: 32,
                color: "#FFFFFF",
                backgroundColor:
                  !isDisabled() || isLoading ? "#D6D8E8" : "#EA345F",
                Family: "Rubik",
              }}
              variant="contained"
              size="small"
              className="btn-accept-step"
              id="btn-accept"
            >
              {t("button.finish")}
            </LoadingButton>
          </Box>
        </>
      )}
    </Box>
  );

  function isDisabled() {
    if (isSmall) {
      return true;
    } else {
      return roles.find((role) => role.type === "admin");
    }
  }
}

export default RolesForm;
