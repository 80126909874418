import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";

import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

import passwordIcon from "../assets/contraseña.svg";
import CustomRadio from "./customRadio";
import HeaderConfig from "./hedaerConfig";

function ChangePasswordForm({
  t,
  isMobile,
  isConfirmAccount,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  isChecked,
  setIsChecked,
  onConfirmAccount,
  isLoading,
}) {
  const [openTooltip, setOpenTooltip] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isShowConfirmPassword, setIsShowConfirmPassword] = useState(false);
  const [validatePasswordForOneLetter, setValidatePasswordForOneLetter] =
    useState(false);
  const [
    validatePasswordForOneLetterUppercase,
    setValidatePasswordForOneLetterUppercase,
  ] = useState(false);
  const [validatePasswordForOneNumber, setValidatePasswordForOneNumber] =
    useState(false);
  const [validatePasswordLength, setValidatePasswordLength] = useState(false);

  const regexForOneLetter = /[a-zA-Z]/;
  const regexForOneUppecaseLetter = /[A-Z]/;
  const regexForOneNumber = /[0-9]/;

  useEffect(() => {
    if (password.length >= 8) {
      setValidatePasswordLength(true);
    } else {
      setValidatePasswordLength(false);
    }
    if (regexForOneLetter.test(password)) setValidatePasswordForOneLetter(true);
    else setValidatePasswordForOneLetter(false);
    if (regexForOneUppecaseLetter.test(password))
      setValidatePasswordForOneLetterUppercase(true);
    else setValidatePasswordForOneLetterUppercase(false);
    if (regexForOneNumber.test(password)) setValidatePasswordForOneNumber(true);
    else setValidatePasswordForOneNumber(false);
  }, [password]);

  return (
    <Box>
      <HeaderConfig
        t={t}
        isMobile={isMobile}
        isConfirmAccount={isConfirmAccount}
      />
      <Box style={{ display: "flex", alignItems: "center", marginTop: 48 }}>
        <Box style={{ width: "100%", maxWidth: 550, marginBottom: 25 }}>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box style={{ width: "10%" }}></Box>
            <Box style={{ fontFamily: "Rubik", fontSize: 14, fontWeight: 600 }}>
              {t("registry.password")}
            </Box>
          </Box>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Box style={{ width: "10%", textAlign: "center" }}>
              <img
                src={passwordIcon}
                alt=""
                style={{ width: 28, height: 26 }}
              />
            </Box>
            <Tooltip
              style={{ width: "80%" }}
              disableHoverListener
              open={openTooltip}
              title={
                <div style={{ padding: 24 }}>
                  <div
                    style={{
                      color: validatePasswordForOneLetter
                        ? "#0EF00F"
                        : "#D41924",
                      fontSize: 14,
                      marginBottom: 3,
                    }}
                  >
                    {t("registry.tooltip.letter")}
                  </div>

                  <div
                    style={{
                      color: validatePasswordForOneLetterUppercase
                        ? "#0EF00F"
                        : "#D41924",
                      fontSize: 14,
                      marginBottom: 3,
                    }}
                  >
                    {t("registry.tooltip.capitalize.letter")}
                  </div>
                  <div
                    style={{
                      color: validatePasswordForOneNumber
                        ? "#0EF00F"
                        : "#D41924",
                      fontSize: 14,
                      marginBottom: 3,
                    }}
                  >
                    {t("registry.tooltip.minNumber")}
                  </div>
                  <div
                    style={{
                      color: validatePasswordLength ? "#0EF00F" : "#D41924",
                      fontSize: 14,
                      marginBottom: 3,
                    }}
                  >
                    {t("registry.tooltip.minLegth")}
                  </div>
                </div>
              }
              placement="bottom-start"
            >
              <OutlinedInput
                type={isShowPassword ? "text" : "password"}
                autoComplete="off"
                required
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                style={{
                  maxWidth: 550,
                  borderRadius: 4,
                  background: "#F6F6FC",
                  borderWidth: 0,
                  fontWeight: 500,
                  height: 27,
                }}
                inputProps={{ maxLength: 50 }}
                endAdornment={
                  <InputAdornment>
                    <IconButton
                      color="primary"
                      onClick={() => setIsShowPassword(!isShowPassword)}
                    >
                      {isShowPassword ? (
                        <VisibilityOffIcon position="end" />
                      ) : (
                        <VisibilityIcon position="end" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                placeholder={t("registry.password")}
                onFocus={() => setOpenTooltip(true)}
                onBlur={() => setOpenTooltip(false)}
                id="outlined-basic"
                fullWidth
              />
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          width: "100%",
          maxWidth: 550,
          marginBottom:
            confirmPassword !== password && confirmPassword !== "" ? 14 : 19,
        }}
      >
        <Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Box style={{ width: "10%" }}></Box>
            <Box style={{ fontFamily: "Rubik", fontSize: 14, fontWeight: 600 }}>
              {t("registry.confirm.password")}
            </Box>
          </Box>
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Box style={{ width: "10%", textAlign: "center" }}>
              <img
                src={passwordIcon}
                alt=""
                style={{ width: 28, height: 26 }}
              />
            </Box>
            <OutlinedInput
              type={isShowConfirmPassword ? "text" : "password"}
              autoComplete="off"
              required
              value={confirmPassword}
              onChange={(event) => setConfirmPassword(event.target.value)}
              style={{
                maxWidth: 550,
                borderRadius: "4px",
                background: "#F6F6FC",
                borderWidth: 0,
                fontWeight: 500,
                height: 27,
              }}
              inputProps={{ maxLength: 50 }}
              placeholder={t("registry.confirm.password")}
              id="outlined-basic"
              fullWidth
              endAdornment={
                <InputAdornment>
                  <IconButton
                    color="primary"
                    onClick={() =>
                      setIsShowConfirmPassword(!isShowConfirmPassword)
                    }
                  >
                    {isShowConfirmPassword ? (
                      <VisibilityOffIcon position="end" />
                    ) : (
                      <VisibilityIcon position="end" />
                    )}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Box>
        </Box>
        {confirmPassword !== password && confirmPassword !== "" && (
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Box style={{ width: "10%", textAlign: "center" }}></Box>
            <Box style={{ color: "#D41924", fontSize: 12 }}>
              {t("registry.not.match.password")}
            </Box>
          </Box>
        )}
      </Box>
      <Box
        style={{
          marginBottom: 11,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <CustomRadio
          disabled={isLoading}
          checked={isChecked}
          onClick={() => {
            setIsChecked(!isChecked);
          }}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              paddingRight: 4,
              fontFamily: "Rubik",
              fontWeight: "bold",
            }}
          >
            {t("registry.accept.conditions")}
          </div>
          <div
            style={{
              fontFamily: "Rubik",
              fontWeight: "bold",
              color: "#EA345F",
              fontStyle: "italic",
            }}
          >
            <Link
              to="/conditions"
              style={{
                fontFamily: "Rubik",
                fontWeight: "bold",
                color: "#EA345F",
                fontStyle: "italic",
                textDecoration: "none",
              }}
            >
              {t("registry.terms.conditions")}
            </Link>
          </div>
        </div>
      </Box>
      <Box style={{ width: "100%", maxWidth: 500 }}>
        <Button
          onClick={() => onConfirmAccount(password)}
          disabled={
            !isChecked || password === "" || password !== confirmPassword
          }
          variant="contained"
          color="primary"
          fullWidth
          style={{
            borderRadius: 20,
            textTransform: "capitalize",
            color: "#FFFFFF",
            marginBottom: 12,
            fontWeight: 700,
            boxShadow: "none",
            fontFamily: "Rubik",
            fontSize: 14,
            height: 33,
            backgroundColor:
              !isChecked || password === "" || password !== confirmPassword
                ? "#D6D8E8"
                : "",
          }}
        >
          {t("registry.terms.to.registry")}
        </Button>
        {/* <Button
          variant="outlined"
          // color="primary"
          fullWidth
          style={{
            borderRadius: 20,
            textTransform: "capitalize",
            color: "#52596B",
            borderColor: "#52596B",
            fontWeight: 700,
            height: 33,
            fontFamily: "Rubik",
            fontSize: 14,
            borderColor: "#52596B",
          }}
        >
          {t("registry.back")}
        </Button> */}
      </Box>
    </Box>
  );
}

export default ChangePasswordForm;
