import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";
import useMediaQuery from "@mui/material/useMediaQuery";

import i18n from "../i18n";
import MobileView from "../views/registry/mobile";
import DesktopView from "../views/registry/desktop";
import service from "../service";
import { EasyContext } from "../context";

function Registry() {
  const theme = useTheme();
  const navigate = useNavigate();
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation();
  const { state, setLanguage } = useContext(EasyContext);
  const [lang, setLangg] = useState("es");

  const [codePhone, setCodePhone] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  useEffect(() => {
    localStorage.clear();
  }, []);

  useEffect(() => {
    setLang(lang);
  }, []);

  return !mobileBreakpoint ? (
    <MobileView
      t={t}
      lang={lang}
      setLang={setLang}
      companyName={companyName}
      setCompanyName={setCompanyName}
      firstName={firstName}
      setFirstName={setFirstName}
      lastName={lastName}
      setLastName={setLastName}
      email={email}
      setEmail={setEmail}
      phone={phone}
      setPhone={setPhone}
      codePhone={codePhone}
      setCodePhone={setCodePhone}
      password={password}
      setPassword={setPassword}
      confirmPassword={confirmPassword}
      setConfirmPassword={setConfirmPassword}
      isChecked={isChecked}
      setIsChecked={setIsChecked}
      onRegistry={onRegistry}
      isLoading={isLoading}
    />
  ) : (
    <DesktopView
      t={t}
      lang={lang}
      setLang={setLang}
      companyName={companyName}
      setCompanyName={setCompanyName}
      firstName={firstName}
      setFirstName={setFirstName}
      lastName={lastName}
      setLastName={setLastName}
      email={email}
      setEmail={setEmail}
      phone={phone}
      setPhone={setPhone}
      codePhone={codePhone}
      setCodePhone={setCodePhone}
      password={password}
      setPassword={setPassword}
      confirmPassword={confirmPassword}
      setConfirmPassword={setConfirmPassword}
      isChecked={isChecked}
      setIsChecked={setIsChecked}
      onRegistry={onRegistry}
      isLoading={isLoading}
    />
  );

  async function onRegistry(payload) {
    try {
      setIsLoading(true);
      await service.createAccount(payload);
      // const data = await service.createAccount(payload);
      // const membership = await service.createMembership(data.newUser.company);

      setIsLoading(false);
      navigate("/notification");
    } catch (err) {
      Swal.fire({
        customClass: {
          confirmButton: "class1",
        },
        icon: "error",
        text: t(err.dataResponse.msg),
      });
      console.log("[Err create account] => ", err);
      setIsLoading(false);
    }
  }

  function setLang(lang) {
    setLangg(lang);
    setLanguage(lang);
  }
}

export default Registry;
