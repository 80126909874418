import { useState, useEffect } from "react";
import { IconButton, Paper, Button } from "@mui/material";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { iconsData } from "./listIcons";
import close from "../assets/close.svg";
import { consts } from "../consts";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

function VideolinkHook({
  t,
  principalColor,
  buttonColor,
  banner,
  page,
  hookTitle,
  hookSubtitle,
  buttonText,
  attentions,
  buttonColorAttentions,
  onDeleteAttention,
  isMobile,
  setIsShowHook,
  height,
}) {
  const theme = useTheme();
  const [isPortrait, setIsPortrait] = useState();
  const [isMobileView, setIsMobileView] = useState(
    useMediaQuery(theme.breakpoints.up("md"))
  );
  const view = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    window.addEventListener("resize", () => {
      if (!window.screen.orientation.angle) {
        setIsPortrait(true);
      } else {
        setIsPortrait(false);
      }
    });
  }, []);

  useEffect(() => {
    if (window.innerHeight > window.innerWidth) setIsPortrait(true);
  }, []);

  return (
    <Box
      style={{
        width: "100%",
        padding: 8,
        maxWidth: isMobile && 702,
        marginLeft: isMobile && "auto",
        marginRight: isMobile && "auto",
      }}
    >
      {!isMobile ? (
        <Box
          style={{
            fontSize: 32,
            color: "#FFFFFF",
            fontWeight: 600,
            textAlign: "center",
            fontFamily: "Rubik",
          }}
        >
          {page === consts.mode_contact
            ? t("contact.center.view")
            : t("contact.center.abilities.view")}
        </Box>
      ) : (
        <Box style={{ position: "absolute", left: 0 }}>
          <IconButton onClick={() => setIsShowHook(false)} id="back-hook">
            <ArrowBackSharpIcon style={{ color: "#FFFFFF" }} />
          </IconButton>
        </Box>
      )}
      <Box
        style={{
          height: !isMobileView ? "calc(100vh - 175px)" : "auto",
          paddingTop: !isMobile
            ? 24
            : !isMobileView
            ? 0
            : isPortrait
            ? "10%"
            : 8,
          marginTop: !view && !isMobileView && height < 1000 ? 32 : 24,
        }}
      >
        <Box
          style={{
            padding: 16,
            display: isMobileView && "flex",
            width: !view ? "100%" : !isMobileView ? 450 : "100%",
            minHeight: !view && isMobileView && height > 1300 ? 800 : 450,
            maxHeight:
              !view && isMobileView && height > 1300
                ? 800
                : isMobileView && 450,
            boxShadow: "inset -1px 0px 20px 9px #eaeaf08c",

            margin: view && !isMobileView && "auto",
          }}
          className="contact-senter-step"
        >
          <Box
            style={{
              width:
                !view && height > 1179 && isMobileView
                  ? "40%"
                  : !isMobileView
                  ? "100%"
                  : "25%",
              padding: !isMobileView && 12,
              backgroundColor: principalColor ? principalColor : "#000000",
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Box
              style={{
                color: "white",
                fontSize: isMobile ? (height > 1024 ? 32 : 14) : 16.4066,
                fontWeight: 700,
                fontFamily: "Rubik",
                textAlign: "center",
              }}
            >
              {t("contact.center.welcome.to")}
            </Box>
            <Box
              style={{
                marginTop: 16,
                marginBottom: 16,
                padding: 8,
                maxHeight: 150,
              }}
            >
              {banner ? (
                <img
                  src={banner.image}
                  alt=""
                  style={{ width: "100%", maxWidth: 150, maxHeight: 150 }}
                />
              ) : (
                <Box
                  style={{
                    fontSize: height > 1024 ? 27 : 16.4,
                    color: "#FFFFFF",
                    fontWeight: 700,
                    fontFamily: "Rubik",
                  }}
                >
                  {t("contact.center.my.logo")}
                </Box>
              )}
            </Box>
            <Box style={{ width: "80%" }}>
              <Button
                fullWidth
                variant="contained"
                style={{
                  borderRadius: isMobile ? (height > 1024 ? 40 : 20) : 20,
                  textTransform: "capitalize",
                  color: "#FFFFFF",
                  marginBottom: 8,
                  fontWeight: 700,
                  background: buttonColor ? buttonColor : "#D6D8E8",
                  fontFamily: "Rubik",
                  height: isMobile ? (height > 1024 ? 60 : 37) : 37,
                  boxShadow: "none",
                  fontSize: isMobile ? (height > 1024 ? 22 : 12) : 14,
                }}
              >
                {t("contact.center.see.tour")}
              </Button>
            </Box>
          </Box>

          {page === consts.mode_contact ? (
            <Box
              style={{
                width: !isMobileView ? "100%" : "75%",
                backgroundColor: "#FFFFFF",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: isMobileView && "center",
                padding: 8,
                overflow: "auto",
                height: view
                  ? !isMobileView
                    ? "calc(100vh - 500px)"
                    : ""
                  : !isMobileView && height > 1300
                  ? "calc(100vh - 510px)"
                  : !isMobileView
                  ? "calc(100vh - 510px)"
                  : "",
              }}
            >
              <Box
                style={{
                  width: "100%",
                  display: isMobileView && "flex",
                  marginBottom: 8,
                }}
              >
                <Box
                  style={{
                    width: !isMobileView ? "100%" : "50%",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: 8.20328,
                    marginRight: 8,
                    height: isMobile ? (height > 1024 ? 220 : 90) : 90,
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    style={{
                      backgroundColor: "#D6D8E8",
                      width: "80%",
                      height: isMobile && height > 1024 && 50,
                      color: "#D6D8E8",
                      marginBottom: 8,
                      borderRadius: 20.9184,
                    }}
                  >
                    a
                  </Box>
                </Box>
                <Box
                  style={{
                    width: !isMobileView ? "100%" : "50%",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: 8.20328,
                    height: isMobile ? (height > 1024 ? 220 : 90) : 90,
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    style={{
                      backgroundColor: "#D6D8E8",
                      width: "80%",
                      height: isMobile && height > 1024 && 50,
                      color: "#D6D8E8",
                      marginBottom: 8,
                      borderRadius: 20.9184,
                    }}
                  >
                    a
                  </Box>
                </Box>
              </Box>
              <Box style={{ width: "100%", display: isMobileView && "flex" }}>
                <Box
                  style={{
                    width: !isMobileView ? "100%" : "50%",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: 8.20328,
                    marginRight: 8,
                    height: isMobile ? (height > 1024 ? 220 : 90) : 90,
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    style={{
                      backgroundColor: "#D6D8E8",
                      width: "80%",
                      height: isMobile && height > 1024 && 50,
                      color: "#D6D8E8",
                      marginBottom: 8,
                      borderRadius: 20.9184,
                    }}
                  >
                    a
                  </Box>
                </Box>
                <Box
                  style={{
                    width: !isMobileView ? "100%" : "50%",
                    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                    borderRadius: 8.20328,
                    height: isMobile ? (height > 1024 ? 220 : 90) : 90,
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    style={{
                      backgroundColor: "#D6D8E8",
                      width: "80%",
                      height: isMobile && height > 1024 && 50,
                      color: "#D6D8E8",
                      marginBottom: 8,
                      borderRadius: 20.9184,
                    }}
                  >
                    a
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box
              style={{
                width: !isMobileView ? "100%" : "75%",
                backgroundColor: "#FFFFFF",
                padding: 8,
                overflow: "scroll",
                height: view
                  ? !isMobileView
                    ? "calc(100vh - 500px)"
                    : ""
                  : height > 1179
                  ? isMobileView
                    ? ""
                    : "calc(100vh - 510px)"
                  : !isMobileView && "calc(100vh - 450px)",
              }}
            >
              <Box style={{ padding: isMobile ? 4 : 32 }}>
                <Box
                  style={{
                    textAlign: "center",
                    fontSize: isMobile ? (height > 1024 ? 32 : 14) : 14,
                    color: "#0E1218",
                    fontWeight: 600,
                    fontFamily: "Rubik",
                    wordBreak: "break-word",
                  }}
                >
                  {hookTitle === "" ? t("attentions.hook.title") : hookTitle}
                </Box>
                <Box
                  style={{
                    textAlign: "center",
                    fontSize: isMobile ? (height > 1024 ? 24 : 10) : 10,
                    color: "#0E1218",
                    fontWeight: 400,
                    fontFamily: "Rubik",
                    marginTop: 8,
                    wordBreak: "break-word",
                  }}
                >
                  {hookSubtitle === ""
                    ? t("attentions.hook.subtitle")
                    : hookSubtitle}
                </Box>
              </Box>
              <Box>
                <Grid container spacing={1}>
                  {attentions?.map((item, index) => (
                    <Grid
                      key={item.id}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={
                        !isMobileView
                          ? 12
                          : attentions.length % 2 === 0
                          ? 6
                          : 12
                      }
                    >
                      <Paper
                        elevation={0}
                        style={{
                          borderRadius: 13.3486,
                          paddingBottom: 8,
                          boxShadow: "0px 0px 13.3486px rgba(0, 0, 0, 0.1)",
                          minHeight: 183,
                        }}
                      >
                        <Box style={{ padding: 12, display: "flex" }}>
                          <Box
                            style={{ marginRight: 8, width: 48, height: 48 }}
                          >
                            <Box
                              style={{
                                backgroundColor:
                                  item.icon === undefined && "#AFB8CD",
                                borderRadius: "100%",
                                width: 48,
                                height: 48,
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <span
                                className="material-icons"
                                style={{
                                  fontSize: 42,
                                  color: item.color,
                                  width: "-webkit-fill-available",
                                }}
                              >
                                {
                                  iconsData.find(
                                    (icon) => icon.icon === item.icon
                                  )?.icon
                                }
                              </span>
                            </Box>
                          </Box>
                          <Box
                            style={{
                              overflow: "hidden",
                              // textOverflow: "ellipsis",
                              width: "100%",
                            }}
                          >
                            <Box
                              style={{
                                fontSize: isMobile
                                  ? height > 1024
                                    ? 27
                                    : 16
                                  : 16,
                                fontFamily: "Rubik",
                                fontWeight: 700,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                // width: "100%",
                              }}
                            >
                              {item.title === ""
                                ? t("attentions.item.title")
                                : item.title}
                            </Box>
                            <Box
                              className="description-attetions-map"
                              style={{
                                fontSize: isMobile
                                  ? height > 1024
                                    ? 24
                                    : 14
                                  : 14,
                                fontFamily: "Rubik",
                                wordBreak: "break-word",
                                display: "-webkit-box",
                                minHeight: 87,

                                // whiteSpace: "nowrap",
                                // overflow: "hidden",
                                // textOverflow: "ellipsis",
                              }}
                            >
                              {item.description === ""
                                ? t("attention.item.description")
                                : item.description}
                            </Box>
                          </Box>
                          <Box
                            className="delete-attention"
                            style={{ marginLeft: "auto", color: "#5C5B5C" }}
                            onClick={() => onDeleteAttention(item.id)}
                            id={`delete-attention-${index}`}
                          >
                            <img
                              src={close}
                              alt=""
                              style={{ width: isMobile ? 8 : 12 }}
                            />
                          </Box>
                        </Box>
                        <Box
                          style={{
                            textAlign: "center",
                            // height: 90,
                            // display: "flex",
                            // alignItems: "end",
                            // justifyContent: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            size="small"
                            style={{
                              backgroundColor: buttonColorAttentions
                                ? buttonColorAttentions
                                : "#3E3E3E",
                              borderRadius: 34.039,
                              width: "75%",
                              height: isMobile ? (height > 1024 ? 50 : 32) : 32,
                              fontFamily: "Rubik",
                              boxShadow: "none",
                              textTransform: "inherit",
                              fontSize: isMobile
                                ? height > 1024
                                  ? 24
                                  : 14
                                : 14,
                            }}
                          >
                            {buttonText === ""
                              ? t("attentions.select.string")
                              : buttonText}
                          </Button>
                        </Box>
                      </Paper>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box
        style={{
          display: "flex",
          marginTop: !isMobileView ? 24 : 12,
          padding: 16,
          justifyContent: view && "center",
        }}
        className="button-screen-step"
      >
        <Button
          onClick={() => setIsMobileView(true)}
          style={{
            textTransform: "inherit",
            borderRadius: isMobile ? (height > 1024 ? 40 : 20) : 20,
            fontSize: isMobile ? (height > 1024 ? 24 : 14) : 14,
            width: view ? "33.33%" : "75%",
            fontWeight: 700,
            boxShadow: "none",
            height: isMobile ? (height > 1024 ? 50 : 32) : 32,
            marginBottom: 8,
            background: isMobileView ? "#FFFFFF" : "transparent",
            color: isMobileView ? "#EA345F" : "#FFFFFF",
            border: isMobileView ? "solid 1px #EA345F" : "1px solid #FFFFFF",
          }}
          variant="contained"
          size="small"
          id="desktop-view"
          className={isMobileView ? "active" : "inactive"}
        >
          {t("contact.center.web.view")}
        </Button>
        <Button
          onClick={() => setIsMobileView(false)}
          style={{
            marginLeft: 12,
            textTransform: "inherit",
            borderRadius: isMobile ? (height > 1024 ? 40 : 20) : 20,
            width: view ? "33.33%" : "75%",
            fontSize: isMobile ? (height > 1024 ? 24 : 14) : 14,
            fontWeight: 700,
            boxShadow: "none",
            background: !isMobileView ? "#FFFFFF" : "transparent",
            color: !isMobileView ? "#EA345F" : "#FFFFFF",
            border: !isMobileView ? "solid 1px #EA345F" : "1px solid #FFFFFF",
            height: 32,
            height: isMobile ? (height > 1024 ? 50 : 32) : 32,
            marginBottom: 8,
            ntFamily: "Rubik",
          }}
          variant="contained"
          id="mobile-view"
          className={!isMobileView ? "active" : "inactive"}
        >
          {t("contact.center.mobile.view")}
        </Button>
      </Box>
    </Box>
  );
}

export default VideolinkHook;
