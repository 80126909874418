import { useState, useEffect } from "react";
import { Box, Dialog, Backdrop } from "@mui/material";
import { styled } from "@mui/material/styles";

import mobileBackground from "../../assets/mobile_background.png";
import ListLinks from "../../components/listLinks";

function MobileView({ t, setLang, lang, onBack, onFinish, contactCenterURL }) {
  const [isOpenDialog] = useState(true);
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <Box style={{ height: "100vh" }}>
      <>
        <Box
          style={{
            height: isOpenDialog ? "25vh" : "45vh",
            width: "100%",
            background:
              "linear-gradient(153.91deg, #E53463 0.87%, #4B27EF 83.55%)",
            backgroundSize: "cover",
            display: "flex",
            padding: 12,
          }}
        ></Box>
        <Dialog
          fullScreen
          open={true}
          PaperProps={{
            style: {
              borderTopLeftRadius: 10,
              height: isOpenDialog ? "98%" : "60%",
              position: "fixed",
              bottom: 0,
              width: "95%",
              borderTopRightRadius: 10,
            },
          }}
          BackdropComponent={styled(Backdrop, {
            name: "MuiModal",
            slot: "Backdrop",
            overridesResolver: (props, styles) => {
              return styles.backdrop;
            },
          })({
            zIndex: -1,
            position: "relative",
          })}
        >
          <Box
            style={{
              // display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              // margin: "auto",
              marginTop: 24,
              paddingLeft: 8,
              paddingRight: 8,
              paddingBottom: 24,
            }}
          >
            <ListLinks
              t={t}
              lang={lang}
              setLang={setLang}
              isMobile={true}
              onBack={onBack}
              onFinish={onFinish}
              contactCenterURL={contactCenterURL}
              height={height}
              width={width}
            />
          </Box>
        </Dialog>
      </>
    </Box>
  );
}

export default MobileView;
