import Box from "@mui/material/Box";
import notFound from "../assets/notFound.svg";

function NotFound() {
  return (
    <Box
      style={{
        width: "100%",
        height: "100vh",
      }}
    >
      <img src={notFound} alt="" style={{ width: "100%", height: "100vh" }} />
    </Box>
  );
}

export default NotFound;
